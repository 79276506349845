export class SharedLocalSettingsService {

  getId(){
    let credentials = localStorage.getItem("credentials");
    return credentials ? JSON.parse(credentials).sub : "default";
  }

  get(){
    let item = localStorage.getItem(this.getId() + "_settings")
    return item ? JSON.parse(item) : {};
  }

  set(value: any){
    localStorage.setItem(this.getId() + "_settings", JSON.stringify(value));
  }
}
