import { Component, Input, OnInit } from '@angular/core';
import { ModalService} from '@app/@core/service/modal.service';
import { environment } from '@env/environment';

import { I18nService } from '@app/i18n';

import { CredentialsService } from '@app/@core/auth';
import { SharedUtils } from '@rehub-shared/utils/utils';

const TIMEOUT_TIME = 2 * 60000; // 2 minutos

@Component({
  selector: 'rehub-disconnected-realtime-modal',
  templateUrl: './disconnected-realtime-modal.component.html',
  styleUrls: ['./disconnected-realtime-modal.component.scss']
})
export class DisconnectedRealtimeModalComponent implements OnInit {

  data: any;
  resetting: boolean = false;

  timedOut: boolean = false;

  private statusChangeSubscription: any;
  private timeout: any;

  bodyTranslation: string;
  actionSelected: string;
  supportUrl: string;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private credentialsService: CredentialsService,
  ) {}

  ngOnInit() {
    this.bodyTranslation = this.i18nService.translate('modal_realtime_disconnected_body');
    this.supportUrl = this.getSupportUrl();

    if(this.data.auto) this.onResetPressed();

    this.statusChangeSubscription = this.data.measurementProgramService.onStatusChange().subscribe((status: string)=>{
      console.log("DisconnectedRealtimeModal status:", status);
      if(status == "RESETTING"){
        this.resetting = true;
        this.startTimeout();
      }else if(status == "CONNECTED"){
        // Ha terminado de instalar
        this.close();
      }
    });
  }

  startTimeout(){
    this.timeout = setTimeout(()=>{
      this.timedOut = true;
    }, TIMEOUT_TIME);
  }

  onCancelPressed(){
    this.close();
  }

  close(result: string = null){
    if(this.timeout) clearTimeout(this.timeout);
    this.statusChangeSubscription.unsubscribe();
    if(result) this.modalService.callback(result);
    else this.modalService.close();
  }

  onResetPressed(){
    this.data.measurementProgramService.resetRealtime();
  }

  onInstallPressed() {
    this.close("install");
  }

  onHelpPressed(){
    //Se abre web de contacto
    let client = environment.client_login;
    let lang = this.i18nService.getLanguageCode();
    let url = environment.login_url + "/contact-form?client=" + client + "&lang=" + lang;
    window.open(url, "_blank");
  }

  onSkipDownloadPressed(){
    this.close();
  }

  onAcceptPressed(){
    if(this.actionSelected == "reset") this.onResetPressed();
    else if(this.actionSelected == "install") this.onInstallPressed();
  }

  onActionChanged(value: string){
    this.actionSelected = value;
  }

  getSupportUrl(){
    return SharedUtils.getSupportUrl({
      client: environment.client_login,
      supportUrl: environment.login_url + "/contact-form",
      patient: this.credentialsService.getInfo(),
      lang: this.i18nService.getLanguageCode(),
      reasonGroup: SharedUtils.REASON_GROUP_REALTIME,
      reason: this.data.measurementProgramService.isRealtimeInstalled() ? SharedUtils.REASON_REALTIME_NO_RESET : SharedUtils.REASON_REALTIME_NO_INSTALL
    });
  }
}
