import { SharedSensorService } from "../service/sensor.service";
import { TherapyUtils } from "./therapy-utils";
import { SharedUtils } from "./utils";

export type PreparedExercise = {
  recordId: string,
  types: ExerciseOption[],
  calibrate?: boolean,
  name: string,
  imageURL: string,
  tools: string[],
  cycleEnd?: boolean
}

export enum ExerciseOption {
  SENSOR = "sensor",
  VIDEO = "video",
  CAMERA = "camera",
}

export enum ExerciseOptionIcon {
  SENSOR = "sensor_main",
  VIDEO = "video",
  CAMERA = "sensor_camera",
}


export type NextExerciseParams = {
  isLastExercise: boolean,
  isFirstSet?: boolean,
  isLastSet: boolean,
  nextExerciseTempId: string,
  finishRedirectURL: string,
  finishQueryParams?: any,
  previousSetJustDone?: boolean,
  currentExerciseType?: ExerciseOption
  nextExerciseType?: ExerciseOption
}

export type CalibrationParams = {
  sensorMac: string,
  sensorType: string,
  type: string,
  patientId: string,
  tempId?: string
}

export class ExerciseUtils{

  /**
   * Obtiene el tiempo restante de un ejercicio teniendo en cuenta los sets realizados y los pendientes
   * @param record Record del ejercicio
   * @param showTotalExercises Indica si se están mostrando todos los ejercicios
   */
  public static getRemainingSecondsFromExercise(record: any, showTotalExercises: boolean = false): any{
    if (record && record.expectedRecordDuration) {
      let waitSeconds = record.waitSeconds;
      let duration = record.expectedRecordDuration;
      let setsCount = record.setsCount;
      let doneSets = showTotalExercises ? 0 : (record.doneSets || 0);
      let pendingSets = setsCount - doneSets;

      let extraTime = pendingSets * 15;

      let totalWait = waitSeconds * (pendingSets - 1);
      let totalExercise = duration * pendingSets;
      let result = totalWait + totalExercise + extraTime;

      return result;
    }

    return null;
  }

  public static getAvailableExerciseTypesFromPreparedExercises(preparedExercises: PreparedExercise[]): ExerciseOption[]{
   let exerciseTypes: Set<ExerciseOption> = new Set();

   preparedExercises.forEach((preparedExercise: PreparedExercise)=>{
    preparedExercise.types.forEach((type: ExerciseOption)=>{
      exerciseTypes.add(type);
    });
   });

   return Array.from(exerciseTypes);
  }

  /**
   * Obtiene el icono de una opción de ejercicio
   * @param exerciseOption Opción de ejercicio
   */
  public static getExerciseOptionIcon(exerciseOption: ExerciseOption): ExerciseOptionIcon{
    return (ExerciseOptionIcon as any)[Object.keys(ExerciseOption)[Object.values(ExerciseOption).indexOf(exerciseOption)]];
  }

  /**
   * Ordena los ejercicios por ciclo y por recordState
   * @param exercises Ejercicios
   * @returns
   */
  public static orderExercises(exercises: any) {
    let exercisesOrdered: any = {};

    exercises.forEach((exercise: any) => {
      let key = this.getCycleExerciseKey(exercise);

      if (key in exercisesOrdered) {
        exercisesOrdered[key].push(exercise);
      } else {
        exercisesOrdered[key] = [exercise];
      }
    });

    let keys = Object.keys(exercisesOrdered);

    keys.forEach((key: any) => {
      exercisesOrdered[key] = exercisesOrdered[key].sort((exerciseA: any, exerciseB: any) => (exerciseA.recordState > exerciseB.recordState) ? 1 : -1 );
    });

    return exercisesOrdered;
  }

  /**
   * Separa los ejercicios por recordState en ordenados y no ordenados
   * @param exercises Ejercicios
   * @returns
   */
  public static splitExercises(exercises: any) {
    let exercisesSplited: any = {
      done: [],
      notDone: []
    }

    let keys = Object.keys(exercises);
    keys.sort();

    while (keys.length != 0) {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        exercises[key][0].recordState == "DONE" ? exercisesSplited.done.push(exercises[key][0]) : exercisesSplited.notDone.push(exercises[key][0]);
        exercises[key].shift();
        if (exercises[key].length == 0) {
          delete exercises[key];
        }
      }

      keys = Object.keys(exercises);
      keys.sort();
    }

    return exercisesSplited;
  }

  /**
   * Tiempo transcurrido desde el último ejercicio hecho
   * @param exercises Ejercicios
   * @returns
   */
  public static getEllapsedTimeSinceLastDoneExercise(exercises: any[]){
    // Se ordenan los ejercicios que estén DONE por fecha y se usa el primero

    let sortedByDate = exercises.filter((exercise: any)=>exercise.recordState == "DONE").sort((a: any, b: any)=> new Date(b.doneDate).getTime() - new Date(a.doneDate).getTime());
    if(sortedByDate.length == 0) return null;

    return new Date().getTime() - new Date(sortedByDate[0].doneDate).getTime();
  }

  /**
   * Obtiene los ejercicios con los datos necesarios para realizarlos
   * @param exercises Ejercicios por hacer
   * @param protocols Protocolos de los ejercicios
   * @param sensorKits Sensores del paciente
   * @param patientInfo Información del paciente
   * @param sensorService Servicio de sensores
   */
  public static getExercisesToPrepare(exercises: any[], protocols: any[], sensorKits: any, patientInfo: any, sensorService: SharedSensorService): PreparedExercise[]{
    // Se guarda si los ejercicios tienen ciclos
    let hasExerciseCycles = this.hasExerciseCycles(exercises);

    // Se obtienen los ejercicios de un solo ciclo
    let cycleExercises = this.getCycleExercises(exercises);

    return cycleExercises.map((exercise: any, index: number)=>{
      let protocol = protocols[exercise.protocol];

      let sensorType = protocol.sensorType;

      let canUseSensor = this.hasRequiredSensorType(sensorType, sensorKits, sensorService); // Indica si el paciente puede hacer el ejercicio con sensor
      let canUseCamera = protocol.cameraEnabled && patientInfo.useCamera; // Indica si el paciente puede hacer el ejercicio con cámara
      if(sensorKits && sensorKits.length > 0) canUseCamera = false;//Los usuarios con sensor no pueden utilizar cámara
      let canUseVideo = patientInfo.noSensor || (!canUseSensor && !canUseCamera); // Indica si el paciente puede hacer el ejercicio sin sensor

      // Si el ejercicio está en progreso (tiene sets a medias) se tiene que seguir haciendo como se ha empezado
      if (exercise.recordState == "IN_PROGRESS") {
        if(exercise.manual){
          canUseCamera = false;
          canUseSensor = false;
        }else if(exercise.camera){
          canUseVideo = false;
          canUseSensor = false;
        }else{
          canUseVideo = false;
          canUseCamera = false;
        }
      }

      let exerciseTypes: ExerciseOption[] = [];

      if(canUseSensor) exerciseTypes.push(ExerciseOption.SENSOR);
      if(canUseVideo) exerciseTypes.push(ExerciseOption.VIDEO);
      if(canUseCamera) exerciseTypes.push(ExerciseOption.CAMERA);

      let isLastExerciseCycle = index == cycleExercises.length - 1;

      let data: PreparedExercise = {
        recordId: exercise.recordId,
        types: exerciseTypes,
        name: `${exercise.jointTranslation}: ${exercise.protocolTranslation}`,
        imageURL: exercise.imageURL,
        tools: protocol.tools,
      };

      if(hasExerciseCycles && isLastExerciseCycle) data.cycleEnd = true;

      return data;
    });
  }

  /**
   * Redirige a la pantalla del siguiente ejercicio
   * @param router
   * @param params
   * @param environment
   */
  public static redirectToNextExercise(router: any, params: NextExerciseParams, environment: any){
    // Si aún quedan sets pendientes te lleva al ejercicio directamente
    if(!params.isLastSet){
      this.startExercise(router, params, environment);
      return;
    }

    //Si era el último set del último ejercicio te redirige a la pantalla principal ya que no hay más ejercicios
    if(params.isLastExercise && params.isLastSet){
      this.redirectToNextURL(router, params.finishRedirectURL, params.finishQueryParams);
      return;
    }

    router.navigate(["exercise/next"], {queryParams: {tempId: params.nextExerciseTempId}});
  }

  /**
   * Redirige a la pantalla del pulsioximetro
   * @param router
   * @param tempId
   */
  public static redirectToPulseOximeter(router: any, tempId: string, isCameraTracking: boolean){
    router.navigate(["exercise/pulseoximeter"], {queryParams: {tempId: tempId, isCameraTracking: isCameraTracking}});
  }

  /**
   * Redirige a la pantalla de calibración
   * @param params Parámetros para la redirección
   * @param environment Entorno
   */
  public static redirectToCalibration(params: CalibrationParams, environment: any){
    let url = SharedUtils.addQueryParamsToURL(environment.professional_url + "/calibrate", params);
    window.location.href = url;
  }

  /**
   * Redirige al siguiente set, al siguiente ejercicio o si ya no hay más ejercicios a una url en concreto.
   * @param router Router de Angular para hacer la redirección
   * @param params
   * @param environment
   */
  public static startExercise(router: any, params: NextExerciseParams, environment: any){

    let currentURL = window.location.origin;

    if(params.isLastExercise && params.isLastSet){
      this.redirectToNextURL(router, params.finishRedirectURL, params.finishQueryParams);
      return;
    }

    // Si es el último set hay que usar el type del siguiente ejercicio
    let type = params.isLastSet ? params.nextExerciseType : params.currentExerciseType;

    let queryParams: any = {
      recordType: this.exerciseOptionToRecordType(type!),
      tempId: params.nextExerciseTempId
    }

    let queryParamsVIDEO: any = {
      tempId: params.nextExerciseTempId
    }

    // Si se acaba de hacer un set de manera seguida no hace falta mostrar las instrucciones
    if(params.previousSetJustDone && !params.isFirstSet && !params.isLastSet){
      queryParams.skipInstructions = true;
    }

    if(type == ExerciseOption.SENSOR || type == ExerciseOption.CAMERA){
      let sameOrigin = currentURL == environment.professional_url;

      let path = "exercise/2";

      if(type == ExerciseOption.CAMERA) queryParams.cameraTracking = true;

      // Si se acaba de hacer un set de manera seguida se asume que el sensor ya está conectado
      if(params.previousSetJustDone && !params.isFirstSet && !params.isLastSet) queryParams.connected = true;

      if(sameOrigin){
        router.navigate([path], {queryParams: queryParams});
      }else{
        window.location.href = SharedUtils.addQueryParamsToURL(`${environment.professional_url}/${path}`, queryParams);
      }

    }else if(type == ExerciseOption.VIDEO){
      // window.location.href = SharedUtils.addQueryParamsToURL(`${environment.exercise_url}/exercises`, queryParamsVIDEO);
      let sameOrigin = currentURL == environment.patient_url;
      let path = "exercise";
      if(sameOrigin){
        router.navigate([path], {queryParams: queryParams});
      }else{
        window.location.href = SharedUtils.addQueryParamsToURL(`${environment.patient_url}/${path}`, queryParams);
      }
    }

  }

  /**
   * Redirige a la siguiente url al terminar el ejercicio con los parámetros indicados
   * @param router Router de Angular
   * @param nextURL Siguiente URL
   * @param queryParams Parámetros de la URL
   */
  public static redirectToNextURL(router: any, nextURL: string, queryParams: any){
    let currentURL = window.location.origin;
    let sameOrigin = currentURL == nextURL;

    if(sameOrigin){
      router.navigate(["/"], {queryParams: queryParams});
    }else{
      window.location.href = SharedUtils.addQueryParamsToURL(nextURL, queryParams);
    }
  }

  /**
   * Obtiene la clave de un ejercicio de un ciclo
   * @param exercise Ejercicio
   */
  private static getCycleExerciseKey(exercise: any){
    return (exercise.orderValue ? exercise.orderValue : 'z')  + '_' + exercise.protocol;
  }

  /**
   * Obtiene los ejercicios de un ciclo
   * @param exercises Ejercicios
   */
  private static getCycleExercises(exercises: any[]){
    if(exercises.length == 0) return [];
    if(!this.hasExerciseCycles(exercises)) return exercises;

    let result: any[] = [exercises[0]];
    let found = false;
    let i = 1;

    let firstKey = this.getCycleExerciseKey(exercises[0]);

    while(i < exercises.length && !found){
      let currentKey = this.getCycleExerciseKey(exercises[i]);

      if(firstKey == currentKey){
        found = true;
      }else{
        result.push(exercises[i]);
        i++;
      }
    }

    return result;
  }

  /**
   * Indica si los ejercicios tienen ciclos. Los ejercicios tienen ciclos si todos los ejercicios se tienen que hacer el mismo número de veces
   * @param exercises Ejercicios
   */
  private static hasExerciseCycles(exercises: any[]){
    let exercisesCount: any = {};

    if(exercises.length == 1) return false;

    exercises.forEach((exercise: any)=>{
      let exerciseKey = this.getCycleExerciseKey(exercise);

      if(exercisesCount[exerciseKey] != null) exercisesCount[exerciseKey]++;
      else exercisesCount[exerciseKey] = 1;
    });

    let keys = Object.keys(exercisesCount);
    let sameExerciseCount = true;
    let i = 1;

    // Se hace una búsqueda. Si el elemento anterior no tiene la misma longitud que el actual es que no son ejercicios con ciclos
    while(i < keys.length && sameExerciseCount){
      let exerciseCount = exercisesCount[keys[i]];
      let lastExerciseCount = exercisesCount[keys[i - 1]];

      if(exerciseCount != lastExerciseCount) sameExerciseCount = false;

      i++;
    }

    return sameExerciseCount;
  }

  private static exerciseOptionToRecordType(exerciseOption: ExerciseOption){
    if(exerciseOption == ExerciseOption.SENSOR || exerciseOption == ExerciseOption.CAMERA) return "EXERCISE";
    return "EXERCISE_MANUAL";
  }

  private static hasRequiredSensorType(sensorType: string, sensorKits: any, sensorService: SharedSensorService){
    let hasSensorKit = sensorKits.length > 0 && sensorType;
    //TODO!: buscar si tiene un sensorKit de ese sensorType
    let selectedSensorKit: any = hasSensorKit ? sensorKits[0] : null;
    return hasSensorKit ? (sensorService.getSensorKitToSensorType() as any)[selectedSensorKit.kitType].includes(sensorType) : false;
  }

  static hasPulseOximeter(pulseOximeter: string, measurementType: string){
    if(pulseOximeter== null ) return false;
    else{
      if(measurementType == "start") {
        return pulseOximeter[0] == "T" || pulseOximeter[1] == "T" || pulseOximeter[2] == "T";
      }
      else if(measurementType == "end"){
        return pulseOximeter[3] == "T" || pulseOximeter[4] == "T" || pulseOximeter[5] == "T";
      } else return false;
    }


  }
}