import { IRxjs } from '../utils/rxjs.interface';
import { ExerciseService } from './exercise.service';
import { RehubWasm } from './wasm';
export class WasmExerciseService extends ExerciseService{

  name: string = "wasm";

  subject: any;
  wasm: any = {};

  constructor(public rxjs: IRxjs, public baseURL: string){
    super(rxjs);
  }

  public init(): any{
    this.wasm = new RehubWasm();

    this.wasm.init(this.baseURL + "main.js").then(()=>{
      this.wasm.setReceive(this.receive.bind(this));
      setTimeout(()=>{
        this.commandActionReady();
      }, 2000);
    });

    this.subject = this.rxjs.createEmptySubject();
    console.log("init WasmExerciseService service");

    return this.createObservable(this.subject);
  }

  public destroy(){
    console.log("destroy WasmExerciseService service");
  }

  public send(command: string, value: any = null){
    let data: any = { command: command };
    if(value) data.value = value;

    if(this.wasm.send) this.wasm.send(JSON.stringify(data));
  }

  public receive(data: any){
    if(!this.subject) return;
    this.subject.next(JSON.parse(data));
  }

  private createObservable(subject: any){
    return this.rxjs.mapSubject(subject, false, (data: any)=>{
      return {
        type: "message",
        message: data
      };
    });
  }
}
