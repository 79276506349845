import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { SharedUtils } from '@rehub-shared/utils/utils';


@Component({
  selector: 'rehub-install-android-modal',
  templateUrl: './install-android-modal.component.html',
  styleUrls: ['./install-android-modal.component.scss']
})
export class InstallAndroidModalComponent implements OnInit {

  url: string ;


  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if(SharedUtils.isIOS()) this.url = "https://apps.apple.com/es/app/rehub/id1619958564";
    else this.url = "https://play.google.com/store/apps/details?id=com.dycare.rehub";
  }

  close() {
    this.modalService.close();
  }

  onInstallPressed(){
    window.open(this.url, "_blank");
  }
}