import { Injectable, Injector, EmbeddedViewRef, ApplicationRef, ComponentFactoryResolver } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DOMService {
  references: any = {};

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  add(nameComponent: string, component: any, data: any = null, callback: any = null) {
    if (!this.isComponentRegistred(nameComponent)) {
      // Create a component reference from the component
      const componentRef: any = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
      if(data) componentRef.instance.data = data;
      if(callback) componentRef.instance.callback = callback;
      componentRef.changeDetectorRef.detectChanges();

      // Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(componentRef.hostView);

      // Get DOM element from component
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

      // Append DOM element to the body
      document.body.appendChild(domElem);

      this.references[nameComponent] = componentRef;

      return componentRef;
    }
    return null;
  }

  addToParent(parent: any, component: any, data: any = null){
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef: any = factory.create(parent.parentInjector);
    componentRef.instance.isInModal = true;
    if(data) componentRef.instance.data = data;
    
    parent.insert(componentRef.hostView);

    //setTimeout(()=>{
     
    //}, 0);
  }

  remove(nameComponent: string, result: any = null, emitCallback: boolean = false) {
    if (this.isComponentRegistred(nameComponent)) {
      
      if(emitCallback) this.callback(nameComponent, result);

      if(!this.references[nameComponent]) return;

      this.appRef.detachView(this.references[nameComponent].hostView);
      this.references[nameComponent].destroy();
      delete this.references[nameComponent];
    }
  }

  callback(nameComponent: string, result: any = null){
    if (this.isComponentRegistred(nameComponent)) {
      let callback = this.references[nameComponent].instance.callback;
      if(callback) callback(result);
    }
  }

  private isComponentRegistred(nameComponent: any) {
    return Object.keys(this.references).includes(nameComponent);
  }
}
