<div class="modal-header">
  <h5 class="modal-title" >{{ "modal_new_version_title" | translate }}</h5>
  <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column" >
  <p class="text-center mb-4">{{ "modal_new_version_description" | translate }}</p>
  <video class="w-100 h-100" style="max-height: 450px;" oncontextmenu="return false;" controlsList="nodownload" [volume]="1" [attr.autoplay]="true" loop controls playsinline="true" webkit-playsinline="true">
    <source [src]="url" type="video/mp4"/>
  </video>
</div>

<div class="modal-footer border-0 pt-0 d-flex justify-content-center">
  <button type="button" class="btn btn-primary btn-lg d-flex flex-row align-items-center justify-content-center"
    (click)="confirm()">
    <span>{{"general_accept" | translate}}</span>
  </button>
</div>