import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { PushNotificationsService } from '@core/service/push-notifications.sevice';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { ShellModule } from './shell/shell.module';
import { AppConfig } from './app.config';
import { AsyncPipe } from '../../node_modules/@angular/common';

// @ts-ignore
import { RehubIconWebComponent } from "@web-components/icon/icon";
// @ts-ignore
import { RehubSpinnerWebComponent } from "@web-components/spinner/spinner";
// @ts-ignore
import { RehubCalendarWebComponent } from "@web-components/calendar/calendar";
// @ts-ignore
import { RehubCoachWebComponent } from "@web-components/coach/coach";
// @ts-ignore
import { RehubGoniometerWebComponent } from "@web-components/goniometer/goniometer";
// @ts-ignore
import { RehubTimerWebComponent } from "@web-components/timer/timer";

export function appInit(appConfig: AppConfig) {
  return () => appConfig.load();
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    CoreModule,
    SharedModule,
    ShellModule,
    RouterModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule
  ],
  declarations: [AppComponent],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfig]
    },
    PushNotificationsService, AsyncPipe
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  constructor() {
    customElements.define("rehub-icon", RehubIconWebComponent);
    customElements.define("rehub-spinner", RehubSpinnerWebComponent);
    customElements.define("rehub-calendar", RehubCalendarWebComponent);
    customElements.define("rehub-coach2", RehubCoachWebComponent);
    customElements.define("rehub-goniometer", RehubGoniometerWebComponent);
    customElements.define("rehub-timer", RehubTimerWebComponent);
  }
}
