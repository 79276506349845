import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { environment } from '@env/environment';
import { API } from '@rehub-shared/api';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging
  ) {
    this.angularFireMessaging.messaging.subscribe((messaging:any) => {
      messaging.onMessage = messaging.onMessage.bind(messaging);
      messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    });
  }

  requestPermission(patientId: any) {
    this.angularFireMessaging.requestToken.subscribe((token: any) => {
      const url = "https://kenmdmcce3.execute-api.eu-west-1.amazonaws.com/dev/add/device-user";
      const data = {
        user: environment.stage + "_" + patientId,
        devices: token
      }

      API.post(url, data).then((result: any) => {
      });
    },
    (err: any) => {
      console.error("Unable to get permission to notify", err);
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.currentMessage.next(payload);
    },(err: any) => {
      console.error("error", err);
    });
  }
}