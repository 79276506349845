import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/@core/auth';
import { environment } from '@env/environment';
import { SharedRCSender } from '@rehub-shared/remote-controller/rc-sender';

@Injectable({
  providedIn: 'root',
})
export class RCSenderService extends SharedRCSender {

  constructor(
    private credentialsService: CredentialsService
  ) {
    super(environment.wsController, environment.chromecastNamespace, credentialsService.getSub());
  }

  setInfo(info: any): void {
    localStorage.setItem("info_sender", JSON.stringify(info));
  }

  getInfo(): string {
    return localStorage.getItem("info_sender");
  }

  removeInfo(): void {
    localStorage.removeItem("info_sender");
  }

}