import { API } from '../../api';
import { LogLevel } from './log';

export interface ILogPublisher{
  log(message: any, level: LogLevel): any;
}

export class LogPublisherConsole implements ILogPublisher{
  log(message: any, level: LogLevel){
    let levelString = `[${LogLevel[level]}]`;
    if(level == LogLevel.Error) console.error(levelString, ...message);
    else console.log(levelString, ...message);
  }
}

export class LogPublisherHttp implements ILogPublisher{

  private key: string;

  constructor(key: string){
    this.key = key;
  }

  log(message: any, level: LogLevel){
    API.postWithoutAuth("https://logs.dycare-services.com/log/publish", message).then((result: any)=>{});
  }
}