import { ControllerRC } from "./controller-rc.interface";

// tipos de controladores que hay para el controlador remoto
export enum ControllerType {
  Chromecast = "CHROME",
  WS = "WS"
} 
//métodos para obtener una instancia ControllerType a partir una cadena
export namespace ControllerType {
  export function toString(type: ControllerType): string {
    return (ControllerType as any)[type];
  }

  export function parse(type: string): ControllerType {
    return (ControllerType as any)[type];
  }
}

export abstract class SharedRCCommon {

  controller: ControllerRC;
  type: string;

  abstract createController(type: ControllerType, additionalConfig: string | null): void;

  // métodos a implementar para saber que esta creado el controlador y poder recuperarlo con la información correcta
  abstract setInfo(info: any): void;

  abstract getInfo(): any;

  abstract removeInfo(): void;

  getController(): ControllerRC {
    let info: any = this.getInfo();
    if (!this.controller && info && info.created) {
      this.createController(info.type, null);
    }
    return this.controller;
  }

  getType(): string {
    return this.type;
  }

}