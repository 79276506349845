import { html } from '../shared.js';

const ICON_BASE_WIDTH = 30;

export class RehubSpinnerWebComponent extends HTMLElement  {

  static get observedAttributes() {
    return ["size", "center"];
  }

  constructor() {
    super();
    this.center = false;
    this.size = 1;
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  render() {
    let width = ICON_BASE_WIDTH * this.size;
    let height = ICON_BASE_WIDTH * this.size;

    this.shadowRoot.innerHTML = html`
      <style>
        :host{
          display: block;
          width: ${width}px;
          height: ${height}px;
        }

        svg{
          fill: currentColor;
          stroke: currentColor;
          width: ${width}px;
          height: ${height}px;
          animation: spin 1s  alternate-reverse infinite;
        }
        .path {
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
          stroke-width: 4px;
          fill: none;
          animation: stroke 1s alternate-reverse infinite;
        }
        .circle{
          stroke-width: 1px;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes stroke {
          0% { stroke-dashoffset: 100; }
          100% { stroke-dashoffset: 60; }
        }
      </style>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path class="path" d="M13,15.75a6.39,6.39,0,0,1-.95-.69,7.18,7.18,0,0,1-1.37-1.53A7.41,7.41,0,1,1,24.25,10"/><circle class="circle" cx="13.02" cy="15.76" r="1.5"/><path class="path" d="M20.64,16.43a6.73,6.73,0,0,1,1-.54,7.32,7.32,0,0,1,2-.54,7.41,7.41,0,1,1,1.86,14.7,7.33,7.33,0,0,1-4.76-1"/><circle class="circle" cx="20.63" cy="16.42" r="1.5"/><path class="path" d="M16.74,21.62a6.58,6.58,0,0,1,.08,1.17,7.59,7.59,0,0,1-.31,2,7.41,7.41,0,1,1-11.35-8.2"/><circle class="circle" cx="16.76" cy="21.62" r="1.5"/></svg>
    `;

    if(this.center){
      let hostElement = this.shadowRoot.getRootNode().host;

      hostElement.style.position = "absolute";
      hostElement.style.left = `calc(50% - ${width / 2}px)`;
      hostElement.style.top = `calc(50% - ${height / 2}px)`;
    }

  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(name == "center") this.center = newValue == "true" || newValue == "";
    else this[name] = newValue;
    this.render();
  }

}
