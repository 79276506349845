<div class="modal-header">
  <h4 class="modal-title">{{ 'general_confirm' | translate }}</h4>
  <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column justify-content-center align-items-center pb-3" style="width: 500px">
  <ng-container *ngIf="step == 0">
    <span class="text-left mb-2" [innerHTML]="bodyTranslation1"></span>

    <div class="w-100 pt-2 pb-2">
      <rehub-checkbox [id]="'mandatory1'" [items]="[{value: false}]" (valueChanged)="onCheckboxPressed($event)">
        {{ 'modal_realtime_confirm_skip_install_realtime_checkbox' | translate }}
      </rehub-checkbox>
    </div>
  </ng-container>

  <ng-container *ngIf="step == 1">
    <span class="mt-3 text-left w-100" [innerHTML]="bodyTranslation2"></span>

  </ng-container>
</div>

<div class="border-0 pt-0 d-flex flex-row pb-3 justify-content-center align-items-center">
  <button type="button" class="btn btn-primary text-nowrap mr-4" (click)="onBackPressed()">{{"general_back" | translate}}</button>
  <button type="button" class="btn btn-danger text-nowrap" (click)="onAcceptPressed()">{{"general_accept" | translate}}</button>
</div>

<div class="modal-footer flex-row justify-content-start">
  <span style="font-size: 13px;">
    {{ 'modal_realtime_help_footer' | translate }}
    <a style="font-size: 13px;" class="cursor-pointer text-primary" [href]="supportUrl" target="_blank">{{ 'general_here' | translate }}</a>
  </span>
</div>