export const DEFAULT_CAMERA_HAND_POINTS = {
  WRIST_RIGHT: 0,
  THUMB_CMC_RIGHT: 1,
  THUMB_MCP_RIGHT: 2,
  THUMB_IP_RIGHT: 3,
  THUMB_TIP_RIGHT: 4,
  INDEX_FINGER_MCP_RIGHT: 5,
  INDEX_FINGER_PIP_RIGHT: 6,
  INDEX_FINGER_DIP_RIGHT: 7,
  INDEX_FINGER_TIP_RIGHT: 8,
  MIDDLE_FINGER_MCP_RIGHT: 9,
  MIDDLE_FINGER_PIP_RIGHT: 10,
  MIDDLE_FINGER_DIP_RIGHT: 11,
  MIDDLE_FINGER_TIP_RIGHT: 12,
  RING_FINGER_MCP_RIGHT: 13,
  RING_FINGER_PIP_RIGHT: 14,
  RING_FINGER_DIP_RIGHT: 15,
  RING_FINGER_TIP_RIGHT: 16,
  PINKY_MCP_RIGHT: 17,
  PINKY_PIP_RIGHT: 18,
  PINKY_DIP_RIGHT: 19,
  PINKY_TIP_RIGHT: 20,
  WRIST_LEFT: 21,
  THUMB_CMC_LEFT: 22,
  THUMB_MCP_LEFT: 23,
  THUMB_IP_LEFT: 24,
  THUMB_TIP_LEFT: 25,
  INDEX_FINGER_MCP_LEFT: 26,
  INDEX_FINGER_PIP_LEFT: 27,
  INDEX_FINGER_DIP_LEFT: 28,
  INDEX_FINGER_TIP_LEFT: 29,
  MIDDLE_FINGER_MCP_LEFT: 30,
  MIDDLE_FINGER_PIP_LEFT: 31,
  MIDDLE_FINGER_DIP_LEFT: 32,
  MIDDLE_FINGER_TIP_LEFT: 33,
  RING_FINGER_MCP_LEFT: 34,
  RING_FINGER_PIP_LEFT: 35,
  RING_FINGER_DIP_LEFT: 36,
  RING_FINGER_TIP_LEFT: 37,
  PINKY_MCP_LEFT: 38,
  PINKY_PIP_LEFT: 39,
  PINKY_DIP_LEFT: 40,
  PINKY_TIP_LEFT: 41
}

export const CAMERA_HAND_POINTS_VERSIONS: { [key: string]: typeof DEFAULT_CAMERA_HAND_POINTS } = {
  "0": DEFAULT_CAMERA_HAND_POINTS
}

export const DEFAULT_CAMERA_HAND_POINTS_CONTRALATERAL = {
  WRIST_RIGHT: DEFAULT_CAMERA_HAND_POINTS.WRIST_LEFT,
  THUMB_CMC_RIGHT: DEFAULT_CAMERA_HAND_POINTS.THUMB_CMC_LEFT,
  THUMB_MCP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.THUMB_MCP_LEFT,
  THUMB_IP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.THUMB_IP_LEFT,
  THUMB_TIP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.THUMB_TIP_LEFT,
  INDEX_FINGER_MCP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_MCP_LEFT,
  INDEX_FINGER_PIP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_PIP_LEFT,
  INDEX_FINGER_DIP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_DIP_LEFT,
  INDEX_FINGER_TIP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_TIP_LEFT,
  MIDDLE_FINGER_MCP_RIGHT:DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_MCP_LEFT,
  MIDDLE_FINGER_PIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_PIP_LEFT,
  MIDDLE_FINGER_DIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_DIP_LEFT,
  MIDDLE_FINGER_TIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_TIP_LEFT,
  RING_FINGER_MCP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_MCP_LEFT,
  RING_FINGER_PIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_PIP_LEFT,
  RING_FINGER_DIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_DIP_LEFT,
  RING_FINGER_TIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_TIP_LEFT,
  PINKY_MCP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.PINKY_MCP_LEFT,
  PINKY_PIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.PINKY_PIP_LEFT,
  PINKY_DIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.PINKY_DIP_LEFT,
  PINKY_TIP_RIGHT: DEFAULT_CAMERA_HAND_POINTS.PINKY_TIP_LEFT,
  WRIST_LEFT: DEFAULT_CAMERA_HAND_POINTS.WRIST_RIGHT,
  THUMB_CMC_LEFT: DEFAULT_CAMERA_HAND_POINTS.THUMB_CMC_RIGHT,
  THUMB_MCP_LEFT: DEFAULT_CAMERA_HAND_POINTS.THUMB_MCP_RIGHT,
  THUMB_IP_LEFT: DEFAULT_CAMERA_HAND_POINTS.THUMB_IP_RIGHT,
  THUMB_TIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.THUMB_TIP_RIGHT,
  INDEX_FINGER_MCP_LEFT: DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_MCP_RIGHT,
  INDEX_FINGER_PIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_PIP_RIGHT,
  INDEX_FINGER_DIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_DIP_RIGHT,
  INDEX_FINGER_TIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.INDEX_FINGER_TIP_RIGHT,
  MIDDLE_FINGER_MCP_LEFT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_MCP_RIGHT,
  MIDDLE_FINGER_PIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_PIP_RIGHT,
  MIDDLE_FINGER_DIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_DIP_RIGHT,
  MIDDLE_FINGER_TIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.MIDDLE_FINGER_TIP_RIGHT,
  RING_FINGER_MCP_LEFT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_MCP_RIGHT,
  RING_FINGER_PIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_PIP_RIGHT,
  RING_FINGER_DIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_DIP_RIGHT,
  RING_FINGER_TIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.RING_FINGER_TIP_RIGHT,
  PINKY_MCP_LEFT: DEFAULT_CAMERA_HAND_POINTS.PINKY_MCP_RIGHT,
  PINKY_PIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.PINKY_PIP_RIGHT,
  PINKY_DIP_LEFT: DEFAULT_CAMERA_HAND_POINTS.PINKY_DIP_RIGHT,
  PINKY_TIP_LEFT:  DEFAULT_CAMERA_HAND_POINTS.PINKY_TIP_RIGHT
}

export const CAMERA_HAND_POINTS_CONTRALATERAL_VERSIONS: { [key: string]: typeof DEFAULT_CAMERA_HAND_POINTS_CONTRALATERAL } = {
  "0": DEFAULT_CAMERA_HAND_POINTS_CONTRALATERAL
}
