import { API } from "./api";

export class RecordAPI extends API{

  static postRecordRealtimePrepare(data: any){
    const url = `${this.getBaseUrl()}/record/LIST/realtime/prepare`;
    return this.post(url, data);
  }

  static getRecordRealtimeData(tempId: string, action: string = "EXERCISE"){
    const url = `${this.getBaseUrl()}/record/realtime/data/${tempId}`;
    return this.getWithoutAuth(url, {action}, {cache: true});
  }

  static getRecord(recordId: string) {
    const url = `${this.getBaseUrl()}/record/from-patient/${recordId}`;
    return this.get(url, {});
  }

  static getRecordDataSmoothStandardDeviation(recordId: string, signals: any[] | null = null, mods: any = null, signalAssessment: boolean = false){
    const url = `${this.getBaseUrl()}/record/chart/smooth-standard-deviation/${recordId}`;

    let data: any = {};

    if(signals) data.signals = signals;
    if(signalAssessment) data.signalAssessment = signalAssessment;
    if(mods) data.mods = mods;

    return this.post(url, data, {timeout: 20000});
  }

  static getMyRoutineByDays(data: {patientId: string, date: any, days?: number, therapyId?: string}){
    if(data.days == null) data.days = 13;

    const url = `${this.getBaseUrl()}/record/from-patient/all/${data.patientId}/exercises/by-days`;
    return this.get(url, data);
  }

  static getAllRecordsFromDiagnosis(patientId: string, diagnosisId: string, groupByPlannedDate: boolean = false){
    const url = `${this.getBaseUrl()}/record/from-patient/all/${patientId}/${diagnosisId}`;
    let data: any = {};
    if(groupByPlannedDate) data.groupByPlannedDate = groupByPlannedDate;
    return this.post(url, data);
  }

  static getAllRecordsTherapy(patientId: string, therapyId: string | null = null, getExercises: boolean = false, diagnosisId: string | null = null){
    const url = `${this.getBaseUrl()}/record/from-patient/all/${patientId}/exercises/therapy`;
    let data: any = {
      getExercises: getExercises
    }
    if(therapyId) data.therapyId = therapyId;
    if(diagnosisId) data.diagnosisId = diagnosisId;
    return this.post(url, data, {timeout: 20000});
  }

  static getMathbox(recordId: string, signals: string[] | null = null){
    const url = `${this.getBaseUrl()}/record/mathbox/${recordId}`;
    let data: any = {};
    if(signals) data.signals = signals;
    return this.post(url, data);
  }

  static getMyRoutineWeek(patientId: string, weeks: any = null){
    const url = `${this.getBaseUrl()}/record/from-patient/all/${patientId}/exercises/by-week`;
    let data: any = {
      weeks: weeks
    }
    return this.post(url, data);
  }

  static updateMinMaxSelection(recordId: string, minValue: number, maxValue: number, signal: string = "Pitch"){
    const url = `${this.getBaseUrl()}/record/update/selection`;
    const data = {
      signal: signal,
      min: minValue,
      max: maxValue,
      recordId: recordId
    }
    return this.post(url, data);
  }

  static updateValue(recordId: string, value: any){
    const url = `${this.getBaseUrl()}/record/update/value`;
    const data = {
      recordId: recordId,
      ...value
    }
    return this.post(url, data);
  }

  static getRecordData(recordId: string, args: any = {}) {
    const url = `${this.getBaseUrl()}/record/data/${recordId}`;
    return this.get(url, args);
  }

  static getRecordScore(recordId: string, data: any){
    const url = `${this.getBaseUrl()}/record/${recordId}/score`;
    return this.post(url, data);
  }

  static deleteRecords(recordIds: string[], republish: boolean = false){
    const url = `${this.getBaseUrl()}/record/update/undo`;
    const data = {
      recordIds: recordIds,
      republish: republish
    }
    return this.post(url, data);
  }

  static uploadDoctorRecordDiagnosisFree(data: any){
    const url = `${this.getBaseUrl()}/record/upload/v2/doctor/free`;
    return this.post(url, data);
  }

}