<div class="modal-header">
  <h4 class="modal-title">{{ 'modal_realtime_disconnected_title' | translate }}</h4>
  <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column justify-content-left align-items-left" [ngClass]="{'modal-disconnect-reset': resetting}" style="width: 450px;">
  <span *ngIf="!resetting" class="text-left" [innerHTML]="bodyTranslation"></span>

  <span *ngIf="resetting" class="text-left">{{ 'modal_realtime_disconnected_reset_body' | translate }}</span>

  <div class="w-100 text-center">
    <rehub-spinner size="2" *ngIf="resetting" class="mx-auto mt-3"></rehub-spinner>
  </div>

  <span *ngIf="timedOut" class="mt-3">{{ 'modal_realtime_timeout' | translate }}</span>
</div>

<div *ngIf="!resetting" class="d-flex flex-column justify-content-start align-items-start pl-4">
  <div>
    <input class="cursor-pointer" type="radio" name="action" value="reset" id="reset" (change)="onActionChanged($event.target.value)">
    <label class="cursor-pointer" for="reset">{{'modal_realtime_disconnected_reset' | translate}}</label><br>
  </div>

  <div>
    <input class="cursor-pointer" type="radio" name="action" value="install" id="install" (change)="onActionChanged($event.target.value)">
    <label class="cursor-pointer" for="install" >{{'modal_realtime_disconnected_install' | translate}}</label>
  </div>
</div>

<div class="border-0 pt-0 d-flex flex-row p-3 justify-content-center align-items-center">
  <button *ngIf="!resetting" type="button" class="btn btn-primary text-nowrap mr-4" (click)="onAcceptPressed()" [disabled]="actionSelected == null">{{"general_accept" | translate}}</button>
  <button  type="button" class="btn btn-danger text-nowrap" (click)="onCancelPressed()">{{"general_cancel" | translate}}</button>
</div>

<div class="modal-footer flex-row justify-content-start">
  <span style="font-size: 13px;">
    {{ 'modal_realtime_help_footer' | translate }}
    <a style="font-size: 13px;" class="cursor-pointer text-primary" [href]="supportUrl" target="_blank">{{ 'general_here' | translate }}</a>
  </span>
</div>