<div class="loader bg-transparent no-shadow p-0" *ngIf="isLoading">
  <div class="ball-clip-rotate">
    <div
      style="width:{{ size }}px;height:{{ size }}px;border-width: {{ borderWidth }}px;border-top-color:{{
        color
      }}; border-left-color:{{ color }};border-right-color:{{ color }}"
    ></div>
  </div>
  <span>{{ message }}</span>
</div>
