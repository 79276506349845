<div class="row">
  <div class="col" *ngFor="let item of items">
    <div class="text-center">
      <div class="d-flex flex-row justify-content-center">
        <div class="form-check">
          <input class="form-check-input checkbox {{getButtonTheme()}}" type="checkbox" value="{{item.value}}" [attr.id]="id + '_' + item.value"
            [attr.checked]="answered == item.value ? '' : null" (change)="changeBox(item.value);">
          <label class="form-check-label checkmark {{getButtonTheme()}}" [attr.for]="id + '_' + item.value"
            [ngClass]="{'checkbox-selected': answered == item.value, 'pointer': !disabled}">{{item.text.es}}</label>
        </div>
      </div>
    </div>
  </div>
</div>