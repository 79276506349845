import { Injectable, isDevMode, Injector } from '@angular/core';
import { environment } from '@env/environment';
import { AuthenticationService, CredentialsService } from './@core/auth';
import { DeployInfoService } from './@core/service/deploy-info.service';
import { FeatureFlagsService } from './@core/service/feature-flags.service';
import { ThemeService } from './@core/service/theme.service';
import { I18nService } from './i18n';
import { API, ChatAPI, FileAPI, LoginAPI, NotificationAPI, PatientAPI, ProtocolAPI, RecordAPI, SensorAPI, TherapyAPI, UserAPI, SignUpAPI } from '@rehub-shared/api';
import { SharedAppConfig } from "@rehub-shared";
import { Title } from "@angular/platform-browser";

import axios from "axios";
import * as moment from "moment";

import { TherapyUtils } from '@rehub-shared/utils';
import { CoachService } from './@core/service/coach.service';

@Injectable()
export class AppConfig extends SharedAppConfig{

  constructor(
    private injector: Injector,
    i18nService: I18nService,
    credentialsService: CredentialsService,
    authenticationService: AuthenticationService,
    deployInfoService: DeployInfoService,
    featureFlagsService: FeatureFlagsService,
    themeService: ThemeService
  ){
    super(environment.professional_url, isDevMode(), environment, i18nService, credentialsService, authenticationService, deployInfoService, featureFlagsService, themeService, null);
  }

  load(){
    const titleService = this.injector.get(Title);
    titleService.setTitle(this.environment.title );
    document.getElementById('appFavicon').setAttribute('href', this.environment.favicon);

    API.setAxiosInstance(axios);

    ChatAPI.setBaseUrl(environment.message_server_url);
    LoginAPI.setBaseUrl(environment.login_url_backend);
    UserAPI.setBaseUrl(environment.user_server_url);
    FileAPI.setBaseUrl(environment.file_server_url);
    PatientAPI.setBaseUrl(environment.patient_server_url);
    TherapyAPI.setBaseUrl(environment.therapy_server_url);
    RecordAPI.setBaseUrl(environment.records_server_url);
    ProtocolAPI.setBaseUrl(environment.protocol_server_url);
    NotificationAPI.setBaseUrl(environment.notification_server_url);
    SensorAPI.setBaseUrl(environment.sensor_server_url);
    SignUpAPI.setBaseUrl(environment.signup_url_backend);

    TherapyUtils.setMoment(moment);

    this.coachService = this.injector.get(CoachService);

    return this.loadConfig().then(()=>{
      return this.refreshCredentials();
    }).then((loginInfo: any)=>{
      let credentialsInfo = this.credentialsService!.getInfo();
      if(credentialsInfo && credentialsInfo.lang) this.i18nService!.setLanguage(credentialsInfo.lang);

      // Seleccionar tema
      let themeName = loginInfo && loginInfo.center && loginInfo.center.themeName ? loginInfo.center.themeName : "";
      let themeConfig = loginInfo && loginInfo.center && loginInfo.center.themeConfig ? loginInfo.center.themeConfig : null;
      this.themeService.setTheme(themeName,themeConfig);

      return true;
    }).catch(()=>{
      this.redirectToLogin();
    });
  }
}
