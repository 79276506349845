export class ScreenLockUtil {

  private screenLock: any;
 
  public static isScreenLockSupported() {
    return ('wakeLock' in navigator);
  }

  public lockScreen() {
    if (ScreenLockUtil.isScreenLockSupported()) {
      if (document.visibilityState === 'visible' && !this.isLocked()) this.lock();
      else {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible' && !this.isLocked()) this.lock();
        });
      }
    }
  }

  public releaseScreen() {
    if (this.isLocked()) {
      this.screenLock.release().then(() => {
        this.screenLock = null;
      });
    }
  }

  private getNavigator() {
    return  (navigator as NavigatorExtended);
  }

  private isLocked() {
    return typeof this.screenLock !== "undefined" && this.screenLock != null;
  }

  private lock() {
    this.getNavigator().wakeLock.request('screen').then((lock: any) => {
      this.screenLock = lock;
    });
  }

}

interface WakeLock {
  request(type: string): Promise<WakeLockSentinel>;
}

interface WakeLockSentinel {
  release(): Promise<void>;
  readonly onrelease: any;
  readonly WakeLocktype: WakeLockType;
  addEventListener(event:string, callback: EventListenerOrEventListenerObject, options? : any ): void;
  removeEventListener(event:string, callback: EventListenerOrEventListenerObject, options? : any ): void;
}

type WakeLockType = 'screen' | null;

interface NavigatorExtended extends Navigator {
  // Only available in a secure context.
  readonly wakeLock: WakeLock;
}