import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { SharedFormSliderComponent } from '@rehub-shared/component/form/answerTypes/slider/form-slider.component';
@Component({
  selector: 'rehub-form-slider',
  templateUrl: '../../../../../../../shared/src/component/form/answerTypes/slider/form-slider.component.html',
  styleUrls: ['../../../../../../../shared/src/component/form/answerTypes/slider/form-slider.component.scss']
})
export class FormSliderComponent extends SharedFormSliderComponent implements OnInit {

  @Input() min: number;
  @Input() max: number;
  @Input() number: number = 0;
  @Output() changeSlider = new EventEmitter();

  ngOnInit() {
    this.init();
  }

  emitChangeSlider(value: any) {
    this.changeSlider.emit(value);
  }

  getButtonTheme(): string {
    return "patient";
  }

}