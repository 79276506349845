import { Permission, PermissionsUtils } from "../utils/permissions";
import { PlansUtils } from "../utils/plan.utils";
import { ProtocolUtils } from "../utils/protocol-utils";

const CREDENTIALS_KEY = "credentials";

export class SharedCredentialsService {

  protected credentials: any;
  protected user : any;
  protected patient : any;

  constructor(
    private moment: any
  ) {}

  getCredentials(){
    if(!this.credentials){
      let credentials: any = localStorage.getItem(CREDENTIALS_KEY);
      if(credentials){
        this.credentials = JSON.parse(credentials);
      }    }
    if(this.credentials){
      if(this.isProfessional()) this.credentials.user= this.getUser();
      if(this.isPatient()) this.credentials.patient= this.getPatient();
    }
    return this.credentials;
  }

  getSub() {
    let credentials = this.getCredentials();
    return credentials ? credentials.sub : "";
  }

  appendCredentials(credentials: any){
    if(!credentials) return;
    let newCredentials = {...this.credentials,...credentials};
    this.setCredentials(newCredentials);
  }

  setUser(user: any){
    this.user = user;
  }

  getUser(){
    return this.user;
  }

  setPatient(patient: any){
    this.patient = patient;
  }
  getPatient(){
    return this.patient;
  }

  setCredentials(credentials: any){

    this.credentials = credentials || null;
    if (this.credentials) {
      // delete  this.credentials.user.dni;
      // delete  this.credentials.email;
      // delete  this.credentials.family_name;
      if(this.credentials.user) delete  this.credentials.user;
      if(this.credentials.patient) delete  this.credentials.patient;
      localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials));
    } else localStorage.removeItem(CREDENTIALS_KEY);
  }

  clearCredentials(){
    this.setCredentials(null);
  }

  clearCredential(key: string){
    let newCredentials = {...this.credentials};
    if(this.credentials && this.credentials[key]) delete newCredentials[key];
    this.setCredentials(newCredentials);
  }

  isAuthenticated(){
    return this.getCredentials() != null && this.credentials.idToken && this.credentials.accessToken;
  }

  isPatient(){
    return this.credentials && this.credentials.userType && this.credentials.userType.startsWith("patient");
  }

  isProfessional(){
    return this.credentials && this.credentials.userType && this.credentials.userType.startsWith("doctor");
  }

  isAdmin(){
    return false;
  }

  isTemp(){
    return this.credentials && this.credentials.temp;
  }

  // TODO: mejorar
  isPending(){
    if(this.isPatient()){
      if (this.isUnderAgePatient()) {
        return this.credentials.isPending || !this.isUnderAgeLegalPatient();
      }

      return this.credentials && this.credentials.isPending;
    }else if(this.isProfessional()){
      return this.credentials && this.credentials.isPending;
    }
  }

  isMissingUserInfo(){
    if(this.isPatient()){
      let missingInfo = false;
      let patient = this.credentials.patient;
      if(patient){
        if(!patient.birthDate || !patient.patientName || !patient.familyName) missingInfo = true;
      }
      return missingInfo;
    }
  }

  //TODO: mejorar
  shouldShowWelcomeTutorial(){
    if(this.isPatient()){
      let afterDate = this.moment(this.patient.creationDate).isAfter("2022-01-18T00:00:00");
      let completed = this.credentials.tempParams && this.credentials.tempParams.welcomeTutorial && this.credentials.tempParams.welcomeTutorial.includes("completed");

      return afterDate && !completed;
    }else if(this.isProfessional()){
      let afterDate = this.moment(this.user.creationDate).isAfter("2021-06-28T00:00:00");

      let completed = this.credentials.tempParams && this.credentials.tempParams.welcomeTutorial && this.credentials.tempParams.welcomeTutorial.includes("completed");
      let skipped = this.credentials.tempParams && this.credentials.tempParams.welcomeTutorial && this.credentials.tempParams.welcomeTutorial.includes("skipped");

      return afterDate && !completed && !skipped;
    }

  }
  isTherapyFinished(){
    if(this.isPatient() && this.patient.therapyStatus){
      let therapyStatus= this.patient.therapyStatus;
      const today =this.moment().startOf('day');;
      const splitTherapyStatus = therapyStatus.split("_")[1];
      const therapyStatusFinal = this.moment.utc(splitTherapyStatus).startOf('day');

      const diasDeDiferencia = today.diff(therapyStatusFinal, 'days');
      if (diasDeDiferencia > 8 && therapyStatus.startsWith("FINISHED")) {
        return true ;
      } else {
        return false;
      }
    } else return false;
  }

  isExpired() {
    if(this.isPatient()){
      return this.credentials && this.credentials.expirationDate && this.moment.utc(this.credentials.expirationDate).isSameOrBefore(this.moment.utc(), "day");
    }else if(this.isProfessional()){
      if (this.credentials && this.user && this.user.expirationDate) {
        let expirationDate = this.moment.utc(this.user.expirationDate, "YYYY-MM-DDTHH:mm:ss.SSSZ");
        return this.moment.utc().isSameOrAfter(expirationDate, "day");
      }
      return false;
    }
  }
  isAssegur(){
    return this.isMyOrganization("ASSEGUR");
  }
  isItech() {
    return this.credentials && this.patient && (this.patient.origin == "itech");
  }

  isSelfService(){
    return this.credentials && this.patient && this.patient.selfService;
  }
  getOrigin() {
    return this.credentials && this.patient && this.patient.origin;
  }

  treesSSAcces() {
    let ssPermissions= this.getPermissions()
    const filteredPermissions: string [] = [] ;

    for (const key in ssPermissions) {
      if (key.startsWith('SS_tree_') && ssPermissions[key] === true) {
        const paramName = key.replace('SS_tree_', '');
        filteredPermissions.push(paramName);
      }
    }

    return filteredPermissions;
  }
  pillsSSAcces() {
    let ssPermissions= this.getPermissions()
    const filteredPermissions: string [] = [] ;

    for (const key in ssPermissions) {
      if (key.startsWith('SS_pill_') && ssPermissions[key] === true) {
        const paramName = key.replace('SS_pill_', '');
        filteredPermissions.push(paramName);
      }
    }

    return filteredPermissions;
  }


  // TODO: renombrar a hasPlan
  isPlan() {
    let credentials = this.getCredentials();
    return credentials && credentials.user && credentials.user.userPlan;
  }

  getPlan() {
    return this.isPlan() ? this.user.userPlan : null;
  }

  isTrial() {
    return this.isPlan() ? this.user.userPlan == PlansUtils.TRIAL : false;
  }

  getPaymentMethod() {
    let credentials = this.getCredentials();
    return credentials && credentials.user && credentials.user.paymentMethod ?
      credentials.user.paymentMethod : null;
  }

  isRefreshTokenValid(){
    if(!this.credentials) return true;
    return this.moment(this.credentials.refreshTokenExp).isAfter();
  }

  setDashboardSettings(dashboardSettings: any){
    this.appendCredentials({
      dashboardSettings: dashboardSettings
    });
  }

  setPendingDailyPatientStatus(pendingDailyPatientStatus: any){
    this.appendCredentials({
      pendingDailyPatientStatus: pendingDailyPatientStatus
    });
  }

  setPendingEndOfTherapy(pendingEndOfTherapy: any) {
    this.appendCredentials({
      pendingEndOfTherapy:pendingEndOfTherapy
    });
  }

  setNeedAnswerAdherenceForm(needAnswerAdherenceForm: any) {
    this.appendCredentials({
      needAnswerAdherenceForm: needAnswerAdherenceForm
    });
  }

  setSatisfactionForm(satisfactionForm: any){
    this.appendCredentials({
      satisfactionForm: satisfactionForm
    });
  }

  isUnderAgePatient() {
    if (this.credentials && this.patient) {
      if(this.credentials.patient && this.credentials.patient.birthDate){
        var start = this.moment.utc(this.patient.birthDate);
        var end = this.moment.utc();
        let age = end.diff(start, "years");
        return age < 18;
      }else if(this.patient.isMinor){
        return true;
      }
    }
    return false;
  }

  markAsActive(){
    this.appendCredentials({
      isPending: false
    });
  }

  markOptional4() {
    let patient = this.patient;
    patient.optional4 = this.moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    this.appendCredentials({ patient });
  }

  passwordResetRequired(){
    let credentialsInfo = this.getInfo();
    return credentialsInfo.pwdResetRequired == true;
  }

  getInfo(){
    if(this.isPatient()){
      return this.patient;
    }else if(this.isProfessional()){
      return this.user;
    }
  }

  checkPermission(permission: Permission) {
    return PermissionsUtils.checkPermission(this.getPermissions(), permission, this.isPatient());
  }

  getPermissions() {
    if(this.isPatient()){
      return this.getCredentials().parentPermissions;
    }else if(this.isProfessional()){
      return this.getCredentials().permissions;
    }
  }



  getTrialPermission(permission: Permission) {
    return PermissionsUtils.getTrialPermission(this.getPermissions(), permission);
  }

  getEnabledModules(){
    let categories = ProtocolUtils.getCategories();
    let enabledCategories: any[] = [];
    categories.forEach((category: string)=>{
      if(this.checkPermission((Permission as any)["Mod_" + category])) enabledCategories.push(category);
    });
    return enabledCategories;
  }

  getMyId() {
    let credentials = this.getCredentials();
    if (credentials) return credentials.sub;
    else return null;
  }

  getMyCenterId(){
    let credentials = this.getCredentials();
    if (credentials && credentials.user) return credentials.user.group;
    else return null;
  }

  isMyId(id: string){
    let credentials = this.getCredentials();
    return credentials && credentials.sub == id;
  }

  isMyCenterId(centerId: string) {
    let credentials = this.getCredentials();
    return credentials && credentials.user && credentials.user.group && credentials.user.group == centerId;
  }

  isMyOrganization(organizationId: string) {
    let credentials = this.getCredentials();
    return credentials && credentials.org && credentials.org==organizationId;
  }

  isMyCenterVitalHubId(tempId: string) {
    let credentials = this.getCredentials();
    return credentials && credentials.center && credentials.center.tempId && credentials.center.tempId == tempId;
  }

  isAlthaiaCenter() {
    let credentials = this.getCredentials();
    return credentials && credentials.center && credentials.center.tempId && credentials.center.tempId == "0ff98fc6-4448-11ef-8812-16e78a6b26b9";
  }

  createTempCredentials(sub: any, idToken: any){
    let credentials = {
      temp: true,
      sub: sub,
      idToken: idToken
    }
    this.setCredentials(credentials);
  }
  shouldShowPharmaForm() {
      let completed = this.credentials.tempParams && this.credentials.tempParams.pharmaForm;

      return !completed;
  }

  setPendingPharmaForm(value:any){
    if(this.credentials.tempParams) this.credentials.tempParams.pharmaForm = value;
    else this.credentials.tempParams = {pharmaForm: value};
  }


  protected isUnderAgeLegalPatient() {
    if (this.credentials && this.patient) {
      return !!this.patient.optional4;
    } else {
      return false;
    }
  }
}