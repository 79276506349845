<div class="modal-header p-3" style="justify-content: center;">
  <h2 class="modal-title m-4" style="letter-spacing: .1rem;">{{"patient_modal_finished_daily_title" | translate}}</h2>

  <a class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column pt-0" style="background: #BAF8F166;">
  <img class="w-100 mx-auto" [src]="url" style="max-width: 25rem; margin-top: -80px;" />
  <div class="w-100 mx-auto" style="max-width: 25rem;">
    <p class="text-center text-primary pl-4 pr-4 w-100" style="font-size: large;">
      {{"patient_modal_finished_daily_visit_your" | translate}}&nbsp;
      <strong>{{'navigation_evolution' | translate}}</strong>&nbsp;
      {{"patient_modal_finished_daily_body" | translate}}
    </p>
  </div>
</div>

<div class="modal-footer border-0 pt-0 d-flex justify-content-center" style="background: #BAF8F166;">
  <div style="display:flex; flex-direction: column;">
    <button type="button" class="btn btn-primary btn-lg d-flex flex-row align-items-center justify-content-center px-4"
      style="border-radius: 30px; padding-left: 4rem!important;  padding-right: 4rem!important"  (click)="visitProgress()">
      <span>Visitar</span>
    </button>
    <div class="text-center text-primary mb-4 mt-4">
      <a data-dismiss="modal" aria-label="Close" style="text-transform: uppercase; font-size: 1rem; font-weight: bold; cursor: pointer; letter-spacing: .1rem;" (click)="onClose()">
        {{"patient_modal_finished_daily_close" | translate}}
      </a>
  </div>

</div>