
<div class="row">
  <div class="col-sm" [ngClass]="{'pl-0 pr-0': !isClickable()}">
    <h4 class="text-title" *ngIf="showAllExercises">{{ 'general_all_exercises' | translate }}</h4>
    <h4 class="text-title" *ngIf="showTotalExercises">{{ titleExercises }}</h4>
    <div class="mt-3 overflow-auto" style="max-height: 310px;">

      <div class="mt-3 ">
        <ng-container *ngFor="let exercise of exercises; let last = last; let first = first;">

          <div *ngIf="!hideFirstExercise || (hideFirstExercise && !first)" class="d-flex py-3 border-top"
            [ngClass]="{'border-bottom': last, 'pointer': isClickable() && exercise.recordState != 'DONE', 'flex-column align-items-center': SharedUtils.isScreenWidthLittle() && !SharedUtils.isMobile() && responsiveExercise}"
            (click)="start(exercise)">
            <ng-container *ngTemplateOutlet="exerciseInfo; context: {exercise: exercise, showExtraInfo: true}"></ng-container>
          </div>

        </ng-container>
      </div>


    </div>
  </div>

</div>
<ng-template #exerciseInfo let-exercise="exercise" let-showExtraInfo="showExtraInfo">
  <div class="flex-shrink-0 text-center" class="content-image my-auto">
    <img [src]="exercise.imageSmallURL" alt="" class="w-100" [ngClass]="{'done': exercise.recordState == 'DONE'}">
  </div>

  <div class="px-3 flex-grow-1 d-flex flex-column text-left" [ngClass]="{'done': exercise.recordState == 'DONE'}">
    <p class="text-title small mb-1">{{exercise.jointTranslation}}:</p>

    <div class="d-flex flex-row align-items-center" style="gap: 10px;">
      <h4 class="mr-2 mb-0 align-self-center text-title" style="font-size: 16px!important;" [ngClass]="{'flex-fill': Utils.isMobile()}">{{exercise.protocolTranslation}}</h4>
    </div>

    <div class="row extra-info mt-2 px-2" *ngIf="showExtraInfo">
      <div class="col d-flex flex-row justify-content-center align-items-center  px-1">
        <rehub-icon name="time_1" size="20" class="mr-1 text-primary"></rehub-icon>
        <h2 class="text-title " style="font-weight: 400 !important;">{{exercise.duration}}</h2>
      </div>
      <div class="col d-flex flex-row justify-content-center align-items-center  px-1">
        <rehub-icon name="components" size="20" class="mr-1 text-primary"></rehub-icon>
        <h2 class="text-title therapy-info-values" style="font-weight: 400 !important;">{{exercise.setsCount}}</h2>
      </div>
      <div class="col d-flex flex-row justify-content-center align-items-center px-1">
        <rehub-icon  name="duplicate_1" size="20" class="mr-1 text-primary"></rehub-icon>
        <h2 class="text-title therapy-info-values" style="font-weight: 400 !important;">{{exercise.iterations}}</h2>
      </div>
      <ng-container *ngIf="showExtraInfo">
        <div class="col d-flex flex-row justify-content-center align-items-center px-1">
          <!-- TODO: better way to check if has sensor,camera and tools  -->
          <rehub-icon *ngIf="protocols[exercise.protocol].cameraEnabled" name="sensor_camera" class="mr-1 text-primary" size="20"></rehub-icon>
          <rehub-icon *ngIf="hasSensor && protocols[exercise.protocol].sensorEnabled" class="mr-1 text-primary" [attr.name]="SharedUtils.getSensorTypeIcon(protocols[exercise.protocol].sensorType)" size="16"></rehub-icon>
          <ng-container *ngIf="!protocols[exercise.protocol].cameraEnabled && !(hasSensor && protocols[exercise.protocol].sensorEnabled) ">
            <ng-container *ngIf="protocols[exercise.protocol].tools && protocols[exercise.protocol].tools.length > 0">
              <rehub-icon *ngFor="let tool of protocols[exercise.protocol].tools" [attr.name]="SharedUtils.getToolIcon(tool)"
                class="text-primary mx-1" size="20"></rehub-icon>
            </ng-container>
          </ng-container>
        </div>
        <div class="col d-flex flex-row justify-content-center align-items-center  px-1">
          <ng-container *ngIf="protocols[exercise.protocol].cameraEnabled || (hasSensor && protocols[exercise.protocol].sensorEnabled) ">
            <ng-container *ngIf="protocols[exercise.protocol].tools && protocols[exercise.protocol].tools.length > 0">
              <rehub-icon *ngFor="let tool of protocols[exercise.protocol].tools" [attr.name]="SharedUtils.getToolIcon(tool)" class="text-primary"  size="20"></rehub-icon>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <rehub-exercise-during-per-week *ngIf="exercise.duringWeek && exercise.duringWeek.perWeekMax != null"
      [duringWeek]="exercise.duringWeek"></rehub-exercise-during-per-week>

  </div>
</ng-template>