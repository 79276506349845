import { html } from '../shared.js';

export class RehubTimerWebComponent extends HTMLElement  {

  static get observedAttributes() {
    return ["startvalue", "autostart", "format", "countdown"];
  }

  constructor() {
    super();

    this.format = "s"; // s, mm:ss
    this.value = 0;
    this.autostart = false;
    this.countdown = false;

    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  disconnectedCallback() {
    this.clearInterval();
  }

  restart(){
    this.value = this.startvalue;
    this.setOutput(this.formatValue(this.value));
    this.clearInterval();
    this.start();
  }

  start(){
    this.clearInterval();

    this.interval = setInterval(()=>{
      if(this.countdown) this.value--;
      else this.value++;

      this.setOutput(this.formatValue(this.value));

      if(this.value == 0) this.finishInterval();
    }, 1000);
  }

  pause(){
    this.clearInterval();
  }

  clearInterval(){
    if(this.interval) clearInterval(this.interval);
  }

  finishInterval(){
    this.clearInterval();

    this.dispatchEvent(new CustomEvent("finish", {
      composed: true
    }));
  }

  render() {
    this.shadowRoot.innerHTML = html`
      <span id="output"></span>
    `;

    this.setOutput(this.formatValue(this.value));
  }

  formatValue(value){
    if (this.format == "mm:ss"){
      let minutes = Math.floor(value / 60);
      let seconds = value % 60;

      let minutesFormat = minutes < 10 ? "0" + minutes : minutes;
      let secondsFormat = seconds < 10 ? "0" + seconds : seconds;

      return minutesFormat + ":" + secondsFormat;
    }

    return "" + value;
  }

  setOutput(output){
    let outputElement = this.shadowRoot.querySelector("#output");
    if(outputElement) outputElement.innerText = output;
  }

  attributeChangedCallback(name, oldValue, newValue) {

    if(name == "startvalue"){
      this.startvalue = parseInt(newValue);
      this.value = this.startvalue;
      this.setOutput(this.formatValue(this.value));
    }else if(name == "autostart"){
      this.autostart = newValue == "true" || newValue == "";
    }else if(name == "countdown"){
      this.countdown = newValue == "true" || newValue == "";
    }else{
      this[name] = newValue;
    }

    if(this.autostart && this.startvalue != null) this.start();

  }

}