export class TherapyUtils {

  private static moment: any;

  public static setMoment(moment: any){
    this.moment = moment;
  }

  public static getTherapyCompletionOfAllTherapies(diagnosisInfo: any){

    let result: any = {
      percentage: null,
      remainingDays: null,
      from: null,
      to: null
    }

    if(!diagnosisInfo || !this.hasTherapy(diagnosisInfo)){
      return result;
    }

    let therapies = this.getActiveTherapiesAndSort(diagnosisInfo);

    if(therapies.length > 0){
      let from = this.getFirstFromDateOfTherapies(diagnosisInfo);
      let to = this.getLastToDateOfTherapies(diagnosisInfo);

      result.percentage = this.getDatePercentage(from, to);
      result.remainingDays = this.getRemainingDays(to);
      result.from = from;
      result.to = to;

    }else{
      result.percentage = 100;
    }

    return result;
  }

  /**
   * Se hace un recorrido y se busca la fecha de inicio más pequeña entre todas las terapias
   * @param diagnosisInfo
   * @returns La fecha de finalización más pequeña
   */
  public static getFirstFromDateOfTherapies(diagnosisInfo: any){
    //TODO! REFACTOR Filter está repetido en varios sitios
    let therapies = this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).therapy
    .filter((t: any)=>t.from && t.to && t.totalExercises && this.moment.utc(t.to).isSameOrAfter(this.moment.utc(), 'day'));

    let result: any = "";

    therapies.forEach((therapy: any)=>{
      if(result == "" || this.moment.utc(therapy.from).isBefore(result)) result = therapy.from;
    });

    return result;
  }

  /**
   * Se hace un recorrido y se busca la fecha de finalización más grande entre todas las terapias
   * @param diagnosisInfo
   * @returns La fecha de finalización más grand
   */
  public static getLastToDateOfTherapies(diagnosisInfo: any){
    //TODO! REFACTOR Filter está repetido en varios sitios
    let therapies = this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).therapy
    .filter((t: any)=>t.from && t.to && t.totalExercises && this.moment.utc(t.to).isSameOrAfter(this.moment.utc(), 'day'));

    let result: any = "";

    therapies.forEach((therapy: any)=>{
      if(result == "" || this.moment.utc(therapy.to).isAfter(result)) result = therapy.to;
    });

    return result;
  }

  public static getRemainingDays(date: any){
    if(!date) return null;

    let today = this.moment.utc().startOf('day');
    let to = this.moment.utc(date).startOf('day');
    let daysDiff = to.diff(today, "days");

    // Se suma uno para que cuente el dia de fin
    return daysDiff + 1;
  }

  public static hasTherapy(diagnosisInfo: any){
    return this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).therapy
    .filter((t: any)=>t.from && t.to && t.totalExercises).length > 0;
  }

  public static hasTherapyActive(diagnosisInfo: any){
    let therapies = this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).therapy;
    return therapies.some((t: any)=>t.from && t.to && t.totalExercises);
  }

  public static hasDiagnosis(diagnosisInfo: any){
    return this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).diagnosis.length > 0;
  }

  /**
   *
   * @param exerciseGroups lista de ejercicios que contiene las iteraciones, numero de sets y duración de las
   * iteraciones de los mismos
   * @returns Suma total del producto de la multiplicacion de iteraciones,nº de sets y duracion de la iteracion del ejercicio
   */

  public static getTherapyTotalTime(exerciseGroups: any[]){
    let result = exerciseGroups.reduce((acum: number, current: any)=>{
      return acum + (current.referenceRecord.iterations * current.referenceRecord.setsCount * current.referenceRecord.iterationTime);
    }, 0);

    return result;
  }

  public static getActiveTherapiesAndSort(diagnosisInfo: any){
    return this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).therapy
    .filter((t: any)=>t.from && t.to && t.totalExercises && this.moment.utc(t.to).isSameOrAfter(this.moment.utc(), 'day'))
    .sort((a: any, b: any)=>{
      return new Date(a.from).getTime() - new Date(b.from).getTime();
    });
  }

  public static getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo: any){
    var diagnosis = [];
    var therapy = [];

    for (var diagnosisIndex in diagnosisInfo) {
      diagnosis.push(diagnosisInfo[diagnosisIndex]);
      for (var therapyIndex in diagnosisInfo[diagnosisIndex].data) {
        therapy.push(diagnosisInfo[diagnosisIndex].data[therapyIndex]);
      }
    }

    return {
      diagnosis: diagnosis,
      therapy: therapy
    }
  }

  public static isTherapyFinished(diagnosisInfo: any) {
    if (this.hasTherapy(diagnosisInfo)) {
      if (this.getActiveTherapiesAndSort(diagnosisInfo).length != 0) {
        let therapiesLastDay = this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).therapy
        .filter((t: any) => t.from && t.to && t.totalExercises && this.moment.utc(t.to).isSame(this.moment.utc(), 'day'));

        return therapiesLastDay.length > 0;
      }
      return true;
    }
    return false;
  }

  public static getJointsOfDiagnoses(diagnosisInfo: any){
    let diagnoses = this.getTherapiesAndDiagnosesFromDiagnosisInfo(diagnosisInfo).diagnosis;
    return Array.from(new Set(diagnoses.map((d: any)=> d.joint)));
  }

  // TODO: Añadir tests
  // TODO: documentar
  private static getDatePercentage(from: any, to: any){
    if(!from || !to) return 100;

    let start = typeof from == "string" ? this.moment.utc(from) : from;
    let end = typeof to == "string" ? this.moment.utc(to): to;
    let today = this.moment.utc();

    end.add(1, 'days');

    let totalDays = end.diff(start, "days");
    let currentDays = Math.max(today.diff(start, "days"), 0);
    let percent =  Math.floor((currentDays / totalDays) * 100);

    return Math.max(0, Math.min(percent, 100));
  }
}