import { ControllerRC } from "../controller-rc.interface";
import { ChromecastCommon } from "./chromecast-common";

declare var cast: any;
export class ChromecastSender extends ChromecastCommon implements ControllerRC {

  private initSender: boolean = false;

  constructor(nameSpace: string) {
    super(nameSpace);
  }

  init(onReceive: Function): Promise<boolean> {
    this.onReceive = onReceive;
    return new Promise<boolean>((resolve, reject) => {
      // no se carga aquí el script de sender porqué se necesita antes (para mostrar el icono y conectar al chromecast)
      this.manager = cast.framework.CastContext.getInstance().getCurrentSession();
      if (!this.manager) reject("Chromecast not initialised");
      if (!this.initSender) {
        // listener para recibir los mensajes del receiver
        this.manager.addMessageListener(this.nameSpace, (nameSpace: any, customEvent: any) => {
          let eventParsed = JSON.parse(customEvent);
          if (eventParsed && eventParsed.type == "message") this.receive(eventParsed.text);
        });
        this.send(this.getPingMessage());
        this.initSender = true;
      }
      resolve(true);
    });
  }

  close(): void {
    if (this.manager) this.manager.endSession(true);
  }

  send(data: any): void {
    if (this.manager) {
      let chromeData = this.generateJsonChromecast(data);
      this.manager.sendMessage(this.nameSpace, chromeData);
    }
  }

  receive(data: any): void {
    if (this.onReceive && data && !data.ping) this.onReceive(data);
  }

}