export abstract class SharedFormBallsComponent {

  numBalls: number;
  selectedNumber: number = null;
  startNumber: number = 1;
  showNumbers: boolean = true;
  size: string;
  disabled: boolean = false;
  numbers: any[];

  constructor() {}

  abstract emitNumberChange(selectedNumber: number): void;
  abstract getButtonTheme(): string;

  init() {
    this.numbers = Array.from(Array(this.numBalls), (x,i) => i = i + this.startNumber);
  }

  selectBall(number:number): void {
    if (this.disabled) return;
    if (this.showNumbers && this.selectedNumber != number) {
      this.selectedNumber = number;
      this.emitNumberChange(this.selectedNumber);
      return;
    }
    if (!this.showNumbers && this.selectedNumber != 0) {
      this.selectedNumber = 0;
    } else {
      this.selectedNumber = null;
    }
    this.emitNumberChange(this.selectedNumber);
  }

  isSelected(number: number): boolean {
    return (this.selectedNumber != null && (this.selectedNumber + this.startNumber) >= number) || 
      (!this.showNumbers && this.selectedNumber == 0)
  }
  
}