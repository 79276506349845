import { html } from '../shared.js';
import CalendarUtils from './calendar-utils.js';

export class RehubCalendarWebComponent extends HTMLElement  {

  constructor() {
    super();
    this._events = {};
    this.selectedMonth = 0;
    this.selectedDay = null;
    this.rendered = false;
    this.attachShadow({ mode: "open" });
  }

  capitalize(text){
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  upperCase(text){
    return text.toUpperCase();
  }

  onNextMonthPressed(){
    this.selectedMonth++;
    this.onMonthChanged();
    this.renderDays();
  }

  onPreviousMonthPressed(){
    this.selectedMonth--;
    this.onMonthChanged();
    this.renderDays();
  }

  onDayPressed(day){
    if(this.selectedDay == day) return;

    this.selectedDay = day;

    this.dispatchEvent(new CustomEvent("daySelected", {
      composed: true,
      detail: {
        day: day
      }
    }));

    let dayElements = this.shadowRoot.querySelectorAll(".day");

    dayElements.forEach((dayElement)=>{
      this.renderSelectedDay(dayElement);
    });
  }

  onMonthChanged(){
    let currentMonth = this.today.clone().add(this.selectedMonth, 'month');

    this.dispatchEvent(new CustomEvent("monthSelected", {
      composed: true,
      detail: {
        day: currentMonth.format('YYYY-MM')
      }
    }));
  }

  set events(value){
    this._events = value;
    let dayElements = this.shadowRoot.querySelectorAll(".day");

    dayElements.forEach((dayElement)=>{
      this.renderEvents(dayElement);
    });
  }

  get events(){
    return this._events;
  }

  set moment(value){
    this._moment = value;
    this.render();
    if(this._selected) this.initSelectedDay();
  }

  get moment(){
    return this._moment;
  }

  set selected(value){
    this._selected = value;
    if(this.moment) this.initSelectedDay();
  }

  get selected(){
    return this.selectedDay;
  }

  initSelectedDay(){
    if(!this.today) this.today = CalendarUtils.getToday(this.moment);
    this.selectedDay = this.moment(this._selected).startOf("day");
    this.selectedMonth = CalendarUtils.getMonthDiff(this.selectedDay, this.today);
    this.renderDays();
  }

  getDayEventColor(day){
    let event = Object.keys(this._events).find((eventDay)=>{
      return CalendarUtils.isSameDay(day, this.moment(eventDay));
    });

    return event ? this._events[event].color : "transparent";
  }

  render() {
    this.today = CalendarUtils.getToday(this.moment);

    if (!this.selectedDay) {
      this.selectedDay = CalendarUtils.getToday(this.moment).toISOString();
    }

    this.selectedDay = this.moment(this.selectedDay).startOf("day");

    this.shadowRoot.innerHTML = html`
      <style>
        :host{
          display: block;
          user-select: none;
          color: #828282;
        }

        .next,.previous{
          cursor: pointer;
        }

        .header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 15%;
          color: #212529;
          padding-left: 5px;
          padding-right: 5px;
        }

        .header h1{
          font-size: 1.5em;
          font-weight: bold;
        }

        .dayNames{
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          /* padding-bottom: 10px; */
          border-bottom: 1px solid #828282;
        }

        .dayName{
          text-align: center;
          width: 100%;
          font-weight: bold;
        }

        .content{
          height: 75%;
          width: 100%;
          display: inline-grid;
          grid-template-columns: repeat(7, auto);
          grid-auto-rows: 1fr;
          border-left: 1px solid #828282;
        }

        .day{
          display: flex;
          font-weight: bold;
          flex-direction: column;
          align-items: center;
          text-align: center;
          /* padding: 10px 0 30px 0; */
          cursor: pointer;
          border-bottom: 1px solid #828282;
          border-right: 1px solid #828282;
          justify-content: space-evenly;
        }

        .day > span {
          width: 20px;
          height: 20px;
          padding-top: 3px;
          padding-bottom: 7px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 20px;
        }

        .day > rehub-icon {
          display: flex;
          color: var(--color-primary);
        }

        .day.lastMonth{
          font-weight: initial;
        }

        /* .day.selected{
          border-top: 3px solid var(--color-primary);
        } */

        .day.selected > span{
          background-color: var(--color-primary);
          color: white;
        }

        .day.current:not(.selected) > span{
          color: var(--color-primary);
        }
      </style>

      <div class="header">
        <rehub-icon name="arrow" class="previous" rotate="-90" size="20"></rehub-icon>
        <h1></h1>
        <rehub-icon name="arrow" class="next" rotate="90" size="20"></rehub-icon>
      </div>

      <div class="dayNames"></div>

      <div class="content"></div>
    `;

    let daysNames = CalendarUtils.getDaysNames(this.moment);
    let namesElement = this.shadowRoot.querySelector(".dayNames");

    daysNames.forEach((name)=>{
      namesElement.innerHTML += html`
      <div class="dayName">
        ${this.upperCase(name).replace(".", "")}
      </div>`;
    });

    let previousElement = this.shadowRoot.querySelector(".previous");
    let nextElement = this.shadowRoot.querySelector(".next");

    previousElement.onclick = this.onPreviousMonthPressed.bind(this);
    nextElement.onclick = this.onNextMonthPressed.bind(this);

    this.rendered = true;

    this.renderDays();
  }

  renderDays(){
    if(!this.rendered) return;

    let currentMonth = this.today.clone().add(this.selectedMonth, 'month');
    let lastMonth = this.today.clone().add(this.selectedMonth - 1, 'month');

    let daysInMonth = CalendarUtils.getDaysInMonth(currentMonth);
    let daysInLastMonth = CalendarUtils.getDaysInMonth(lastMonth);
    let firstDayOfMonth = CalendarUtils.getFirstDayOfMonth(currentMonth);
    let firstDayOfMonthOffset = CalendarUtils.getFirstDayOfMonthOffset(currentMonth);

    let contentElement = this.shadowRoot.querySelector(".content");

    contentElement.innerHTML = "";

    for(let i = 0; i < firstDayOfMonthOffset; i++){
      let day = firstDayOfMonth.clone().subtract(firstDayOfMonthOffset - i, 'days');

      contentElement.innerHTML += html`
      <div class="day lastMonth" data-day="${day.toISOString()}">
        <span>${daysInLastMonth - firstDayOfMonthOffset + i + 1}</span>
        <rehub-icon name="shape_circle" size="10" style="color: transparent;"></rehub-icon>
      </div>`;
    }

    for(let i = 0; i < daysInMonth; i++){
      let day = firstDayOfMonth.clone().add(i, 'days');

      contentElement.innerHTML += html`
      <div class="day" data-day="${day.toISOString()}">
        <span>${i + 1}</span>
        <rehub-icon name="shape_circle" size="10" style="color: transparent;"></rehub-icon>
      </div>`;
    }

    let dayElements = this.shadowRoot.querySelectorAll(".day");

    dayElements.forEach((dayElement)=>{
      let day = dayElement.dataset.day;
      let isLastMonth = dayElement.classList.contains("lastMonth");
      if(day && !isLastMonth) dayElement.onclick = this.onDayPressed.bind(this, day);

      this.renderCurrentDay(dayElement);
      this.renderSelectedDay(dayElement);
      this.renderEvents(dayElement);
    });

    this.renderMonth(currentMonth);
  }

  renderCurrentDay(dayElement){
    dayElement.classList.remove("current");
    let day = dayElement.dataset.day;
    let current = day && CalendarUtils.isToday(this.moment, this.moment(day));
    if(current) dayElement.classList.add("current");
  }

  renderSelectedDay(dayElement){
    dayElement.classList.remove("selected");
    let day = dayElement.dataset.day;
    let selected = day && CalendarUtils.isSameDay(this.moment(day), this.selectedDay);
    if(selected) dayElement.classList.add("selected");
  }

  renderMonth(currentMonth){
    let monthElement = this.shadowRoot.querySelector(".header h1");
    monthElement.innerHTML = this.capitalize(currentMonth.format('MMMM YYYY'));
  }

  renderEvents(dayElement){
    let day = this.moment(dayElement.dataset.day);
    let color = this.getDayEventColor(day);
    let elements = dayElement.getElementsByTagName("rehub-icon");
    if(elements.length > 0) elements[0].style.color = color;
  }

};