const MAX_FILE_SIZE = 1048576 * 30; // 30MB

export class FileUtils{
  public static readFileEvent(event: any, callback: any){
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        let fileToUpload = reader.result;
        let name = file.name.split(".")[0];
        let extension = "." + file.name.split(".")[1];
        let size = file.size;
        callback(fileToUpload, name, extension, size);
      };
    }
  }

  public static formatSize(bytes: number, decimals: number = 2){
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  public static isValidSize(size: number){
    return size < MAX_FILE_SIZE;
  }

  public static isValidName(name: string){
    return name.match(/^[a-zA-Z0-9 _]{1,50}$/);
  }

  public static isValidFile(event: any, fileType: string){
    let size = fileType == "video" ? 30 : 5;
    let error;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if(!this.checkValidFileType(file, fileType)){
        error = "general_file_error_type";
      }else if(!this.checkValidFileSize(file, size)){
        error = "general_file_error_size";
      }
    }
    return error;
  }

  public static checkValidFileSize(file: any, expectedSize: any){
    let fileSize = file.size / 1024 / 1024; // in MB
    return fileSize <= expectedSize;
  }

  public static checkValidFileType(file: any, expectedType: string){
    let type = file.type;
    return type.startsWith(expectedType);
  }

  public static getAcceptInput(fileType: string){
    if(fileType == "video") return "video/mp4";
    else if(fileType == "image") return "image/*";
    else return "invalid";
  }

  public static createAndDownloadJsonFile(fileName: string, content: string){
    let element = document.createElement('a');
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}