import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { DOMService } from '@app/@core/service/dom.service';

@Component({
  selector: 'rehub-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @HostBinding('class') toastClass = '';

  @Input() position: string;
  @Input() theme: string;
  @Input() title: string;
  @Input() text: string;
  @Input() isCloseButton: boolean;
  reference: any;

  constructor(
    private domService: DOMService
  ) {}

  ngOnInit() {
    if (!this.isCloseButton) {
      setTimeout(() => this.remove(), 2000);
    }
  }

  remove() {
    this.domService.remove('Toast');
  }
}
