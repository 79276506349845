import { SharedUtils } from "../../utils/utils";

export const ChromecastScript: any = { 
  receiverRC: '//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js',
  senderRC: 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
};
/**
 * Clase común de chromecast donde estan los métodos compartidos para el sender y el receiver
 * 
 * @public
 */
export class ChromecastCommon {

  protected onReceive: any;
  protected manager: any;
  protected nameSpace: string;

  constructor(nameSpace: string) {
    this.nameSpace = nameSpace;
  }

  /**
   * Cargar uno de los scripts necesarios para utilizar el chromecast
   * 
   * @param name - Nombre del atributo para coger el script correcto de chromecast del objecto ChromecastScript
   * @returns Una promesa para saber que ya esta cargado el script
   */
  loadScript(name: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // se mira si ya esta cargado el script, en caso contrario lo carga
      if (SharedUtils.isScriptLoaded(ChromecastScript[name])) {
        resolve();
      } else return SharedUtils.loadScript(ChromecastScript[name]).then(() => {
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  protected generateJsonChromecast(message: any) {
    return {
      type: "message",
      text: message
    };
  }
  
  protected getPingMessage() {
    return {ping: true};
  }

}