<div class="row py-3">
  <div class="col">
    <div class="container px-1 mb-2">
      <div class="row font-weight-bold">
        <!-- <div class="col px-0 text-center">{{min}}</div> -->
        <div class="col">
          <div class="position-relative">
            <input type="range" class="form-control-range slider mt-2 {{ getButtonTheme() }}" id="formControlRange"
              min="{{ min }}" max="{{ max }}" step="{{ step }}" (change)="changeRange($event.srcElement.value)" [value]="number"
              [ngClass]="{'available-slider': !disabled}" [attr.disabled]="disabled ? '' : null" list="values">
            <!-- <datalist id="values" class="data-list d-flex flex-wrap">
              <option *ngFor="let value of values" [value]="value" [label]="value" class="option-list flex-fill"></option>
            </datalist> -->
            <div id="tickmarks">
              <p *ngFor="let value of values" [ngClass]="{'font-weight-bold': number == value}">{{ value }}</p>
            </div>
            <!-- <div class="position-absolute tag">{{ number }}</div> -->
          </div>
        </div>
        <!-- <div class="col px-0 text-center">{{max}}</div> -->
      </div>
    </div>
  </div>
</div>