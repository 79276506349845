export type AspectRatioParameters = {ratio: number, newWidth: number, newHeight: number, offsetX: number, offsetY: number};

export class CanvasUtils{
  public static drawImage(ctx: CanvasRenderingContext2D, image: any, sx: number, sy: number, sw: number, sh: number, dx: number, dy: number, dw: number, dh: number){
    if(ctx == null) return;

    ctx.drawImage(image, sx, sy, sw, sh, dx, dy, dw, dh);
  }

  public static drawImageSimple(ctx: CanvasRenderingContext2D, image: any, x: number, y: number){
    if(ctx == null) return;

    ctx.drawImage(image, x, y);
  }

  public static drawRect(ctx: CanvasRenderingContext2D, x0: number, y0: number, x1: number, y1: number, color: string, lineWidth: number) {
    if(ctx == null) return;

    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.rect(x0, y0, x1, y1);
    ctx.stroke();
  }

  public static drawText(ctx: CanvasRenderingContext2D, x: number, y: number, text: string, color: string, size: number) {
    if(ctx == null) return;

    ctx.fillStyle = color;
    ctx.font = size + "px Arial";
    ctx.fillText(text, x, y);
  }

  public static drawLine(ctx: CanvasRenderingContext2D, x0: number, y0: number, x1: number, y1: number, lineWidth: number, color: string) {
    if(ctx == null) return;

    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(x0, y0);
    ctx.lineTo(x1, y1);
    ctx.stroke();
  }

  public static fillCircle(ctx: CanvasRenderingContext2D, x: number, y: number, r: number, color: string) {
    if(ctx == null) return;

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.fill();
  }

  public static fillTriangle(ctx: CanvasRenderingContext2D, x: number, y: number, r: number, size: number, color: string){
    if(ctx == null) return;

    let half = size / 2;

    ctx.save();

    ctx.fillStyle = color;
    ctx.translate(x, y);
    ctx.rotate(r);
    ctx.beginPath();
    ctx.moveTo(-half, half);
    ctx.lineTo(0, -half);
    ctx.lineTo(half, half);
    ctx.closePath();
    ctx.fill();

    ctx.restore();
  }

  public static drawCircle(ctx: CanvasRenderingContext2D, x: number, y: number, r: number, color: string) {
    if(ctx == null) return;

    ctx.strokeStyle = color;
    ctx.fillStyle = "";
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.stroke();
  }

  /**
   * Crea un canvas escalado con ratio 1:1 a partir de un elemento de video con el frame actual dibujado.
   * @param video Elemento de video
   * @param size Elemento de video
   * @param frameRotation Rotación del frame en grados
   * @returns
  */
  public static getCanvasFromVideoScaled(video: HTMLVideoElement, size: number, frameRotation: number): HTMLCanvasElement{
    let width = video.videoWidth;
    let height = video.videoHeight;

    let aspectRatioParameters = this.getAspectRatioParameters(width, height, size);

    let cvs: HTMLCanvasElement = document.createElement("canvas");
    let ctx: CanvasRenderingContext2D | null = cvs.getContext("2d");

    cvs.width = size;
    cvs.height = size;

    //TODO: preprocesado de video???

    if(ctx){
      ctx.fillStyle = "black";

      if(frameRotation == -90){ // Counter Clockwise
        ctx.translate(cvs.width, 0);
        ctx.rotate((90 * Math.PI) / 180);
      } else if(frameRotation == 90){ // Clockwise
        ctx.translate(0, cvs.height);
        ctx.rotate((-90 * Math.PI) / 180);
      }

      ctx.fillRect(0, 0, cvs.width, cvs.height);
      ctx.drawImage(video, aspectRatioParameters.offsetX, aspectRatioParameters.offsetY, aspectRatioParameters.newWidth, aspectRatioParameters.newHeight);
    }

    return cvs;
  }

  /**
   * Crea un canvas a partir de un elemento de video con el tamaño especificado.
   * @param video Elemento de video
   * @returns
  */
  public static getCanvasResized2Model(video: HTMLVideoElement, size: number): HTMLCanvasElement{
    let cvs: HTMLCanvasElement = document.createElement("canvas");
    let ctx: CanvasRenderingContext2D | null = cvs.getContext("2d");

    let aspectRatioParameters = this.getAspectRatioParameters(video.videoWidth, video.videoHeight, size);

    cvs.width = size;
    cvs.height = size - (aspectRatioParameters.offsetY * 2);

    if(ctx) ctx.drawImage(video, 0, 0, aspectRatioParameters.newWidth, aspectRatioParameters.newHeight);
    return cvs;
  }

  /**
   * Calcula los parámetros necesarios para poder transformar de unas coordenadas con ratio 1:1 a coordenadas normales o al revés.
   *
   * @param destinationWidth Ancho de destino en píxeles
   * @param destinationHeight Alto de destino en píxeles
   * @param originSize Ancho y alto de origen en píxeles
   * @returns Parámetros de relación de aspecto
   */
  public static getAspectRatioParameters(destinationWidth: number, destinationHeight: number, originSize: number): AspectRatioParameters{
    let ratio = destinationWidth / destinationHeight;

    let newWidth = ratio > 1 ? originSize : originSize * ratio;
    let newHeight = ratio > 1 ? originSize / ratio : originSize;

    let offsetX = (originSize - newWidth) / 2;
    let offsetY = (originSize - newHeight) / 2;

    return {
      ratio,
      newWidth,
      newHeight,
      offsetX,
      offsetY
    }
  }
}