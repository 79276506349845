<div class="modal-header">
  <h4 class="modal-title">{{ 'modal_title_confirm_use_sensor' | translate }}</h4>
  <a class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePressed()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="border-0 pt-0 d-flex flex-column flex-md-row p-3 align-items-center justify-content-center" style="gap: 20px;">

  <button id="buttonConfirmSensorUse" type="button" class="align-items-center btn btn-lg btn-primary d-flex justify-content-center text-nowrap" style="min-width: 210px;" (click)="onUseSensorPressed(true)">
    <rehub-icon name="sensor_main" class="mb-1"></rehub-icon>
    <span style="min-width: 40px; z-index: 1;">{{"general_yes" | translate}}</span>
  </button>

  <button type="button" class="align-items-center btn btn-lg btn-danger d-flex justify-content-center text-nowrap" style="min-width: 210px" (click)="onUseSensorPressed(false)">
    <span style="min-width: 125px;">{{"general_no" | translate}}</span>
  </button>

</div>