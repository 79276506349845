import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service/modal.service';

import { environment } from '@env/environment';

import { I18nService } from '@app/i18n';
import { CredentialsService } from '@app/@core/auth';

import { SharedUtils } from '@rehub-shared/utils/utils';
import { ChatAPI } from '@rehub-shared/api';

@Component({
  selector: 'rehub-skip-install-realtime-modal',
  templateUrl: './confirm-skip-install-realtime-modal.component.html',
  styleUrls: ['./confirm-skip-install-realtime-modal.component.scss']
})
export class ConfirmSkipInstallRealtimeModalComponent implements OnInit {

  data: any;

  bodyTranslation1: string;
  bodyTranslation2: string;
  bodyTranslation3: string;
  supportUrl: string;
  skipInstall: boolean = false;
  step = 0;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.bodyTranslation1 = this.i18nService.translate('modal_realtime_confirm_skip_install_realtime_body_1');
    this.bodyTranslation2 = this.i18nService.translate('modal_realtime_confirm_skip_install_realtime_body_2');
    this.supportUrl = this.getSupportUrl();
  }

  close(result: string = null){
    if(result) this.modalService.callback(result);
    else this.modalService.close();
  }

  onAcceptPressed(){
    if(this.skipInstall && this.step == 0){
      this.step++;
    }else if(this.step == 1){
      let message = this.i18nService.translate('chat_automatic_message_realtime');
      ChatAPI.postMessageToAllGroups(message);
      this.data.measurementProgramService.setSkipRealtimeInstall();
      this.close();
    }else{
      this.close();
    }
  }

  onBackPressed(){
    if(this.skipInstall && this.step != 0){
      this.step--;
      this.skipInstall = false;
    }else{
      this.close("install");
    }
  }

  onCheckboxPressed(value: any){
    this.skipInstall = value == false;
  }

  getSupportUrl(){
    return SharedUtils.getSupportUrl({
      client: environment.client_login,
      supportUrl: environment.login_url + "/contact-form",
      patient: this.credentialsService.getInfo(),
      lang: this.i18nService.getLanguageCode(),
      reasonGroup: SharedUtils.REASON_GROUP_REALTIME,
      reason: this.data.measurementProgramService.isRealtimeInstalled() ? SharedUtils.REASON_REALTIME_NO_RESET : SharedUtils.REASON_REALTIME_NO_INSTALL
    });
  }

}
