<button
  type="button"
  aria-haspopup="true"
  aria-expanded="false"
  data-toggle="dropdown"
  class="dropdown-toggle btn-wide btn btn-primary"
>
  {{ selected.text }}
</button>

<div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-right dropdown-menu">
  <ng-container *ngFor="let item of items">
    <button
      *ngIf="item.text && !item.title"
      type="button"
      tabindex="0"
      class="dropdown-item btn-lg"
      (click)="selectOption($event, item)"
    >
      {{ item.text }}
    </button>
    <div *ngIf="item.separator" tabindex="-1" class="dropdown-divider"></div>
    <h6 *ngIf="item.title" tabindex="-1" class="dropdown-header">{{ item.text }}</h6>
  </ng-container>
</div>
