export default class CalendarUtils{
  static getDaysNames(moment) {
    let days = moment.weekdaysShort(true);
    return days;
  }

  static getNow(moment){
    return moment();
  }

  static getToday(moment){
    return moment().startOf('day');
  }

  static isToday(moment, date){
    return this.isSameDay(moment(), date);
  }

  static isSameDay(a, b){
    return a.isSame(b, 'day');
  }

  static getDaysInMonth(date){
    return date.endOf("Month").date();
  }

  static getFirstDayOfMonth(date){
    return date.startOf("Month");
  }

  static getFirstDayOfMonthOffset(date){
    return date.startOf("Month").weekday();
  }

  static getMonthDiff(a, b){
    return a.clone().startOf("month").diff(b.clone().startOf("month"), "months");
  }

}