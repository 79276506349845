import { SharedUtils } from "../utils";

type DeployInfo = {
  version: string;
  external_version: string;
  branch_name: string;
  commit_id: string;
  commit_date: string;
  commit_message: string;
}

export class SharedDeployInfoService{

  environment: any;

  // TODO: crear un objeto con estos campos
  version: string;
  external_version: string;
  branch_name: string;
  commit_id: string;
  commit_date: string;
  commit_message: string;

  protected userType: string;

  constructor(environment: any, userType: string){
    (window as any).deployInfo = this;

    this.userType = userType;
    this.environment = environment;
    this.version = this.environment.version;
    this.external_version = this.environment.version;
  }

  set(deployInfo: DeployInfo){
    if(deployInfo == null || Object.keys(deployInfo).length == 0) return;

    this.version = deployInfo.version;
    this.external_version = deployInfo.external_version;
    this.branch_name = deployInfo.branch_name;
    this.commit_id = deployInfo.commit_id;
    this.commit_date = deployInfo.commit_date;
    this.commit_message = deployInfo.commit_message;
  }

  getURLCacheParam(){
    // En local se usará la versión del environment y en los demás entornos el commit_id
    // Se le añade una _p si se pide desde paciente y _u desde profesional para evitar problemas de cache
    return (this.commit_id || this.version ) + "_" + this.userType;
  }

  // TODO: cambiar, que solo se use external version y cambiar el nombre a version
  getVersion(){
    return SharedUtils.isStage(this.environment.stage, ["test"]) ? this.version : this.external_version;
  }

  getDate(){
    return this.commit_date || new Date().toISOString();
  }

}