import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedFormBallsComponent } from '@rehub-shared/component/form/answerTypes/balls/form-balls.component';

@Component({
  selector: 'rehub-form-balls',
  templateUrl: '../../../../../../../shared/src/component/form/answerTypes/balls/form-balls.component.html',
  styleUrls: ['../../../../../../../shared/src/component/form/answerTypes/balls/form-balls.component.scss']
})
export class FormBallsComponent extends SharedFormBallsComponent implements OnInit {

  @Input() numBalls: number;
  @Input() showNumbers: boolean = true;
  @Input() selectedNumber: number;
  @Input() size: string;
  @Input() startNumber: number = 1;
  @Output() numberChange = new EventEmitter<number>();

  ngOnInit() {
    this.init();
  }

  emitNumberChange(selectedNumber: number) {
    this.numberChange.emit(selectedNumber);
  }

  getButtonTheme() {
    return "patient";
  }

}