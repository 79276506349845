import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'rehub-new-version-video-modal',
  templateUrl: './new-version-video-modal.component.html',
  styleUrls: ['./new-version-video-modal.component.scss']
})
export class VideoVersionVersionModalComponent implements OnInit {
  lang: string;
  url: string;


  constructor(
    private modalService: ModalService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {
    this.lang = this.i18nService.getLanguage();
    this.url = `https://web-resources.dycare-services.com/rehub-assets/video-tutorials/${this.lang}/PAT_Update.mp4`;
  }

  close() {
    this.modalService.close();
  }

  confirm() {
    this.modalService.close(true);
  }
}