import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CredentialsService } from '@app/@core/auth';
import * as moment from 'moment';
import { I18nService } from '@app/i18n';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { RecordAPI } from '@rehub-shared/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedValidators } from "@rehub-shared/utils/validators";
import { ModalService } from '@app/@core/service';
import { PulsoWarningModalComponent } from '@app/@shared/modal/pulsowarning-modal/pulsowarning-modal.component';
import { ModalComponent } from '@app/@shared/modal/modal.component';
import { InfoModalComponent } from '@app/@shared/modal/info-modal/info-modal.component';


@Component({
  selector: 'rehub-pulseoximeter-measurement',
  templateUrl: './pulseoximetermeasurement.component.html',
  styleUrls: ['./pulseoximetermeasurement.component.scss'],
  host: { class: 'rounded shadow bg-white p-2 pb-3' },
})
export class PulseOximeterMeasurementComponent implements OnInit {

  @Output() sendPulseOximeterMeasurement = new EventEmitter();
  @Input() pulseOximeterMeasures:any;


  constructor(
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    public formBuilder: FormBuilder,
    private modalService: ModalService,

  ) {}

  paramsForm: FormGroup;

  ngOnInit() {
    let paramsFormData = {
      spo2: [null, [Validators.min(0),Validators.max(200), SharedValidators.integerValidator]],
      d:[null, [Validators.min(0),Validators.max(10), SharedValidators.integerValidator]],
      ppm:[null, [Validators.min(0),Validators.max(360), SharedValidators.integerValidator]],
    }
    this.paramsForm = this.formBuilder.group(paramsFormData);
    console.log("nana",this.paramsForm);
  }
  isHeightScreenLittle() {
    return SharedUtils.getScreenHeight() < 620;
  }

  isWarningValue(spo2: number | null, d: number | null, ppm: number | null): boolean {
    if (spo2 !== null && (spo2 < 90 || spo2 > 100)) {
        return true;
    }
    if (d !== null && d >= 4) {
        return true;
    }
    if (ppm !== null && (ppm > 150 || ppm < 55)) {
        return true;
    }
    return false;
  }

  disabledButton(){
    if((this.paramsForm.get('ppm').value == null && this.pulseOximeterMeasures[0] == true) || this.paramsForm.controls.ppm.invalid ) return true;
    else if ((this.paramsForm.get('d').value == null && this.pulseOximeterMeasures[1] == true)|| this.paramsForm.controls.d.invalid) return true;
    else if ((this.paramsForm.get('spo2').value == null && this.pulseOximeterMeasures[2] == true) || this.paramsForm.controls.spo2.invalid) return true;
    else return false;
  }

  onIndicatorPressed(body: string){
    let data = {body: body, width: '550px'};
    this.modalService.show(ModalComponent, InfoModalComponent, data, () => {
      this.modalService.close(false, false);
    });
  }



  sendPulseOximeter() {
    let eventData = {
      paramsForm: this.paramsForm,
      cancel :false
    }
    if (this.isWarningValue(this.paramsForm.get('spo2').value, this.paramsForm.get('d').value, this.paramsForm.get('ppm').value)) {
       eventData = {
        ...eventData,
        cancel: true
      }
      let data = {title: "login_logout_confirm"};
      this.modalService.show(ModalComponent, PulsoWarningModalComponent, data, (result: any) => {
        this.modalService.close(false, false);
        this.sendPulseOximeterMeasurement.emit(eventData);
      });

    }else this.sendPulseOximeterMeasurement.emit(eventData);
  }

}
