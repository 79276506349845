import { API } from "./api";

export class StripeAPI extends API {

  static createTrialCustomerSubscription(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/trial/customer/subscription/create`;
    return this.postWithoutAuth(url, data);
  }

  static getProducts(): Promise<any>{
    const url = `${this.getBaseUrl()}/stripe/website/products`;
    return this.getWithoutAuth(url, {});
  }

  static getBills(rehubId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/stripe/list-invoices`;
    return this.getWithoutAuth(url, {rehubId: rehubId});
  }

  static getCustomerInfo(rehubId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/customer`;
    return this.getWithoutAuth(url, {rehubId: rehubId});
  }

  static getCustomerPortal(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/stripe/customer-portal`;
    return this.postWithoutAuth(url, data);
  }

  static getCustomerSubscriptions(rehubId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/stripe/customer-subscriptions`;
    return this.getWithoutAuth(url, {rehubId: rehubId});
  }

}