import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from '@app/utils';
import { ExerciseUtils } from '@rehub-shared/utils';
import { SharedUtils } from '@rehub-shared/utils/utils';

import * as moment from 'moment';

@Component({
  selector: 'rehub-exercise-info',
  templateUrl: './exercise-info.component.html',
  styleUrls: ['./exercise-info.component.scss']
})
export class ExerciseInfoComponent implements OnInit {

  Utils = Utils;
  SharedUtils = SharedUtils;

  @Input() exercise: any;
  @Input() protocol: any;
  @Input() hasSensor: boolean = false;

  duration: any;
  isMobile: boolean = false;

  constructor() {}

  ngOnInit() {
    this.isMobile = Utils.isMobile();
    let seconds = ExerciseUtils.getRemainingSecondsFromExercise(this.exercise);
    this.duration = seconds == 0 ? "-" : moment.utc(moment.duration(seconds, "s").asMilliseconds()).format("mm:ss");
  }

  hasIconsToShow(){

    return (this.exercise.tools && this.exercise.tools.length > 0) || this.exercise.sensor || this.protocol.cameraEnabled;
  }

}