import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';

@Component({
  selector: 'rehub-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  data: any;
  title: any;
  body:any;

  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit() {
  }

  close() {
    this.modalService.close();
  }

  confirm() {
    this.modalService.close(true);
  }
}