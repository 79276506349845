import { API } from "./api";

export class UserAPI extends API {

  static getNotificationsProfessional(): Promise<any> {
    const url = `${this.getBaseUrl()}/user/me/notifications`;
    return this.get(url, {});
  }

  static updateLoginFormResponse(data: any): Promise<any> {
    const url = `${this.getBaseUrl()}/user/loginFormResponse/update`;
    return this.post(url, data);
  }

  static getPatientAssignations(): Promise<any> {
    const url = `${this.getBaseUrl()}/user/me/assignations`;
    return this.get(url, {});
  }

  static updateParam(authenticationService: any, params: any): Promise<any> {
    const data = {params: params}
    const url = `${this.getBaseUrl()}/user/me/update/param`;

    //TODO! mover updateParam a authenticationService?
    return this.post(url, data).then(()=>{
      return authenticationService.getAndSaveLoginInfo();
    });
  }

  static update(data: any): Promise<any> {
    const url = `${this.getBaseUrl()}/user/me/update`;
    return this.post(url, data);
  }

  static updateSettings(dashboardSettings: any): Promise<any> {
    const url = `${this.getBaseUrl()}/user/me/update/settings`;
    var data = { dashboardSettings : dashboardSettings};
    return this.post(url, data);
  }

  static updatePassword(patientId: any): Promise<any> {
    const url = `${this.getBaseUrl()}/user/update/password/admin`;
    var data = { patientId: patientId };
    return this.post(url, data);
  }

  static getAll(): Promise<any> {
    const url = `${this.getBaseUrl()}/user/all`;
    return this.get(url, {});
  }

  static getTemp(userId: string, sortKey: string): Promise<any> {
    const url = `${this.getBaseUrl()}/user/temp/${userId}/${sortKey}`;
    return this.get(url, {});
  }

  static getLoginInfo(): Promise<any> {
    const url = `${this.getBaseUrl()}/user/login/info`;
    return this.get(url, {});
  }
}