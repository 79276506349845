import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { ButtonProgressBar } from '@rehub-shared/utils/button-progress-bar';

@Component({
  selector: 'rehub-confirm-use-sensor-modal',
  templateUrl: './confirm-use-sensor-modal.component.html'
})
export class ConfirmUseSensorModalComponent implements OnInit {

  confirmSensorUseProgressBar: ButtonProgressBar = new ButtonProgressBar();
  
  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.confirmSensorUseProgressBar.start("#buttonConfirmSensorUse", 7500, this.onUseSensorPressed.bind(this, true));
  }

  onUseSensorPressed(useSensor: boolean){
    this.confirmSensorUseProgressBar.stop();
    this.modalService.close(useSensor);
  }

  onClosePressed() {
    this.confirmSensorUseProgressBar.stop();
    this.modalService.close();
  }
}