import { LoginAPI, UserAPI } from "../api";
import { SharedUtils } from "../utils/utils";
import { SharedCredentialsService } from "./credentials.service";
import { SharedI18nService } from "./i18n.service";
import { SharedThemeService } from "./theme.service";

export type LoginInfo = {
  user?: {
    mandatory2?: string,
  }
  patient?: {
    mandatory2?: string,
  }
}
export class SharedAuthenticationService{

  constructor(
    private isDevMode: boolean,
    private moment: any,
    private environment: any,
    private credentialsService: SharedCredentialsService,
    private router: any,
    private i18nService: SharedI18nService | null,
    private themeService: SharedThemeService
  ){}

  login(code: string, redirectUrl: string | null = null) {

    return LoginAPI.login(code).then((result: any)=>{


      if(!result || result == "null" || result == null){
        this.redirectToLogin();
        return;
      }

      let decoded = SharedUtils.parseJwt(result.IdToken);

      let userType = decoded['custom:userType'];
      let sub = decoded['custom:rehubId'] || decoded['sub'];

      // TODO: esto debería estar en una función y añadir tests
      if (userType == "patient") {
        sub = "p_" + sub;
      }else if (userType == "patient2") {
        sub = sub + "_p";
      }

      if (userType == "doctor") {
        sub = "u_" + sub;
      }else if (userType == "doctor2") {
        sub = sub + "_u";
      }

      let isPatientEnvironment = this.environment.name == "patient";
      let isProfessionalEnvironment = this.environment.name == "professional";

      let isCorrectUserType = (isPatientEnvironment && userType.startsWith("patient")) || (isProfessionalEnvironment && userType.startsWith("doctor"));

      if(isCorrectUserType) {
        this.credentialsService!.setCredentials({
          // username: decoded.nickname,
          // name: decoded.name,
          // family_name: decoded.family_name,
          // email: decoded.email,
          idToken: result.IdToken,
          sub: sub,
          userType: decoded['custom:userType'],
          center: decoded['custom:center'],
          accessToken: result.AccessToken,
          refreshToken: result.RefreshToken,
          accessTokenExp: new Date(decoded.exp * 1000),
          refreshTokenExp: this.moment().add(25, 'days').toDate(),
          environment: this.environment.stage
        });

        this.resetLastAccessDate(sub);
        this.getAndSaveLoginInfo().then((loginInfo: any) => {
          let credentialsInfo = this.credentialsService!.getInfo();
          if(credentialsInfo.lang) this.i18nService!.setLanguage(credentialsInfo.lang);
          if(this.credentialsService!.isPatient()) {
            // Seleccionar tema
            let themeName = loginInfo && loginInfo.center && loginInfo.center.themeName ? loginInfo.center.themeName : "";
            let themeConfig = loginInfo && loginInfo.center && loginInfo.center.themeConfig ? loginInfo.center.themeConfig : null;
            this.themeService.setTheme(themeName,themeConfig);
          }

          let redirectTo: string | null = "/";
          let currentPageRehub = localStorage.getItem("CURRENT_PAGE_REHUB");

          if (redirectUrl) redirectTo = redirectUrl;
          else if (currentPageRehub) {
            redirectTo = currentPageRehub;
            localStorage.removeItem("CURRENT_PAGE_REHUB");
          }

          this.router.navigate([redirectTo]);
        }).catch((error: any) => {
          this.credentialsService!.clearCredentials();
          this.router.navigate(['/login',{user: 'logout'}]);
          console.error("Error getting login info:", error);
        });
      } else {
        if(isPatientEnvironment) window.location.href = this.environment.professional_url + "/login?code=" + code;
        if(isProfessionalEnvironment) window.location.href = this.environment.patient_url + "/login?code=" + code;
      }
    }).catch((error: any)=>{
      this.redirectToLogin();
    });
  }

  redirectToUpdatePassword(code: string, email: string, client_id?: string) {
    let loginClient = localStorage.getItem('loginClient');
    let loginClientFinal = client_id ? client_id : loginClient;

    let params: any = {
      client: loginClientFinal ? loginClientFinal : this.environment.client_login,
      lang: this.i18nService!.getLanguageCode()
    }

    if (code) params.code = code;
    if (email) params.email = email;

    window.location.href = SharedUtils.addQueryParamsToURL(this.environment.login_url + "/update-password", params);
  }

  redirectToLogin(email?: string, client_id?: string) {
    let isLocal = this.isDevMode;

    let loginClient = localStorage.getItem('loginClient');
    let loginClientFinal = client_id ? client_id : loginClient;

    let params: any = {
      client: loginClientFinal ? loginClientFinal : this.environment.client_login,
      lang: this.i18nService!.getLanguageCode()
    }

    // if (isLocal) params.redirect_url = location.protocol + '//' + location.host + "/login";
    if (email) params.email = email;

    window.location.href = SharedUtils.addQueryParamsToURL(this.environment.login_url + "/login", params);
  }

  logout() {
    // Customize credentials invalidation here
    this.credentialsService!.clearCredentials();
    this.router.navigate(['/login']);
  }
  manageFrontendLoginClient(login_client: string) {
    if (login_client) {
      localStorage.setItem("loginClient", login_client);
    } else {
      localStorage.removeItem("loginClient");
    }
  }

  loadPatientManifest(patientlang: string) {
    // Obligo al hijo a implementar esta función
  }

  getAndSaveLoginInfo() {
    //TODO save login info in memory instead of localstorage
    return UserAPI.getLoginInfo().then((loginInfo: any) => {
      try {
        let frontend2LoginClient = loginInfo.center ? loginInfo.center.frontendLoginClient : null;
        this.manageFrontendLoginClient(frontend2LoginClient);
        if (loginInfo.user) {
          this.credentialsService!.setUser(loginInfo.user);
        }
        if (loginInfo.patient) {
          this.credentialsService!.setPatient(loginInfo.patient);
          this.loadPatientManifest(loginInfo.patient.lang);
        }
        this.credentialsService!.appendCredentials({
          ...loginInfo,
          isPending: this.isPending(loginInfo, this.environment.conditions_of_use_version, this.environment.privacy_policy_version),
          satisfactionForm: loginInfo.user && loginInfo.user.satisfactionForm ? loginInfo.user.satisfactionForm : null,
          FIForm: loginInfo.user && loginInfo.user.FIForm ? loginInfo.user.FIForm : null
        });
      } catch (error) {
        console.error(error);
      }

      this.moment.tz.setDefault(loginInfo.user ? loginInfo.user.tz : loginInfo.patient ? loginInfo.patient.tz : this.environment.defaultTimeZone);

      return loginInfo;
    });
  }


  private getMandatoryFormatted(mandatory: string): string[]{
    if(mandatory && typeof mandatory == "string") return mandatory.split("_");
    return [];
  }

  private isPending(loginInfo: LoginInfo, conditionsOfUseVersion: string, privacyPolicyVersion: string): boolean {
    let mandatory2: string = "";

    if (loginInfo.user && loginInfo.user.mandatory2) mandatory2 = loginInfo.user.mandatory2;
    if (loginInfo.patient && loginInfo.patient.mandatory2) mandatory2 = loginInfo.patient.mandatory2;

    let mandatoryFormatted = this.getMandatoryFormatted(mandatory2);

    if (mandatoryFormatted.length == 0 || mandatoryFormatted.length == 1) return true;
    if (mandatoryFormatted.length == 2) throw new Error(`Mandatory2 is not valid: ${mandatory2}`);
    if (mandatoryFormatted[0] != conditionsOfUseVersion || mandatoryFormatted[1] != privacyPolicyVersion) return true;

    return false;
  }

  private resetLastAccessDate(sub: string) {
    let settings:any = localStorage.getItem(sub + "_settings")
    settings = settings ? JSON.parse(settings) : {};
    if (settings.lastAccessDate) delete settings.lastAccessDate;
    localStorage.setItem(sub + "_settings", JSON.stringify(settings));
  }
}