import { Component, Input } from '@angular/core';
@Component({
  selector: 'rehub-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  host: { class: "d-block" }
})
export class RehubProgressBarComponent {
  @Input() current: number;
  @Input() max: number;
  @Input() iconName: string;
  @Input() showProgress: boolean;
  getPercentage() {
    return this.current * 100 / this.max;
  }
}