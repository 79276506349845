<div class="modal-header">
  <h4 class="modal-title">{{ 'modal_realtime_updating_title' | translate }}</h4>
</div>

<div class="modal-body d-flex flex-column justify-content-center align-items-center">
  <span>{{ 'modal_realtime_updating_body' | translate }}</span>
  <rehub-spinner size="3" class="mt-3"></rehub-spinner>
</div>

<div class="border-0 pt-0 d-flex flex-row p-3 justify-content-center align-items-center">
  <button type="button" class="btn btn-primary text-nowrap mr-2" (click)="onHelpPressed()">{{"modal_realtime_disconnected_help" | translate}}</button>
  <button type="button" class="btn btn-danger text-nowrap" (click)="onCancelPressed()">{{"general_cancel" | translate}}</button>
</div>
