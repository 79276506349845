export class SharedWebViewService {

  constructor() {}

  public postMessage(name: string, message: any) {
    let msg={ [name] : message };

    if ((window as any).webViewInterface != null) {
      (window as any).webViewInterface.sendMessageToWebView(JSON.stringify(msg));
    }
    if ((window as any).webkit != null && (window as any).webkit.messageHandlers != null && (window as any).webkit.messageHandlers.webViewInterface != null){
        (window as any).webkit.messageHandlers.webViewInterface.postMessage(JSON.stringify(msg));
    }
  }
}

declare global {
  interface Window {
    chrome: any;
  }

}