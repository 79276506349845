<ng-container *ngIf="data"  >
    <div class="modal-header" >
    <rehub-icon [attr.name]="data.icon" *ngIf="data.icon" size="25" class="d-inline-block"></rehub-icon>
    <h5 class="modal-title" *ngIf="data.title">{{ data.title | translate }}</h5>
    <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <rehub-icon name="close" size="15"></rehub-icon>
    </a>
  </div>
  <div class="modal-body info-container" [style.width]="data.width ? data.width : 'auto'">
    {{ data.body | translate }}
  </div>
  <div class="modal-footer border-0 pt-0">
    <button type="button" class="btn btn-primary btn-lg w-100" (click)="confirm()">
      {{ ( data.customButton ? data.customButton : "general_accept" ) | translate}}</button>
  </div>

</ng-container>