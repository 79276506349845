import { ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

import { SharedFillContainerDirective } from '@rehub-shared/directive/fill-container.directive';

@Directive({
  selector: '[rehubFillContainer]'
})
export class FillContainerDirective extends SharedFillContainerDirective implements OnDestroy {

  resizeObserver: any;

  @Input() 
  set container(id: string){
    this.init(id);
  }

  constructor(
    el: ElementRef,
    cd: ChangeDetectorRef
  ) {
    super(el, cd);
  }

  ngOnDestroy(){
    this.destroy();
  }
}