<div style="z-index:30;" class="position-fixed {{position}}">
  <div id="toast-container" class="toast">
    <div class="toast-header bg-{{theme}}" >
      <p class="m-0 mr-auto text-dark py-2">{{ text }}</p>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="remove()" *ngIf="isCloseButton">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- <p class="toast-body m-0 small" *ngIf="text">
      {{ text }}
    </p> -->
  </div>
</div>
