
import { API } from "./api";

export class MapsAPI extends API{

  static getFromAddress(address: string): Promise<any> {
    const formattedAddress = address.replace(" ", "+");
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=AIzaSyACfXHH_V7oOBFQXLjqEMihCLffZpUYJOw`;
    return this.getWithoutAuth(url, {});
  }
  
  static getFromCoordinates(lat: number, lng: number): Promise<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyACfXHH_V7oOBFQXLjqEMihCLffZpUYJOw`;
    return this.getWithoutAuth(url, {});
  }
}