import { API } from "./api";

export type DemoItem = "USER_DASH";
export type DemoAction = "WIDGET_PATIENT_COMMON_PRATICE_TIME" | "WIDGET_PATIENT_END_OF_THERAPY_SUMMARY" | "WIDGET_PATIENT_ADHERENCE_SUMMARY" |
  "WIDGET_PATIENT_END_OF_THERAPY_SUMMARY_LIST_NOTES";

export class DemoAPI extends API {

  static populate(data: any): Promise<any> {
    const url = `${this.getBaseUrl()}/demo/data/populate`;
    return this.post(url, data);
  }

  static getInfoPopulate(): Promise<any> {
    const url = `${this.getBaseUrl()}/demo/data/populate/info`;
    return this.get(url, {});
  }

  static getDemoDataDashboard(item: DemoItem, action: DemoAction): Promise<any> {
    const url = `${this.getBaseUrl()}/demo/data/item/${item}/action/${action}`;
    return this.post(url, {});
  }

}