import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rehub-exercise-during-per-week',
  templateUrl: './exercise-during-per-week.component.html'
})
export class ExerciseDuringPerWeekComponent implements OnInit {

  @Input() duringWeek: any;

  ngOnInit(): void {
      
  }
}