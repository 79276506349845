import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { I18nModule } from '@app/i18n';
import { AuthModule } from '@core/auth';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '@app/@shared';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingOverlayComponent } from '@app/modules/exercise/shared/loading-overlay/loading-overlay.component';

@NgModule({
  imports: [CommonModule, TranslateModule, AuthModule, I18nModule, RouterModule, SharedModule, LoadingBarHttpClientModule],
  declarations: [HeaderComponent, FooterComponent, ShellComponent, LoadingOverlayComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShellModule {}
