import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';

@Component({
  selector: 'rehub-cycle-end-wait-modal',
  templateUrl: './cycle-end-wait-modal.component.html',
  styleUrls: ['./cycle-end-wait-modal.component.scss']
})
export class CycleEndWaitModalComponent implements OnInit {

  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit() {
    
  }

  onAcceptPressed() {
    this.modalService.close(true);
  }
}