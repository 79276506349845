import { html } from '../shared.js';

export class RehubGoniometerWebComponent extends HTMLElement  {

  constructor() {
    super();
    this.draggingPointIndex = null;
    this.points = [];
    this.angle = 90;
    this.mouse = {x: 0, y: 0};
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.shadowRoot.innerHTML = html`
      <style>
        :host{
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        canvas{
          width: 100%;
          height: 100%;
        }
      </style>
      <canvas id="cvs"></canvas>
    `;

    this.postRender();
  }

  onMouseMove(event){
    var rect = event.target.getBoundingClientRect();
    this.mouse.x = event.clientX - rect.left;
    this.mouse.y = event.clientY - rect.top;
  }

  onMouseDown(event){
    this.mouse.down = true;
    this.mouse.downPosition = {x: this.mouse.x, y: this.mouse.y};
  }

  onMouseUp(event){
    delete this.mouse.down;
    delete this.mouse.downPosition;

    this.draggingPointIndex = null;

    this.dispatchEvent(new CustomEvent("angleChanged", {
      composed: true,
      detail: {
        angle: this.angle
      }
    }));
  }

  postRender(){
    let host = this.shadowRoot.getRootNode().host;
    let cvs = this.shadowRoot.querySelector("#cvs");

    host.onmousemove = this.onMouseMove.bind(this);
    host.onmousedown = this.onMouseDown.bind(this);
    host.onmouseup = this.onMouseUp.bind(this);

    let resizeObserver = new ResizeObserver((entries)=>{
      cvs.width = host.clientWidth;
      cvs.height = host.clientHeight;
    });

    resizeObserver.observe(host);

    cvs.width = host.clientWidth;
    cvs.height = host.clientHeight;

    let separation = 200;

    let centerX = cvs.width / 2;
    let centerY = cvs.height / 2;

    this.points.push({x: centerX, y: centerY, r: 10});
    this.points.push({x: centerX, y: centerY - separation, r: 10});
    this.points.push({x: centerX + separation, y: centerY, r: 10});

    this.draw();
  }

  draw(){
    let cvs = this.shadowRoot.querySelector("#cvs");
    let ctx = cvs.getContext("2d");

    ctx.clearRect(0, 0, cvs.width, cvs.height);



    // this.fillCircle(ctx, this.mouse.x, this.mouse.y, 5, "red");

    // Si se ha hecho click

    if(this.mouse.down){

      // Si hay algún punto marcado como arrastrando se le actualiza la posición al mover el mouse

      if(this.draggingPointIndex != null){
        let point = this.points[this.draggingPointIndex];

        let diffX = this.mouse.x - point.x;
        let diffY = this.mouse.y - point.y;

        point.x = this.mouse.x;
        point.y = this.mouse.y;

        // Si se mueve le punto del centro se mueven los demás puntos también

        if(this.draggingPointIndex == 0){
          this.points[1].x += diffX;
          this.points[1].y += diffY;

          this.points[2].x += diffX;
          this.points[2].y += diffY;
        }

        this.angle = this.getAngleBetweenThreePoints(this.points[0], this.points[1], this.points[2]);
      }else{
        // Se obtiene el primer punto pulsado y se marca como el punto que se está arrastrando
        this.points.some((point, index)=>{
          let collides = this.getCirclesCollide(point.x, point.y, point.r, this.mouse.x, this.mouse.y, 5);
          if(collides) this.draggingPointIndex = index;
          return collides;
        });
      }
    }

    // Se pinta el segmento del circulo

    let a = this.getAngleBetweenTwoPoints(this.points[0], this.points[1]);
    let b = this.getAngleBetweenTwoPoints(this.points[0], this.points[2]);
    let c = this.getAngleBetweenThreePoints2(this.points[1], this.points[0], this.points[2]);

    let invert = c < 180;

    let arcLength = this.getShortestLength(this.points[0], this.points[1], this.points[2]);

    ctx.save();
    ctx.fillStyle = "#2296F333";
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#2296F3";
    ctx.moveTo(this.points[0].x, this.points[0].y);
    ctx.arc(this.points[0].x, this.points[0].y, arcLength, a * (Math.PI / 180), b * (Math.PI / 180), invert);
    ctx.fill();
    ctx.stroke();
    ctx.restore();


    // Se pintan los 3 puntos y las uniones

    this.drawLine(ctx, this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y, 10, "#2296F3");
    this.fillCircle(ctx, this.points[1].x, this.points[1].y, this.points[1].r + 2, "#2296F3");
    this.drawLine(ctx, this.points[0].x, this.points[0].y, this.points[2].x, this.points[2].y, 10, "white");
    this.fillCircle(ctx, this.points[2].x, this.points[2].y, this.points[2].r + 2, "white");


    this.fillCircle(ctx, this.points[0].x, this.points[0].y, this.points[0].r, "white");
    this.drawLine(ctx, this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y, 6, "white");
    this.fillCircle(ctx, this.points[1].x, this.points[1].y, this.points[1].r, "white");
    this.drawLine(ctx, this.points[0].x, this.points[0].y, this.points[2].x, this.points[2].y, 6, "#2296F3");
    this.fillCircle(ctx, this.points[2].x, this.points[2].y, this.points[2].r, "#2296F3");

    this.fillCircle(ctx, this.points[0].x, this.points[0].y, this.points[0].r + 2, "white");
    this.fillCircle(ctx, this.points[0].x, this.points[0].y, this.points[0].r - 4, "#2296F3");

    // Se pinta un texto con los grados en el centro del triangulo

    let textPosition = this.getTriangleCentroid(this.points[0], this.points[1], this.points[2]);
    ctx.font = "30px Arial";
    ctx.fillStyle = "white";
    ctx.fillText(this.angle + "°", textPosition.x - 10, textPosition.y);

    requestAnimationFrame(this.draw.bind(this));
  }

  getAngleBetweenThreePoints(p0, p1, p2){
    let p12 = Math.sqrt(Math.pow((p0.x - p1.x), 2) + Math.pow((p0.y - p1.y), 2));
    let p13 = Math.sqrt(Math.pow((p0.x - p2.x), 2) + Math.pow((p0.y - p2.y), 2));
    let p23 = Math.sqrt(Math.pow((p1.x - p2.x), 2) + Math.pow((p1.y - p2.y), 2));

    return Math.floor(Math.acos(((Math.pow(p12, 2)) + (Math.pow(p13, 2)) - (Math.pow(p23, 2))) / (2 * p12 * p13)) * 180 / Math.PI);
  }

  getAngleBetweenThreePoints2(p1, p2, p3){
    let v1 = {x: p1.x - p2.x, y: p1.y - p2.y};
    let v2 = {x: p3.x - p2.x, y: p3.y - p2.y};

    let angle = -(Math.atan2(v2.y, v2.x) - Math.atan2(v1.y, v1.x)) * 180 / Math.PI;

    if(angle < 0) angle = 360 + angle;

    return angle;
  }


  getAngleBetweenTwoPoints(p0, p1){
    return Math.atan2(p1.y - p0.y, p1.x - p0.x) * 180 / Math.PI;
  }

  getTriangleCentroid(p0, p1, p2){

    if(this.draggingPointIndex != null && this.draggingPointIndex != 0){
      this.lastDraggingPointIndex = this.draggingPointIndex;
    }

    let length = this.getShortestLength(p0, p1, p2) / 2;

    // Solución sin cambio de base

    // vector 1
    let v1_x = p1.x - p0.x;
    let v1_y = p1.y - p0.y;
    let modulo1 = Math.sqrt(Math.pow(v1_x,2) + Math.pow(v1_y,2));

    let u1_x = v1_x / modulo1;
    let u1_y = v1_y / modulo1;

    // vector 2
    let v2_x = p2.x - p0.x;
    let v2_y = p2.y - p0.y;
    let modulo2 = Math.sqrt(Math.pow(v2_x, 2) + Math.pow(v2_y, 2));

    let u2_x = v2_x / modulo2;
    let u2_y = v2_y / modulo2;

    // Suma de vectores
    let v3_x = u1_x + u2_x;
    let v3_y = u1_y + u2_y;

    let modulo3 = Math.sqrt(Math.pow(v3_x, 2) + Math.pow(v3_y, 2));

    let u3_x = v3_x / modulo3;
    let u3_y = v3_y / modulo3;

    // Mltiplicacion por length
    let v4_x = u3_x * length
    let v4_y = u3_y * length

    // Calcular punto final del vector
    let pf_x = v4_x + p0.x;
    let pf_y = v4_y + p0.y;

    return {
      x: pf_x,
      y: pf_y
    };

  }

  getShortestLength(p0, p1, p2){
    let lengthA = this.getDistance(p0.x, p0.y, p1.x, p1.y);
    let lengthB = this.getDistance(p0.x, p0.y, p2.x, p2.y);
    return Math.min(lengthA, lengthB);
  }

  getCirclesCollide(x0, y0, r0, x1, y1, r1){
    let distance = this.getDistance(x0, y0, x1, y1);

    return distance < r0 + r1;
  }

  getDistance(x0, y0, x1, y1){
    let dx = x0 - x1;
    let dy = y0 - y1;
    return Math.sqrt(dx * dx + dy * dy);
  }

  fillCircle(ctx, x, y, r, color){
    ctx.fillStyle = color;
    ctx.strokeStyle = color;

    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI, false);
    ctx.fill();
  }

  drawLine(ctx, x0, y0, x1, y1, w, color){
    ctx.strokeStyle = color;
    ctx.lineWidth = w;
    ctx.beginPath();
    ctx.moveTo(x0, y0);
    ctx.lineTo(x1, y1);
    ctx.stroke();
  }

}