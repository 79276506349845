import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

const minRate = 0.0625;
const maxRate = 16.0;

export class Utils {

  public static capitalizeText(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  public static getMonths(language: string) {
    let months: any = [];
    moment.locale(language);
    let monthsMoment = moment.months();
    monthsMoment.forEach((value: any) => months.push(this.capitalizeText(value)));
    return months;
  }

  public static getYears() {
    let years = [];
    let initialYear = new Date('2020-1-1').getFullYear();
    let finalYear = new Date().getFullYear();
    for (let year = initialYear; year <= finalYear; year++) years.push(year + '');
    return years;
  }
  public static getDuringDayNames(){
    return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  }
  public static getDuringDayNameFromIndex(index: number){
    return this.getDuringDayNames()[index];

  }
  public static getTodayDuringDayName(){
    //TODO: get the day with the user timezone instead of the browser timezone
    return this.getDuringDayNameFromIndex(new Date().getDay()-1);
  }

  public static getProtocolWithoutSide(protocol: string){
    return protocol.replace("_left", "").replace("_right", "");
  }

  public static isMobile(){
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )

    return width <= 992;
  }

  public static isLittleScreenMobile() {
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )

    return width <= 440;
  }

  public static getExerciseDurationInSeconds(exercise: any){
    return exercise.expectedRecordDuration;
  }

  public static getPlaybackRate(duration: any, iterationTime:any) {
    let newPlaybackRate = duration / iterationTime;
    return {
      value: Math.min(Math.max(newPlaybackRate, minRate), maxRate),
      valid: (newPlaybackRate > minRate && newPlaybackRate < maxRate)
    };
  }

  public static getRemainingDays(date: any){
    if(!date) return null;

    let today = moment.utc().startOf('day');
    let to = moment.utc(date).startOf('day');
    let daysDiff = to.diff(today, "days");

    // Se suma uno para que cuente el dia de fin
    return daysDiff + 1;
  }

  public static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public static formFieldsToUntouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formFieldsToUntouched(control);
      }
    });
  }

  public static getTimeZone(timeZone: string) {
    return timeZone ? timeZone : 'Europe/Madrid';
  }

  public static transitionPatientState(action: string, currentState: string, isPatient: boolean, isProfessional: boolean) {
    let newState = currentState;

    if (action == "accept") {
      if (this.isValidPatientState(["PENDING_PATIENT", "PENDING_PROFESSIONAL", "PENDING_PATIENT_PROFESSIONAL"], currentState)) {
        newState = "OK";
      } else if(this.isValidPatientState(["CANCEL_PATIENT", "CANCEL_PROFESSIONAL", "REFUSED_PATIENT", "REFUSED_PROFESSIONAL"], currentState)) {
        newState = "REMOVED";
      }
    } else if(action == "deny") {
      if (isPatient && this.isValidPatientState(["PENDING_PATIENT", "PENDING_PATIENT_PROFESSIONAL"], currentState)) {
        newState = "REFUSED_PATIENT";
      } else if (isProfessional && this.isValidPatientState(["PENDING_PROFESSIONAL", "PENDING_PATIENT_PROFESSIONAL"], currentState)) {
        newState = "REFUSED_PROFESSIONAL";
      } else if (isProfessional && this.isValidPatientState(["PENDING_PATIENT"], currentState)) {
        newState = "REMOVED";
      } else if (isPatient && this.isValidPatientState(["PENDING_PROFESSIONAL"], currentState)) {
        newState = "REMOVED";
      }
    } else if (action == "remove") {
      if (isPatient && this.isValidPatientState(["OK"], currentState)) {
        newState = "CANCEL_PATIENT";
      } else if (isProfessional && this.isValidPatientState(["OK"], currentState)) {
        newState = "CANCEL_PROFESSIONAL";
      }
    }
    return newState;
  }

  public static isValidPatientState(list: any, currentState: string){
    return list.indexOf(currentState) != -1
  }

  public static cleanQueryParams(params: any) {
    if(!params || typeof params !== 'object') return null;

    let cleanParams = {};

    Object.keys(params).forEach((key: string) => {
      if(
        params[key] != null &&
        params[key] != "" &&
        !(Array.isArray(params[key]) && params[key].length == 0)
      ) {
        cleanParams[key] = params[key];
      }
    });

    return cleanParams;
  }

  public static getUrlParameter(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  /* View in fullscreen */
  public static openFullscreen() {
    let elem: any = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  public static closeFullscreen() {
    let elem: any = document;
    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.mozCancelFullScreen) { /* Firefox */
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) { /* IE/Edge */
      elem.msExitFullscreen();
    }
  }

  public static isInFullScreen(){
    return document.fullscreenElement != null;
  }

  public static getOS(){
    let ua = navigator.userAgent.toLowerCase();
    if(ua.includes("windows")) return "Windows";
    else if(ua.includes("android")) return "Android";
    else if(ua.includes("mac")) return "Mac";
    else return "Unknown";
  }

  public static isWindows(){
    return this.getOS() == "Windows";
  }

  public static isMac(){
    return this.getOS() == "Mac";
  }

  public static isAndroid(){
    return this.getOS() == "Android";
  }

  public static isDesktop(){
    return this.isWindows() || this.isMac();
  }

  public static getCurrentRouter(route: any) {
    let currentRoute = route.root;
    while (currentRoute.children[0] !== undefined) {
      currentRoute = currentRoute.children[0];
    }
    return currentRoute;
  }

}
