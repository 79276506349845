import { Injectable } from '@angular/core';
import { WebsocketNotificationService } from './websocket-notification.service'
import { CredentialsService } from '../auth';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventBusService } from './eventbus.service';

const PING_INTERVAL = 60000 * 8; // 8 minutos

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService {

  socketStatus : any;
  lastServerStatus: boolean = null;

  pingInterval: any;
  licenseInUse: boolean = false;

  private websocketStatusObservable: Observable<any>;
  private websocketStatusSubscription: any;

  constructor(
    private wsNotificationService: WebsocketNotificationService,
    private credentialsService: CredentialsService,
    private eventBus: EventBusService
  ){}

  checkServer() {
    if (this.isSocketStatusDisconnected() && !this.licenseInUse) {
      this.connect();
    }
  }

  isSocketStatusDisconnected() {
    return !this.socketStatus || !this.socketStatus.source || this.socketStatus.source.closed;
  }

  ping() {
    let sub = this.credentialsService.getCredentials().sub;
    let pingMessage: any = {
      author: sub,
      type: "PING",
      message: ""
    };
    this.socketStatus.next(pingMessage);
  }

  private connect() {
    this.socketStatus = <Subject<any>>this.wsNotificationService.connect(this.credentialsService.getCredentials().sub).
      pipe(map((response: MessageEvent): any => this.mapResponseNotification(response)));

    this.websocketStatusObservable = this.socketStatus.asObservable();
    this.websocketStatusSubscription = this.websocketStatusObservable.subscribe((data: any) => {
      this.manageWebsocketStatus(data);
    });

    if (this.pingInterval) clearInterval(this.pingInterval);

    this.pingInterval = setInterval(() => {
      this.ping();
    }, PING_INTERVAL);
  }

  private mapResponseNotification(response: MessageEvent) {
    if (response.type == "message" && response.data) {
      let data = response.data;
      return {
        author: "TODO",
        type: response.type,
        message: JSON.parse(data)
      };
    } else if (response.type == "close") {
      return {
        author: "TODO",
        type: response.type,
        message: null,
      };
    }
  }

  private manageWebsocketStatus(data: any) {
    if (!data) return;

    if (data.type == "close") {
      this.socketStatus = null;
      this.websocketStatusObservable = null;
      this.websocketStatusSubscription.unsubscribe();
      this.wsNotificationService.disconnect();
      this.lastServerStatus = false;
      return;
    }

    if (data.type == "error") {
      console.error("ERROR");
      return;
    }

    if (this.isOnlySendMessage(data.message.code)) this.eventBus.publish("WS_" + data.message.code, data.message);
  }

  private isOnlySendMessage(code: string) {
    return code == "NEW_CHAT_MESSAGE";
  }
}
