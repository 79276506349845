/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

declare var $: any;

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';
import { Log, LogPublisherConsole, PersistentLog } from '@rehub-shared/utils/log';

if (environment.production) {
  enableProdMode();
}else{
  PersistentLog.getInstance().addPublisher(new LogPublisherConsole())
  Log.getInstance().addPublisher(new LogPublisherConsole());
}

if (environment.gtm_code) {
  const gtmCode: any = environment.gtm_code,
        script = document.createElement('script'),
        noscript = document.createElement('noscript');

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','`+ gtmCode +`');`;

  noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=`+ gtmCode +`"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.head.appendChild(script);
  document.body.appendChild(noscript);
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch((err: any) => console.error(err));
}

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

declare global {
  interface Window {
    RehubPWAInstallEvent: any;
    RehubPWA: boolean;
  }
}

/**
 * PWA install event listener.
 * Listen for the beforeinstallprompt event to open the PWA install window
**/
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();

  const rehubInstallPWAEvent = {
      event: e
  }
  window.RehubPWAInstallEvent = rehubInstallPWAEvent;
});

/**
 * Listen for the DOMContentLoaded event
 * Check if the app is installed
**/
window.addEventListener("DOMContentLoaded", () => {
  let displayMode = "browser-tab"
  if ((navigator as any).standalone) {
    displayMode = "standalone-ios"
  }
  if (window.matchMedia("(display-mode: standalone)").matches) {
    displayMode = "standalone"
  }
  if (window.matchMedia("(display-mode: fullscreen)").matches) {
    displayMode = "fullscreen"
  }
  window.RehubPWA = displayMode !== "browser-tab";

})

