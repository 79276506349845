import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagsService } from '@app/@core/service/feature-flags.service';
import { Permission } from '@rehub-shared/utils/permissions';
import { CredentialsService } from '@core/auth';



@Component({
  selector: 'rehub-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  items: any[];

  constructor(
    public featureFlagsService: FeatureFlagsService,
    private credentialsService: CredentialsService,

  ){}

  ngOnInit() {
    let isChatDisabled = this.chatDisabled();
    this.items = this.getItems(isChatDisabled);

  }

  getItems(isChatDisabled: boolean){
    return [
      {key: "navigation_exercises", icon: "exercise", route: "/", options: {exact: true}},
      {key: "navigation_calendar", icon: "calendar", route: "/calendar", options: {exact: false}},
      {key: "navigation_chat", icon: "chat", route: "/chat", options: {exact: false, hidden: (!this.featureFlagsService.enableChatPatient || isChatDisabled)}},
      {key: "navigation_evolution", icon: "progress", route: "/evolution", options: {exact: false}},
    ]
  }
  private chatDisabled() {
    return this.credentialsService.checkPermission(Permission.Chat_Patient_D)
  }
}