<footer class="fixed-bottom footer-nav">
  <div class="d-flex justify-content-around pt-2 pb-1 h-100" style="font-size: 12px; color: black;">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="!item.options.hidden">
        <a class="d-flex  align-items-center cursor-pointer text-uppercase text-primary text-decoration-none footer-active h-100 my-auto" [routerLink]="item.route" routerLinkActive="footer-active" [routerLinkActiveOptions]="item.options"  style="flex: 1; opacity: 0.5;">
          <div class="w-100">
            <rehub-icon class="mx-auto" [attr.name]="item.icon" size="16"></rehub-icon>
            <div class=" mt-1" style="text-align: center;">{{item.key | translate}}</div>
          </div>
        </a>
      </ng-container>
    </ng-container>
  </div>
</footer>