import { Injectable } from '@angular/core';
import { ConfirmSkipInstallRealtimeModalComponent } from '@app/@shared/modal/confirm-skip-install-realtime-modal/confirm-skip-install-realtime-modal.component';
import { DisconnectedRealtimeModalComponent } from '@app/@shared/modal/disconnected-realtime-modal/disconnected-realtime-modal.component';
import { InstallRealtimeModalComponent } from '@app/@shared/modal/install-realtime-modal/install-realtime-modal.component';
import { ModalComponent } from '@app/@shared/modal/modal.component';
import { UpdateRealtimeModalComponent } from '@app/@shared/modal/update-realtime-modal/update-realtime-modal.component';
import { SharedMeasurementProgramService } from "@rehub-shared/measurement-program/measurement-program.service";
import { ModalService } from './modal.service';
import { RxjsImpl } from './shared-implementations/rxjs.impl';

@Injectable({
  providedIn: 'root',
})
export class MeasurementProgramService extends SharedMeasurementProgramService{

  constructor(
    private modalService: ModalService,
  ){
    super(new RxjsImpl());
  }

  showInstall(data: any = null): void {
    this.modalService.show(ModalComponent, InstallRealtimeModalComponent, {...data, measurementProgramService: this}, (result: any) => {
      this.modalService.close(false, false);
      setTimeout(() => {
        if(result == "update") {
          this.showUpdate();
        }else if(result == "skip"){
          this.showConfirmSkipInstall();
        }
      }, 200);
    });
  }

  showDisconnected(data: any = null): void {
    this.modalService.show(ModalComponent, DisconnectedRealtimeModalComponent, {...data, measurementProgramService: this}, (result: any) => {
      this.modalService.close(false, false);
      if (result == "install") {
        setTimeout(() => {
          this.showInstall();
        }, 200);
      }
    });
  }

  showUpdate(data: any = null): void {
    this.modalService.show(ModalComponent, UpdateRealtimeModalComponent, {...data, measurementProgramService: this});
  }

  showConfirmSkipInstall(data: any = null): void {
    this.modalService.show(ModalComponent, ConfirmSkipInstallRealtimeModalComponent, {...data, measurementProgramService: this}, (result: any) => {
      this.modalService.close(false, false);
      if (result == "install") {
        setTimeout(() => {
          this.showInstall();
        }, 200);
      }
    });
  }
}