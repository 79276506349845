import { environment } from '@env/environment';
import { SharedDeployInfoService } from "@rehub-shared/service/deploy-info.service";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeployInfoService extends SharedDeployInfoService{

  constructor(){
    super(environment, "p");
  }
}