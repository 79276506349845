import { Injectable } from '@angular/core';
import { ToastComponent } from '@shared/toast/toast.component';
import { DOMService } from './dom.service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(
    private domService: DOMService
  ) {}

  show(message: string, theme: string, isCloseButton: boolean) {
    this.domService.remove('Toast');
    let reference: any = this.domService.add('Toast', ToastComponent);
    if (reference !== null) {
      reference.instance.isCloseButton = isCloseButton;
      //reference.instance.title = title;
      reference.instance.position = "bottom left";
      reference.instance.text = message;
      reference.instance.theme = theme;
      reference.instance.reference = reference;
    }
  }
}
