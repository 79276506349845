import { SharedAuthenticationService } from "../service/authentication.service";
import { SharedCredentialsService } from "../service/credentials.service";
import { LoginAPI } from "./login.api";

export class APIResponseInterceptor{

  isRefreshing: boolean = false;
  refreshPromise: Promise<any>;

  constructor(
    private credentialsService: SharedCredentialsService,
    private authenticationService: SharedAuthenticationService,
    private loginClient: string
  ){}

  intercept(axios: any, response: any){
    return response;
  }

  interceptError(axios: any, error: any){
    // console.log(error);
    // console.log(error.code);
    // console.log(error.response);

    // Si la petición ha superado el timeout se devuelve el error
    if(error.code == "ECONNABORTED") return Promise.reject(error); //return error;

    // Si no tiene headers de Authorization no hace falta refrescar el token
    if(!error.config.headers || error.config.headers.Authorization == null) return Promise.reject(error); //return error;

    // Si no tiene error la respuesta no hace falta refrescar el token
    if(![401, 403].includes(error.response.status)) return Promise.reject(error); //return error;

    // Si no está refrescando se hace la petición de refrescar
    if(!this.isRefreshing){
      this.isRefreshing = true;

      let refreshToken = this.credentialsService.getCredentials().refreshToken;
 
      this.refreshPromise = LoginAPI.refreshToken(this.loginClient, refreshToken).then((result: any) => {

        let data = {
          idToken: result.IdToken,
          accessToken: result.AccessToken
        };
        
        error.config.headers.Authorization = result.IdToken;
        
        this.credentialsService.appendCredentials(data);
        this.isRefreshing = false;
        
        // Se vuelve a hacer la petición con los credenciales nuevos
        
        return axios(error.config);
      }).catch((error: any)=>{
        // Si al refrescar no devuelve los tokens correctamente limpia los credenciales y redirige al login
        this.credentialsService.clearCredentials();
        this.authenticationService.redirectToLogin();
      });

      return this.refreshPromise;
    }else{
      // Si ya está refrescando hay que esperar a que termine el refresco en progreso
      return this.refreshPromise.then(()=>{
        // El token se ha refrescado y se vuelve a hacer la petición con los credenciales nuevos
        error.config.headers.Authorization = this.credentialsService.getCredentials().idToken;
        return axios(error.config);
      });
    }
  }
}