<div style="position: absolute;z-index: 9999;" >
    <div *ngIf="!isWellwo" >
        <rehub-coach2  #coach *ngIf="!coachService.disabled && !SharedUtils.isLittleScreenMobile()"></rehub-coach2>
    </div>

    <div *ngIf="isWellwo" >
        <rehub-coach2  #coach *ngIf="!coachService.disabled && !SharedUtils.isLittleScreenMobile()" (click)="toggleCoach()"></rehub-coach2>
        <div *ngIf="(coachService.disabled || SharedUtils.isLittleScreenMobile()) && !isCoachHidden" (click)="toggleCoach()">
            <div *ngIf="!SharedUtils.isLittleScreenMobile() " class="mx-auto" style="width: 105px;" >
                <rehub-icon name="shape_circle" size="90"  class="coach text-primary" ></rehub-icon>
                <img  class="coach mx-auto" style= "height: 75px;right: 9px;bottom: 7px;"  data-toggle="tooltip" data-placement="bottom" [title]="'general_toggle_coach' | translate" src="/assets/images/coach/coach_head_small.png">
            </div>
            <div *ngIf="SharedUtils.isLittleScreenMobile() " class="mx-auto" style="width: 105px;" >
                <rehub-icon name="shape_circle" size="65"  class="coach text-primary" ></rehub-icon>
                <img  class="coach mx-auto" style= "height: 53px;right: 9px;bottom: 7px;"  data-toggle="tooltip" data-placement="bottom" [title]="'general_toggle_coach' | translate" src="/assets/images/coach/coach_head_small.png">
            </div>
        </div>
    </div>
</div>