import { BandColor } from "../utils/protocol-utils";
import { API } from "./api";

export class TherapyAPI extends API {

  static setPain(recordId: string, pain: number){
    const url = `${this.getBaseUrl()}/therapy/exercise/update/pain-satisfaction`;
    let data: any = {
      recordId: recordId,
      pain: pain
    }
    return this.post(url, data);
  }
  static setPulseOximeter(recordId: string, PPM :number | null, D: number | null, SPO2: number | null , measurementTime:string){
    const url = `${this.getBaseUrl()}/therapy/exercise/update/params`;
    let data: any = {
      recordId: recordId,
      pulseOximeter : {
        measurementType: measurementTime,
        PPM: PPM,
        D: D,
        SPO2: SPO2,
        }

    }
    return this.post(url, data);
  }

  static getReference(patientId: string, recordId: string){
    const url = `${this.getBaseUrl()}/therapy/exercise/reference/from-patient`;
    const data = {
      recordId: recordId,
      patient: patientId
    }
    return this.post(url, data, {timeout: 20000});
  }

  static getVideoRecord(recordId: string){
    const url = `${this.getBaseUrl()}/therapy/exercise/reference/from-patient/video?recordId=${recordId}`;
    return this.get(url, {});
  }

  static getVideoProtocol(protocolId: string){
    const url = `${this.getBaseUrl()}/therapy/exercise/reference/from-patient/video?protocolId=${protocolId}`;
    return this.get(url, {});
  }

  static getTherapies(patientId: string, diagnosisId: string){
    const url = `${this.getBaseUrl()}/therapy/patient/all/${patientId}/diagnosis/${diagnosisId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static createSets(data: any){
    const url = `${this.getBaseUrl()}/therapy/exercise/create/sets`;
    return this.post(url, data, {timeout: 20000});
  }

  static createTherapy(patientId: string, diagnosisId: string, therapyName: string | null = null){
    const url = `${this.getBaseUrl()}/therapy/create`;

    let data: any = {
      patientId: patientId,
      diagnosisId: diagnosisId
    }

    if(therapyName) data.therapyName = therapyName;

    return this.post(url, data);
  }

  static updateTherapy(therapyId: string, therapyName: string | null = null){
    const url = `${this.getBaseUrl()}/therapy/update`;

    let data: any = {
      therapyId: therapyId
    }

    if(therapyName) data.therapyName = therapyName;

    return this.post(url, data);
  }

  static getTherapy(therapyId: string){
    const url = `${this.getBaseUrl()}/therapy/info/${therapyId}`;
    return this.get(url, {});
  }

  static getOverallRecovery(diagnosisId: string | null = null){
    const url = `${this.getBaseUrl()}/therapy/patient/chart/overallrecovery/${diagnosisId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getChartRecord(diagnosisId: string | null = null){
    const url = `${this.getBaseUrl()}/therapy/patient/chart/record/${diagnosisId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static updateAdhereranceExpected(date: string, value: number, patientId: string, range: string = "day"){
    const url = `${this.getBaseUrl()}/therapy/exercise/update/adherence-expected/range-date`;
    const data = {
      date: date,
      patientId: patientId,
      adherence: value,
      range: range
    }
    return this.post(url, data);
  }

  static getPatientRoutines(patientId: string){
    const url = `${this.getBaseUrl()}/therapy/patient/routine/general/${patientId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getPatientExercise(exerciseId: string){
    const url = `${this.getBaseUrl()}/therapy/patient/routine/exercise/${exerciseId}`;
    return this.get(url, {});
  }

  static getPatientRoutineExerciseByDate(diagnosisId: string, date: any, protocol: string | null = null){
    const url = `${this.getBaseUrl()}/therapy/patient/routine/exercise/by-date/${diagnosisId}`;

    let data: any = {
      date: date
    }

    if(protocol) data.protocol = protocol;

    return this.post(url, data, {timeout: 20000});
  }

  static deleteProtocols(therapyId: string, protocol: string[]){
    const url = `${this.getBaseUrl()}/therapy/delete/protocol`;
    const data = {
      therapyId: therapyId,
      protocols: protocol
    }
    return this.post(url, data);
  }

  static deleteTherapy(therapyId: string){
    const url = `${this.getBaseUrl()}/therapy/delete/${therapyId}`;
    return this.post(url, {});
  }

  static deleteExercise(exerciseId: string) {
    const url = `${this.getBaseUrl()}/therapy/exercise/delete`;
    let data = {exerciseId: exerciseId};
    return this.post(url, data);
  }

  static uploadExerciseFromPatientManual(recordId: string, recordDuration: any){
    const url = `${this.getBaseUrl()}/therapy/exercise/upload/from-patient/manual`;
    let data = { recordId, recordDuration }
    return this.post(url, data, {timeout: 20000});
  }

  static uploadExerciseFromPatient(params: any, file: any){
    const url = `${this.getBaseUrl()}/therapy/exercise/upload/from-patient`;
    return this.post(url, params).then((result: any)=>{
      return this.put(result, file, {});
    });
  }

  static getPatientTotalExercises(patientId: string){
    const url = `${this.getBaseUrl()}/therapy/patient/routine/general/${patientId}`;

    return this.get(url, {}).then((result: any)=>{
      let totalExercises = 0;
      if(result && result.length > 0){
        result.forEach((therapy: any)=>{
          if(therapy.summary && therapy.summary.all && therapy.summary.all.num) totalExercises += therapy.summary.all.num;
        });
      }
      return totalExercises;
    })
  }

  static getDiagnosis(diagnosisId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/info/${diagnosisId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getAllDiagnoses(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/from-patient/${patientId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static updateDiagnosis(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/update`;
    return this.post(url, data);
  }

  static createDiagnosis(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/create/v2`;
    return this.post(url, data);
  }

  static getJoints(categories: string[] = []): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/joints/all`;
    let data: any = {slug: false};

    if(categories.length > 0) data = {...data, categories};

    return this.get(url, data, {cache: true});
  }

  static getJointsLogopedics() {
    return ["logoDysphagia", "logoVoice", "logoMotor"];
  }

  static deleteDiagnosis(diagnosisId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/delete/${diagnosisId}`;
    return this.post(url, {});
  }

  static getFAForm(patientId: string, diagnosisId: string, status: string = "ALL"): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/faForm/${patientId}/${diagnosisId}`;
    let data = {
        status: status
    };
    return this.post(url, data);
  }

  static createFAForm(patientId: string, diagnosisId: string, faForms: any): Promise<any>{
    return this.manageFAForm(patientId, diagnosisId, { faForms: faForms }, "create");
  }

  static deleteFAForm(patientId: string, diagnosisId: string, faFormResponse: any): Promise<any>{
    return this.manageFAForm(patientId, diagnosisId, { faFormResponse: faFormResponse }, "delete");
  }

  static listFaForm(patientId: string, diagnosisId: string, faFormResponse: any): Promise<any>{
    return this.manageFAForm(patientId, diagnosisId, { faFormResponse: faFormResponse }, "list_notes");
  }

  static getStrengthFromBand(data: {cam_main_initial: number, cam_main_final: number, rom: number, bandColor: BandColor, strengthMin: number, bandLength: number}): Promise<any>{
    const url = `${this.getBaseUrl()}/therapy/exercise/create/helpers/band/strength`;
    return this.post(url, data);
  }

  private static manageFAForm(patientId: string, diagnosisId: string, faForms: any, action: string): Promise<any>{
    const url = `${this.getBaseUrl()}/diagnosis/faForm/${patientId}/${diagnosisId}/${action}`;
    return this.post(url, faForms, {timeout: 20000});
  }
}