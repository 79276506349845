const LOCALSTORAGE_KEY = "language";

export type LanguageInfo = {
  name: string,
  code: string,
  flag: string,
  prefix?: string
}

export type TranslateArgs = {
  [key: string]: any
}
export class SharedI18nService{

  private translations: any = {};
  private messagesLength: any = {};
  private defaultLanguage = "english";
  private language: string = this.defaultLanguage;
  private supportedLanguages: LanguageInfo[];

  setTranslation(language: string, translations: any){
    this.translations[language] = translations;
  }
  setMessagesLength(messagesLength: any){
    this.messagesLength = messagesLength;
  }
  getMessageLength(id: string): number {
    return this.messagesLength[id];
  }

  setSupportedLanguages(supportedLanguages: LanguageInfo[]){
    this.supportedLanguages = supportedLanguages;
  }

  getSupportedLanguages(): LanguageInfo[]{
    return this.supportedLanguages;
  }

  setLanguage(language: string): void{
    console.log("setLanguage", language);
    if(language == null) return;
    if(!language) return;

    localStorage.setItem(LOCALSTORAGE_KEY, language);
    this.language = language;
  }

  getLanguage(): string{
    return this.language;
  }

  getLanguages(): LanguageInfo[]{
    return this.getSupportedLanguages();
  }

  getLanguageCode(): string{
    let languageInfo = this.getLanguageInfo(this.language);
    return languageInfo ? languageInfo.code : "es";
  }

  getLanguagePrefix(): any {
    let languageInfo = this.getLanguageInfo(this.language);
    return languageInfo && languageInfo.prefix ? languageInfo.prefix : "+34";
  }

  translate(key: string, args: any = null): string{
    if(Object.keys(this.translations).length == 0) return key;
    if(Object.keys(this.translations[this.language]).length == 0) return key;

    let translated = this.translations[this.language][key];

    if(translated == null) return key;

    //TODO! traducir parámetros

    return translated;
  }

  setDefaultLanguage(language: string){
    this.defaultLanguage = language;
  }

  private getLanguageInfo(languageName: string){
    let supportedLanguages = this.getSupportedLanguages();
    return supportedLanguages.find((language: LanguageInfo)=>language.name == languageName);
  }
}