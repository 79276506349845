import { SensorAPI } from "../api";
import { API } from "../api/api";
import { SensorPosition, SignalName, SignalShape } from "../utils/protocol-utils";
import { IRxjs } from "../utils/rxjs.interface";
import { ExerciseService } from "./exercise.service";
import { WasmExerciseService } from "./wasm-exercise.service";

const IP = "127.0.0.1";
const HTTP_PORT = "9090";
const PING_INTERVAL = 60000 * 2; // 2 minutos

export type MeasurementData = {
  degree: number,
  strength: number;
  cycleCount: number;
  message: string;
  score: number[];
  control: string[];
  isMoving: boolean;
  log: string;
}

export abstract class SharedMeasurementProgramService{

  private sub: string;
  private stage: string;
  private lastStatus: string;
  private status: string;
  private _hasSensors: boolean;
  private enabled: boolean = false;
  private localVersion: string;
  private skipSensorAndVersionCheck: boolean = false;

  private statusChangeSubject: any;
  private checkRealtimeInterval: any;
  private pingInterval: any;

  private exerciseServiceSubject: any;
  private exerciseServiceSubscription: any;
  private exerciseService: ExerciseService;
  private androidEnabled: boolean = false;
  private iosEnabled: boolean = false;
  private realtimeInfo: any;

  constructor(
    private rxjs: IRxjs
  ){}

  init(exerciseService: ExerciseService, params: {sub: string, stage: string, skipSensorAndVersionCheck: boolean, realtimeInfo: any}): Promise<any>{
    if(this.enabled && this.exerciseService.equals(exerciseService)){
      return Promise.resolve(this.enabled);
    }

    this.exerciseService = exerciseService;
    this.sub = params.sub;
    this.skipSensorAndVersionCheck = params.skipSensorAndVersionCheck;
    this.stage = params.stage;
    this.realtimeInfo = params.realtimeInfo;

    let batch = {
      hasSensors: this.getHasSensors(),
      versions: this.getRealtimeServerVersions()
    }

    return API.allFromObject(batch).then((result: any)=>{
      //Si tiene sensores y hay exerciseService se habilita y se intenta conectar al websocket
      if(result.hasSensors && this.exerciseService){
        this.enabled = true;
        this.statusChangeSubject = this.createStatusChangeSubject();
        // TODO: Enviar correctamente connecting, no se entera ya que se emite antes de que se pueda suscribir
        this.setStatus("CONNECTING");
        this.connect();
      }

      return this.enabled;
    }).catch(()=>{
      return false;
    });
  }

  isEnabled(){
    return this.enabled;
  }

  isConnected(){
    return this.enabled && this.status == "CONNECTED";
  }

  getStatus(){
    return this.status;
  }

  getLastStatus(){
    return this.lastStatus;
  }

  setStatus(status: string){
    this.lastStatus = this.status;
    this.status = status;
    if(this.lastStatus != this.status && this.statusChangeSubject) this.statusChangeSubject.next(status);
  }

  onStatusChange(){
    if(this.statusChangeSubject == null) this.statusChangeSubject = this.createStatusChangeSubject();
    return this.statusChangeSubject;
  }

  downloadRealtimeInstaller(){
    this.getRealtimeServerVersions().then((versions: string[])=>{
      let version = versions[versions.length - 1].split("_").join(".");
      window.location.href = "https://rehub-updates.dycare-services.com/Rehub" + version + ".exe";
      this.setStatus("INSTALLING");
      this.startCheckRealtimeInterval();
    }, ()=>{});
  }

  updateRealtime(){
    this.setStatus("UPDATING");
    this.getRealtimeServerVersions().then((versions: string[])=>{
      let data = {
        realtimeVersion: versions[versions.length - 1]
      }

      //TODO! Eliminar
      if(this.localVersion == "2_0_24"){
        let url = "http://" + IP + ":" + HTTP_PORT + "/action/update/";

        API.postWithoutAuth(url, data).then((r: any)=>{
          this.startCheckRealtimeInterval();
        });
      }else{
        this.commandActionUpdate(data);
        this.startCheckRealtimeInterval();
      }

    },()=>{});
  }

  resetRealtime(){
    if(this.status == "CONNECTED"){
      this.commandActionReset();
    }else{
      window.location.href = "rehub://";
    }

    this.setStatus("RESETTING");

    this.startCheckRealtimeInterval();
  }

  isValidVersion(callback: Function){
    if(this.skipSensorAndVersionCheck){
      callback(true);
      return;
    }

    return this.getRealtimeServerVersions().then((serverVersions: string[])=>{
      callback(serverVersions.includes(this.localVersion));
    }, (error:any) => {
      callback();
    });
  }

  cancelInstall(){
    clearInterval(this.checkRealtimeInterval);
    if(this.status == "INSTALLING") this.setStatus("PENDING_INSTALL");
  }

  cancelUpdate(){
    clearInterval(this.checkRealtimeInterval);
    this.setStatus("PENDING_UPDATE");
  }

  isAndroidEnabled(){
    return this.androidEnabled;
  }

  isIOSEnabled(){
    return this.iosEnabled;
  }

  getExerciseService(){
    return this.exerciseService;
  }

  getExerciseServiceSubject(){
    return this.exerciseServiceSubject;
  }

  private startCheckRealtimeInterval(){
    // Se intenta conectar cada 8 segundos hasta que se instala y se conecta
    if(this.checkRealtimeInterval) clearInterval(this.checkRealtimeInterval);
    this.checkRealtimeInterval = setInterval(()=>{
      if(this.status == "CONNECTED"){
        clearInterval(this.checkRealtimeInterval);
        return;
      }
      this.disconnect();
      this.connect();
    }, 8000);
  }

  private disconnect(){
    if(this.exerciseService) this.exerciseService.destroy();
    if(this.exerciseServiceSubscription) this.exerciseServiceSubscription.unsubscribe();
  }

  private connect(){

    this.exerciseServiceSubject = this.exerciseService.init();

    this.exerciseServiceSubscription = this.exerciseServiceSubject.subscribe((data: any)=>{
      if(!data) return;

      if(data.type == "error"){
        console.error("error websocket");

      }else if(data.type == "close"){
        console.error("close websocket");

        //Si está actualizando, reiniciando o instalando no queremos que cambie de estado
        if(this.status == "UPDATING" || this.status == "RESETTING" || this.status == "INSTALLING"){
          return;
        }

        // Si ya estaba conectado y de golpe se desconecta
        if(this.status == "CONNECTED"){
          this.clearPingInterval();
          this.startCheckRealtimeInterval();
        }

        // Si está instalado pero no se puede conectar
        if(this.isRealtimeInstalled()){
          this.setStatus("DISCONNECTED");
        }
        //Si no está instalado y no ha podido levantar y no lo está instalando ya
        else if (this.status != "PENDING_INSTALL"){
          this.setStatus("PENDING_INSTALL");
        }

      }else if(data.type == "message"){

        // Si se he conectado correctamente al websocket
        if(data.message.command == "client_connect"){

          if(this.status == "INSTALLING"){
            //TODO: se ha instalado correctamente
          }

          if(this.status == "UPDATING"){
            //TODO: se ha actualizado correctamente
          }

          this.localVersion = data.message.response.version;
          // Se marca en el localStorage que el realtime ya está instalado
          if(!this.isRealtimeInstalled()){
            this.setRealtimeInstalled();
          }

          this.clearSkipRealtimeInstall();

          // Se envia el environment para poder abrir la web correcta desde el acceso directo
          //this.commandActionSetEnvironment(this.stage);

          // Se comprueba que la versión sea correcta
          this.isValidVersion((valid: boolean)=>{
            // Si la version no coincide hay que actualizar
            if(valid){
              this.setStatus("CONNECTED");
              //Se envian pings cada x minutos para que no se cierre la conexion
              this.startPingInterval();
            }else if(this.status != "UPDATING" && this.status != "PENDING_UPDATE"){
              this.setStatus("PENDING_UPDATE");
            }
          });
        }
      }
    });
  }

  private startPingInterval(){
    this.clearPingInterval();
    this.pingInterval = setInterval(()=>{
      this.exerciseService.commandPing();
    }, PING_INTERVAL);
  }

  private clearPingInterval(){
    if(this.pingInterval) clearInterval(this.pingInterval);
    this.pingInterval = null;
  }

  private setRealtimeInstalled(){
    localStorage.setItem("realtimeInstalled", "true");
  }

  public setSkipRealtimeInstall(){
    localStorage.setItem("skipRealtimeInstall", "true");
  }

  public clearSkipRealtimeInstall(){
    localStorage.removeItem("skipRealtimeInstall");
  }

  public isSkipRealtimeInstallEnabled(){
    let skipRealtimeInstall = localStorage.getItem("skipRealtimeInstall");
    return skipRealtimeInstall && skipRealtimeInstall == "true";
  }

  public isRealtimeInstalled(){
    let realtimeInstalled = localStorage.getItem("realtimeInstalled");
    return realtimeInstalled && realtimeInstalled == "true";
  }

  public hasSensors(){
    return this._hasSensors;
  }

  private createStatusChangeSubject(){
    return this.rxjs.createEmptySubject();
  }

  //TODO usar promesas?
  private getHasSensors(): Promise<boolean>{
    if(this.skipSensorAndVersionCheck){
      this._hasSensors = true;
      return Promise.resolve(true);
    }

    return SensorAPI.getSensorKitsByOwner(this.sub).then((result: any) => {
      this._hasSensors = result && Object.keys(result).length > 0;
      return this._hasSensors;
    }).catch((error:any) => {
      return false;
    });
  }

  private getRealtimeServerVersions(): Promise<any>{
    return new Promise((resolve: any, reject: any)=>{
      if(this.skipSensorAndVersionCheck){
        resolve();
        return;
      }

      if(this.realtimeInfo.androidEnabled) this.androidEnabled = this.realtimeInfo.androidEnabled;
      if(this.realtimeInfo.iosEnabled) this.iosEnabled = this.realtimeInfo.iosEnabled;
      resolve(this.realtimeInfo.versions);
    });
  }

  formatData(data: any, mainSignal: string | null = null): MeasurementData{
    let result: MeasurementData = { ...data };

    if(mainSignal) result.degree = Math.round(data[mainSignal]);

    result.strength = data.Strength != null ? data.Strength : null;
    result.cycleCount = data.cc;
    result.message = data.f || "";
    result.score = data.s || [];
    result.control = data.c || [];
    result.isMoving = data.Stationary && data.Stationary == "MOVEMENT";

    return result;
  }


  // COMMANDS

  cancel(){
    this.exerciseService.commandStopRecord();
    this.exerciseService.commandStopProcessRecord();
    this.exerciseService.commandStopStream();
  }

  pause(){
    this.exerciseService.commandStopRecord();
    this.exerciseService.commandStopProcessRecord();
  }

  resume(){
    this.exerciseService.commandStartRecord();
    this.exerciseService.commandStartProcessRecord();
  }

  stop(){
    this.exerciseService.commandStopRecord();
    this.exerciseService.commandStopProcessRecord();
    this.exerciseService.commandStopStream();
    if(this.exerciseService instanceof WasmExerciseService) this.exerciseService.commandGetUploadRecord();
    else this.exerciseService.commandUploadRecord();
  }

  stopStream(){
    this.exerciseService.commandStopStream();
  }

  startStream(){
    this.exerciseService.commandStartStream();
  }

  record(){
    this.exerciseService.commandStartRecord();
  }

  getScore(){
    this.exerciseService.commandGetScore();
  }

  zero(mode: string = "ALL", reset: boolean = true){
    this.exerciseService.commandZero(mode, reset);
  }

  invertMainSignal(){
    this.exerciseService.commandInvertMainSignal();
  }

  reset(){
    this.exerciseService.commandResetRecord();
  }

  discard(){
    this.exerciseService.commandResetRecord();
  }

  invertedSignals(signals: any){
    this.exerciseService.commandInvertedSignals(signals);
  }

  commandNewUser(idToken: string, stage:string){
    this.exerciseService.commandNewUser(idToken, stage);
  }

  commandNewRecord(joint: string, protocol:string, secondarySignals: boolean){
    this.exerciseService.commandNewRecord(joint, protocol, secondarySignals);
  }

  commandNewSignalAssessment(record: any, mainSignal: SignalName, signalShape: SignalShape, exerciseId: string, referenceRecordSD: any, inverted: any, hasStrength: boolean, hasROM: boolean, active: boolean, bilateral: boolean){
    this.exerciseService.commandNewSignalAssessment(record, mainSignal, signalShape, exerciseId, referenceRecordSD, inverted, hasStrength, hasROM, active, bilateral);
  }

  commandNewSignalTracker(mainSignal: SignalName, signalShape: SignalShape, therapyId: string, minRange: number, maxRange: number, inverted: any, hasStrength: boolean, active: boolean, bilateral: boolean){
    this.exerciseService.commandNewSignalTracker(mainSignal, signalShape, therapyId, minRange, maxRange, inverted, hasStrength, active, bilateral);
  }

  commandNewCustomProtocol(){
    this.exerciseService.commandNewCustomProtocol();
  }

  commandSetSensor(mac: string){
    this.exerciseService.commandSetSensor(mac);
  }

  commandDisconnectSensor(){
    this.exerciseService.commandDisconnectSensor();
  }

  commandStartStream(){
   this.exerciseService.commandStartStream();
  }

  startStreamCalibration(){
    this.exerciseService.commandStartStreamCalibration();
  }

  commandStopStream(){
    this.exerciseService.commandStopStream();
  }

  commandStartProcessRecord(){
   this.exerciseService.commandStartProcessRecord();
  }

  commandCheckSensorPosition(sensorPosition: SensorPosition){
    this.exerciseService.commandCheckSensorPosition(sensorPosition);
  }

  recalibrateGyroscope(){
    this.exerciseService.commandRecalibrateGyroscope();
  }

  recalibrateGauge(){
    this.exerciseService.commandRecalibrateGauge();
  }

  commandActionSetEnvironment(env: string){
    this.exerciseService.commandActionSetEnvironment(env);
  }

  commandActionUpdate(data: any){
    this.exerciseService.commandActionUpdate(data);
  }

  commandActionReset(){
    this.exerciseService.commandActionReset();
  }

  commandActionReady(){
    this.exerciseService.commandActionReady();
  }

  showingInstall: boolean = false;
  showingDisconnected: boolean = false;
  showingUpdate: boolean = false;
  showingConfirmSkipInstall: boolean = false;

  abstract showInstall(data: any): void;
  abstract showDisconnected(data: any): void;
  abstract showUpdate(data: any): void;
  abstract showConfirmSkipInstall(data: any): void;
}