import { Component, OnInit, Input, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { ModalService } from '@app/@core/service/modal.service';
import { CoachService } from '@app/@core/service/coach.service';
import { I18nService } from '@app/i18n';
import { Utils } from '@app/utils';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { ProtocolUtils } from '@rehub-shared/utils/protocol-utils';
import { CredentialsService } from '@app/@core/auth';

@Component({
  selector: 'materials-modal-component',
  templateUrl: './materials-modal.component.html',
  styleUrls: ['./materials-modal.component.scss'],
  host: { class: "modal-100" }
})

export class MaterialsModalComponent implements OnInit {

  @Input() data: any;
  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef>;

  Utils = Utils;
  SharedUtils = SharedUtils;
  openPanels: string[] = []; // Mantiene un registro de los paneles abiertos
  matPlaying: string; // Mantiene un registro del material que se está reproduciendo
  materialsLength: number = 0;
  view_tools: boolean = false;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private coachService: CoachService,
    private credentialsService: CredentialsService

  ) { }

  ngOnInit(): void {
    // Inicialización adicional si es necesario
    this.coachService.setState({step: "open_materials_modal"});
  }

  getMaterialInstructionsVideoURL(material: string): string {
    let videoToolURL = `https://web-resources.dycare-services.com/rehub-frontend/tools/${material}.mp4`;
    return videoToolURL;
  }

  isPanelOpen(material: string): boolean {
    return this.openPanels.includes(material);
  }


  ngAfterViewInit() {
    let acc = document.getElementsByClassName("accordion");
    this.materialsLength = acc.length;

    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {

        // Close all other panels
        for (let j = 0; j < acc.length; j++) {
          if (acc[j] !== this) {
            acc[j].classList.remove("active");
            let otherPanel = acc[j].nextElementSibling as HTMLElement;
            if (otherPanel.style.display === "contents") {
              otherPanel.style.display = "none";
            }
          }
        }

        // Toggle the clicked panel
        this.classList.toggle("active");
        let panel = this.nextElementSibling;
        if (panel.style.display === "contents") {
          panel.style.display = "none";
        } else {
          panel.style.display = "contents";
        }

      });
    }
  }

  togglePanel(material: string, index: number): void {
    for(let i = 0; i <  this.materialsLength; i++){
      this.matPlaying = material
      if( i === index ){
        //this.playVideo(index);
        break
      }else{
        this.pauseVideo(index);
        break;
      }
    }
  }
  onCheckboxPressed(){
    this.view_tools = !this.view_tools;
    let sub = this.credentialsService.getSub();
    localStorage.setItem(sub +"_view_tools", this.view_tools.toString());
  }

  playVideo(index: number): void {
    const videoElement = this.videoElements.toArray()[index];
    if (videoElement) {
      videoElement.nativeElement.play();
    }
  }

  pauseVideo(index: number): void {
    const videoElement = this.videoElements.toArray()[index];
    if (videoElement) {
      videoElement.nativeElement.pause();
    }
  }

  onClose(): void {
    this.modalService.close(false, false);
  }

  startNextExercise(): void {
    this.modalService.callback(true);
  }
}
