export abstract class SharedFormCheckboxComponent {

  items: any[];
  id: string;
  disabled: boolean = false;
  answered: any;

  abstract emitChangeCheckbox(value: any): void;
  abstract getButtonTheme(): string;

  changeBox(value:any): void {
    if (this.disabled) return;
    this.answered = value;
    this.emitChangeCheckbox(this.answered);
  }

}