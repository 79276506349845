import { ControllerType, SharedRCCommon } from './rc-common';
import { WSRemoteController } from './ws/ws-rc';
import { ChromecastSender } from './chromecast/chromecast-sender';

export abstract class SharedRCSender extends SharedRCCommon {

  urlWS: string;
  sub: string;
  nameSpace: string;

  constructor(urlWS: string, nameSpace: string, sub: string) {
    super();
    this.urlWS = urlWS;
    this.nameSpace = nameSpace;
    this.sub = sub;
  }

  createController(type: ControllerType, additionalConfig: string | null = null): void {
    if (type == ControllerType.Chromecast) this.controller = new ChromecastSender(this.nameSpace);
    if (type == ControllerType.WS) this.controller = new WSRemoteController("client_message", this.urlWS, additionalConfig ? additionalConfig : this.sub);
    this.type = type;
    this.setInfo({ type: type, created: true });
  }

}