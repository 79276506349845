
  
    <div class="w-100 align-items-center d-flex reverse flex-lg-row h-100 justify-content-between mb-2 px-3 py-2 shadow bg-light" style="border-radius: 15px !important;">
      
      <div class="m-3 d-flex justify-content-center">
        <img [src]="'assets/images/achievement/' + achievement.achievementName + '.jpeg'" alt="" [ngClass]="{'greyscale': pending}"
          style="width: 50px; height: 50px; border-radius: 50px;" class="shadow">
      </div>

      <div class="d-flex flex-column w-100 h-100 justify-content-between">
        
        <h3 *ngIf="achievement.doneDate" class="align-items-center center-content d-flex flex-wrap height-card-sm justify-content-around text-center" style="height: 52px; text-align: center;">
          {{"achievement_" + achievement.achievementName | translate }}         
        </h3>
        <h3 *ngIf="achievement.doneDate" class="w-100 d-flex justify-content-center">
          {{achievement.achievementValue}}/{{achievement.achievementValue}}
        </h3>
         

        <!-- <h3 *ngIf="!achievement.doneDate" class="align-items-center center-content d-flex flex-wrap height-card-sm justify-content-around text-center" style="height: 52px; text-align: center;">
          {{"achievement_" + achievement.achievementName | translate }}         
        </h3>
        <rehub-progress-bar *ngIf="!achievement.doneDate" [current]="getAchievementRemaining()" [max]="achievement.achievementValue" [showProgress]="true" class="w-100 d-flex justify-content-center" ></rehub-progress-bar>
        
  -->

      </div>


    </div>

 



