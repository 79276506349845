import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rehub-dot-indicator',
  templateUrl: './dot-indicator.component.html',
  styleUrls: ['./dot-indicator.component.scss'],
})
export class DotIndicatorComponent implements OnInit {

  @Input() numberDots: number = 1;
  @Input() indexSelected: number = 0;

  dots: any;

  ngOnInit(): void {
    this.dots = Array(this.numberDots).fill(0).map((x, i) => i);
  }

}