/**
 * Bus de eventos
 * 
 * Referencias: {@link https://pierfrancesco-soffritti.medium.com/create-a-simple-event-bus-in-javascript-8aa0370b3969}
 * {@link https://github.com/PierfrancescoSoffritti/light-event-bus.js}
 */
export class SharedEventBusService {

  private lastId: number = 0;
  //TODO! usar Map en vez de un objeto
  private subscriptions: any = {};

  /**
   * Escucha mensajes de un canal en concreto
   * @param channel Nombre del canal que se va a escuchar
   * @param callback Función que se va a ejecutar cuando llegue un mensaje en el cancal indicado
   * @returns Objeto con la función unsubscribe para limpiar la subscripción cuando ya no haga falta
   */
  public subscribe(channel: string, callback: any){
    const id = this.getNextId();

    if(!this.subscriptions[channel]) this.subscriptions[channel] = {};

    this.subscriptions[channel][id] = callback;

    return { 
      unsubscribe: () => {
        if(this.subscriptions[channel]){
          if(this.subscriptions[channel][id]) delete this.subscriptions[channel][id]
          if(Object.keys(this.subscriptions[channel]).length === 0) delete this.subscriptions[channel]
        }
      }
    }
  }

  /**
   * Publica un mensaje en un canal en concreto
   * @param channel Nombre del canal en el que se va a publicar el mensaje
   * @param message Mensaje que se va a enviar por el canal
   */
  public publish(channel: string, message: any = null): void{
    if(!this.subscriptions[channel]) return;

    let data: any = {
      channel: channel
    }
    
    if(message) data.message = message;

    Object.keys(this.subscriptions[channel]).forEach(key => this.subscriptions[channel][key](data));
  }

  /**
   * Incrementa el id y lo devuelve
   * @returns El siguiente id
   */
  private getNextId(){
    return this.lastId++;
  }
}