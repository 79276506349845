export abstract class SharedFormSliderComponent {

  min: number;
  max: number;
  step: number;
  number: any = 0;
  disabled: boolean = false;
  values: any[] = [];

  abstract emitChangeSlider(value: any): void;
  abstract getButtonTheme(): string;

  init() {
    let range = Math.abs(this.max - this.min);
    this.step = range / 10;
    for (let i = this.min; i <= this.max; i += this.step) {
      this.values.push(i);
    }
  }

  changeRange(value: any): void {
    this.number = value;
    this.emitChangeSlider(value);
  }

  getLeft() {
    return (this.number / this.max) * 100;
  }

}