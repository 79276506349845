import { Injectable } from '@angular/core';
import { EventBusService } from './eventbus.service';

import { SharedOnboardingService } from "@rehub-shared/service/onboarding.service";

@Injectable({
  providedIn: 'root',
})
export class OnboardingService extends SharedOnboardingService {

  constructor(
    eventBus: EventBusService
  ) {
    super(eventBus);
  }

  initOnboarding(languageCode: string, patientId: string, isScoachHidden: boolean) {
    return this.initOnboardingShared(languageCode, patientId, isScoachHidden);
  }

  generateOnboardingListeners(){
    return this.generateOnboardingListenersShared();
  }

  closeOnboardingListeners(){
    return this.closeOnboardingListenersShared();
  };

  onbordingTheme(primary: string, secondary: string){
    return this.onbordingThemeShared(primary, secondary);
  };

}
