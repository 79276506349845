const defaultTaggedTemplate = (strings, ...values)=>{
  let str = '';
  strings.forEach((string, i) => {
    str += string + (values[i] || '');
  });
  return str;
};

export const baseURL = "assets/web-components/";

export const html = (strings, ...values) => defaultTaggedTemplate(strings, ...values);
export const css = (strings, ...values) => defaultTaggedTemplate(strings, ...values);