<div class="modal-header">
  <h5 class="modal-title" >{{ "modal_realtime_pending_install_title" | translate }}</h5>
  <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column" >
  {{ "modal_realtime_pending_install_body_android" | translate }}
</div>

<div class="modal-footer border-0 pt-0 d-flex justify-content-center">
  <button type="button" class="btn btn-primary btn-lg d-flex flex-row align-items-center justify-content-center"
    (click)="onInstallPressed()">
    <rehub-icon name="android" size="25" class="mr-2"></rehub-icon>
    <span>{{"modal_realtime_install_android" | translate}}</span>
  </button>
</div>