import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { I18nService } from '@app/i18n';
import { SharedUtils } from '@rehub-shared/utils/utils';

@Component({
  selector: 'rehub-confirm-modal',
  templateUrl: './pulsowarning-modal.component.html',
  styleUrls: ['./pulsowarning-modal.component.scss']
})
export class PulsoWarningModalComponent implements OnInit {

  SharedUtils = SharedUtils;

  data: any;
  disabled: boolean;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {

  }

  close() {
    this.modalService.close(false, true);
  }

  confirm() {
    this.disabled = true;
    this.modalService.callback(true);
  }

  cancel() {
    this.close();
  }

  getBodyTranslation(){
    if(!this.data.body) return;
    return this.i18nService.translate(this.data.body);
  }
}
