declare var ResizeObserver: any;

export class SharedFillContainerDirective{

  resizeObserver: any;

  constructor(
    protected el: any,
    protected cd: any
  ) {}

  destroy(){
    if(this.resizeObserver) this.resizeObserver.disconnect();
  }

  init(id: string){
    this.destroy();
    
    let element: Element | null = document.querySelector(id);
    
    if(element == null){
      setTimeout(()=>{
        if(element == null) this.init(id);
      }, 10);

      return;
    }

    this.resizeObserver = new ResizeObserver((entries: any[])=>{
      let size = this.getElementSize(element!);
      let offset = this.getElementOffset(element!);

      this.el.nativeElement.style.width = size.width + "px";
      this.el.nativeElement.style.height = size.height + "px";
      this.el.nativeElement.style.left = offset.left + "px";
      this.el.nativeElement.style.top = offset.top + "px";

      this.cd.detectChanges();
    }); 

    this.resizeObserver.observe(element);
  }

  getElementSize(element: Element){
    return {width: element.clientWidth, height: element.clientHeight};
  }

  getElementOffset(element: Element){
    let viewportOffset = element.getBoundingClientRect();
    return {left: viewportOffset.left, top: viewportOffset.top};
  }
}