import { Injectable } from '@angular/core';
import { SharedVoiceControlService } from "@rehub-shared/service/voice-control.service";
import { EventBusService } from './eventbus.service';
import { LocalSettingsService } from './local-settings.service';
@Injectable({
  providedIn: 'root'
})
export class VoiceControlService extends SharedVoiceControlService {

  constructor(
    eventBus: EventBusService,
    localSettingsService: LocalSettingsService
  ) {
    super(eventBus, localSettingsService);
  }
}
