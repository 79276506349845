import { ICoach } from "@rehub-shared/coach/coach.interface";
import { DOMService } from "../dom.service";

export class CoachImpl implements ICoach{

  domService: DOMService;

  constructor(domService: DOMService){
    this.domService = domService;
  }

  addToDOM(component: any): any{
    return this.domService.add("coach", component);
  }

  removeFromDOM(reference: string): void{
    this.domService.remove("coach");
  }
}