import { SharedUtils } from "../utils/utils";
import { APIResponseInterceptor } from "./api-response-interceptor";

export abstract class API{

  static responseCache = new Map();

  private static baseUrl: string;

  private static axios: any;

  private static cache: any = {};

  static setAxiosInstance(axios: any){
    this.axios = axios;
  }

  static setInterceptor(interceptor: APIResponseInterceptor){
    this.axios.interceptors.response.use((response: any)=>{
      return interceptor.intercept(this.axios, response);
    },(error: any)=>{
      return interceptor.interceptError(this.axios, error);
    });
  }

  static setBaseUrl(serviceURL: string): void{
    this.baseUrl = serviceURL;
  }

  static getBaseUrl(): string{
    if(this.baseUrl == undefined) throw new Error("baseUrl no está definida");
    return this.baseUrl;
  }

  private static getIdToken(){
    let credentials = JSON.parse(localStorage.getItem("credentials") || "");
    if(!credentials) return null;

    return credentials.idToken;
  }

  static get(url: string, params: any = {}, extraRequestParams: any = {}): Promise<any>{
    let urlWithQueryParams = SharedUtils.addQueryParamsToURL(url, params);
    let cached = this.cache[urlWithQueryParams];

    if(extraRequestParams.cache == true && cached){
      return Promise.resolve(cached);
    }

    const headers = {"Authorization": this.getIdToken()};

    return this.axios({
      method: "get",
      url: urlWithQueryParams,
      headers: headers,
      ...extraRequestParams
    }).then((response: any)=>{
      if(extraRequestParams.cache){
        this.cache[urlWithQueryParams] = response.data;
      }

      return response.data;
    });
  }

  static post(url: string, data: any = {}, extraRequestParams: any = {}): Promise<any>{
    const headers = {"Authorization": this.getIdToken()};

    return this.axios({
      method: "post",
      url: url,
      headers: headers,
      data: data,
      ...extraRequestParams
    }).then((response: any)=>response.data);
  }

  static put(url: string, data: any = {}, customHeaders: any = {}, extraRequestParams: any = {}): Promise<any>{
    const headers = {"Content-Type": "application/octet-stream", ...customHeaders};

    return this.axios({
      method: "put",
      url: url,
      data: data,
      headers: headers,
      ...extraRequestParams
    }).then((response: any)=>response.data);
  }

  static getWithoutAuth(url: string, params: any = {}, extraRequestParams: any = {}): Promise<any>{
    return this.axios({
      method: "get",
      url: SharedUtils.addQueryParamsToURL(url, params),
      ...extraRequestParams
    }).then((response: any)=>response.data);
  }

  static getHeadersWithoutAuth(url: string, params: any = {}, extraRequestParams: any = {}): Promise<any>{
    return this.axios({
      method: "get",
      url: SharedUtils.addQueryParamsToURL(url, params),
      ...extraRequestParams
    }).then((response: any)=>response.headers);
  }

  static headWithoutAuth(url: string, params: any = {}, extraRequestParams: any = {}): Promise<any>{
    return this.axios({
      method: "head",
      url: SharedUtils.addQueryParamsToURL(url, params),
      ...extraRequestParams
    }).then((response: any)=>response);
  }

  static postWithoutAuth(url: string, data: any = {}, extraRequestParams: any = {}): Promise<any>{
    return this.axios({
      method: "post",
      url: url,
      data: data,
      ...extraRequestParams
    }).then((response: any)=>response.data);
  }

  static all(params: Promise<any>[]): Promise<any>{
    return Promise.all(params);
  }

  static allFromObject(params: {[key: string]: Promise<any>}): Promise<any>{
    let keys = Object.keys(params);
    let values = Object.values(params);

    return Promise.all(values).then((result: any[])=>{
      let r: any = {};

      result.forEach((promise: any, index: number)=>{
        r[keys[index]] = promise;
      });

      return r;
    });
  }
}