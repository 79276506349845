import { API } from "./api";

export class LoginAPI extends API {

  static login(code: string): Promise<any> {
    let params = { code };

    const url = `${this.getBaseUrl()}/user/get-credentials`;
    return this.getWithoutAuth(url, params);
  }

  static changePassword(previousPassword: string, proposedPassword: string, accessToken: string): Promise<any> {
    let params = { previousPassword, proposedPassword, accessToken };

    const url = `${this.getBaseUrl()}/user/change-password`;
    return this.postWithoutAuth(url, params);
  }

  static forgotPassword(username: string, client: string): Promise<any> {
    let params = { username, client };

    const url = `${this.getBaseUrl()}/user/forgot-password`;
    return this.postWithoutAuth(url, params);
  }

  static refreshToken(client: string, refreshToken: string): Promise<any> {
    let params = { client, refreshToken };

    const url = `${this.getBaseUrl()}/user/refresh-token`;
    return this.postWithoutAuth(url, params);
  }
}