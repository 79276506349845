import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalService } from "@app/@core/service";
import { CoachService } from "@app/@core/service/coach.service";
import { DeployInfoService } from "@app/@core/service/deploy-info.service";
import { I18nService } from "@app/i18n";
import { environment } from "@env/environment";
import { ChatAPI } from "@rehub-shared/api";
import { IOSExerciseService } from "@rehub-shared/measurement-program/ios-exercise.service";
import { AudioManager } from "@rehub-shared/utils/audio-manager";
import { SharedUtils } from "@rehub-shared/utils/utils";
import { ChatUtils } from "@rehub-shared/utils/chat.utils";
import * as moment from 'moment';

@Component({
  selector: 'rehub-calling-modal',
  templateUrl: './calling-modal.component.html',
  styleUrls: ['./calling-modal.component.scss']
})
export class CallingModalComponent implements OnInit, OnDestroy {

  SharedUtils = SharedUtils;

  data: any;
  params: any;
  audioManager: AudioManager;
  audioTextFile: string = "videocall_ring";
  intervalAudio: any;

  acceptIncomingCallAnchorElement : any;

  videoCallURL: string;


  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private deployInfoService: DeployInfoService,
    private coachService: CoachService,
  ) {}

  ngOnInit(): void {
    this.audioManager = new AudioManager(environment.professional_url, this.deployInfoService.getURLCacheParam(), [this.audioTextFile]);
    //TODO cambiar audio, añadir opción de poder loopear un sonido. Añadir silencio al final
    this.intervalAudio = setInterval(() => {
      this.audioManager.play(this.audioTextFile);
    }, 1100);

    this.params = {
      ownerId: this.data.userId,
      title: this.data.sortKey,
      groupId: this.data.groupId
    }
    this.videoCallURL = ChatUtils.getVideoCallUrl(environment.patient_url, this.params);
  }

  ngOnDestroy(): void {
    //TODO: parar audio en vez de eliminar interval
    this.audioManager.stop();
    clearInterval(this.intervalAudio);
  }

  initCall() {
    this._sendCallStatusMessage("ACCEPT_CALL", this.data.sortKey);
    this.coachService.removeFromDOM();
    this.close();
    if((SharedUtils.isIOS() && IOSExerciseService.hasInterface())){
      setTimeout(() => {
        window.location.assign(this.videoCallURL);
      }, 4000);
    }else{
      window.open(this.videoCallURL, "_blank");
    }

  }

  endCall() {
    this._sendCallStatusMessage("REFUSE_CALL", this.data.sortKey);
    this.close();
  }

  close() {
    this.modalService.close();
  }

  getAvatar(user: any) {
    return SharedUtils.getAvatarUrl(user, environment.output_s3_url);
  }

  private _sendCallStatusMessage(callStatus: string, sortKey:string = null) {
    let m: any = {
      "read": false,
      "createDate": moment(),
      "sender": "me",
      "note": this.i18nService.translate("videocall_message_caller_START_CALL"),
      "START_CALL": true
    }
    m[callStatus] = true;
    let item = {
      "callStatus": callStatus
    };

    if (sortKey) item['sortKey'] = sortKey;

    ChatAPI.postCallStatusToChatGroup(this.data.groupId, item)
    .then((result: any) => {})
    .catch((error: any)=>{
      if(error.code == "ECONNABORTED") {
        //this.snackbar.showError("general_connection_error", null, this.isPatient());
      } else if(error.status == 0){
        //this.modal.showNoInternet();
      } else {
        //this.snackbar.showWarning("dialog_error_post_message", null, this.isPatient());
      }
      console.log("ERROR SEND CHAT MESSAGE", error);
    });
  }

}