import { ICoach } from "./coach.interface";

export type CoachMessage = {
  text: string,
  audio: string,
  animation: string
}
export class SharedCoachService{

  coachImplementation: ICoach;

  lastState: any = {};
  state: any = {};
  messages: any;
  messagesLength: any;
  messagesAlternatives: any;

  audioAssetsBaseUrl: string;
  audioAssetsUrl: string;
  languageCode: string;

  cacheUrlParam: string;

  onShowMessageCallback: any;

  DOMReference: any;

  systemMessage: any;
  warningMessage: any;
  controlMessage: any;
  feedbackMessage: any;

  messagesToPlay: CoachMessage[] = [];

  disabled: boolean = false;
  muted: boolean = false;

  audioElement: HTMLAudioElement;

  sayNextTimeout: any;

  constructor(coach: ICoach){
    this.coachImplementation = coach;
  }

  setMessages(messages: any){
    this.messages = messages;
  }

  getMessages(id: string) {
    return this.messages[id];
  }

  init(params: {audioAssetsBaseUrl: string, languageCode: string, cacheUrlParam: string, disabled: boolean, muted: boolean}): void{
    this.audioAssetsBaseUrl = params.audioAssetsBaseUrl;
    this.setLanguageCode(params.languageCode);
    this.cacheUrlParam = params.cacheUrlParam;
    this.disabled = params.disabled;
    this.muted = params.muted || false;

    this.audioElement = document.createElement("audio");

    window.onclick = ()=>{
      if(!this.state.windowClicked) this.setState({windowClicked: true});
    }
  }

  setLanguageCode(languageCode: string): void{
    this.languageCode = languageCode;
    this.audioAssetsUrl = this.audioAssetsBaseUrl + languageCode;
  }

  getDOMReference(): any{
    return this.DOMReference;
  }

  setDOMReference(value: any): void{
    this.DOMReference = value;
  }

  addToDOM(coachComponent: any): void{
    if(!this.getDOMReference()){
      let reference = this.coachImplementation.addToDOM(coachComponent);
      this.setDOMReference(reference);
    }
  }

  removeFromDOM(): void{
    let domReference = this.getDOMReference();
    if (domReference) {
      this.coachImplementation.removeFromDOM(domReference);
      this.setDOMReference(null);
    }
  }

  getState(): any{
    return this.state;
  }

  setState(value: any, skipCheckConditions: boolean = false): void{
    this.lastState = {...this.state};
    let lastStep = this.state.step;

    this.state = {
      ...this.state,
      ...value
    }

    if(this.state.step != lastStep) this.state.lastStep = lastStep;
    //console.log(this.state);

    if(!skipCheckConditions) this.processState(this.state, this.lastState);
  }

  say(params: {key: string, baseUrl?: string, useFirstAlternative?: boolean}): void{
    this.messagesToPlay = [this.getMessageFormatted(params.key, params.useFirstAlternative)];
    this.sayNext(params.baseUrl || this.audioAssetsUrl);
  }

  resetState(): void{
    this.state = {};
  }

  onShowMessage(callback: any): void{
    this.onShowMessageCallback = callback;
  }

  disable(): void{
    this.messagesToPlay = [];
    this.stopAudio();
    this.disabled = true;
  }

  enable(): void{
    this.disabled = false;
  }

  mute(): void{
    this.messagesToPlay = [];
    this.stopAudio();
    this.sayNextTimeout = setTimeout(()=>{
      this.sayNext();
    }, 500);
    this.muted = true;
  }

  unmute(): void{
    this.muted = false;
  }

  clearAllMessages(): void{
    this.messagesToPlay = [];
    this.stopAudio();
    this.sayNext();
  }

  private getAnimation(key: string): string{
    //TODO: Usar animación aleatoria
    return this.messages[key] ? this.messages[key].animation[0] : "talk";
  }

  private sayNext(baseUrl: string = this.audioAssetsUrl): void{
    if(this.sayNextTimeout) clearTimeout(this.sayNextTimeout);

    if(this.disabled) return;

    let next: any;

    if(this.messagesToPlay.length == 0){
      next = {
        text: "",
        animation: "idle"
      }
    }else{
      next = this.messagesToPlay[0];
      this.messagesToPlay.splice(0, 1);
    }

    if(next.audio != null) this.playAudio(baseUrl, next.audio);
    if(next.text != null) this.showMessage(next);
  }

  private showMessage(message: any): void{
    if(this.disabled) return;
    if(this.onShowMessageCallback) this.onShowMessageCallback(message);
  }

  /**
   * Returns an alternative message for a given ID.
   * @param id - The ID of the message to retrieve.
   * @param useFirstAlternative - Whether to always use the first alternative message or choose a random one.
   * @returns An object containing the audio, text, and animation for the message.
   */
  private getAlternativeMessage(id: string, useFirstAlternative: boolean = false){
    let messagesForId = this.messages[id];

    if (messagesForId && messagesForId.length > 0){
      let randomIdx = useFirstAlternative ? 0 : Math.floor(Math.random() * messagesForId.length);
      return messagesForId[randomIdx];
    }else{
      return {
        audio: id,
        text: id,
        animation: "talk"
      };
    }
  }

  private getMessageFormatted(id: string, useFirstAlternative: boolean = false): CoachMessage{
    let message = this.getAlternativeMessage(id, useFirstAlternative);
    let animation = this.getAnimation(message);

    let result: CoachMessage = {
      text: message.text,
      audio: message.audio,
      animation: animation
    }

    return result;
  }

  private getMessagesFromState(state: any, lastState: any): (string | null)[]{

    if(state.windowClicked != true) return [];

    let firstMessage: string;
    let secondMessage: string | null = null;

    switch(state.step){
      case "daily_form_opened":
        if(state.adherence != null){
          let negativeAdherence = state.adherence < 75;

          secondMessage = "coach_login_with_daily_form_";

          if(negativeAdherence) secondMessage += "negative_adherence";
          else secondMessage += "positive_adherence";

          if(state.therapyCompletionPercentage >= 90) secondMessage += "_100";
          else if(state.therapyCompletionPercentage >= 80) secondMessage += "_90";
          else if(state.therapyCompletionPercentage >= 70) secondMessage += "_80";
          else if(state.therapyCompletionPercentage >= 60) secondMessage += "_70";
          else if(state.therapyCompletionPercentage >= 50) secondMessage += "_60";
          else if(state.therapyCompletionPercentage >= 40) secondMessage += "_50";
          else if(state.therapyCompletionPercentage >= 30) secondMessage += "_40";
          else if(state.therapyCompletionPercentage >= 20) secondMessage += "_30";
          else if(state.therapyCompletionPercentage >= 10) secondMessage += "_20";
          else secondMessage += "_10";

        }

        if(!state.firstTime){
          return [this.getBeforeFormsMessage(state), "coach_login_with_daily_form_first_time", secondMessage];
        }else{
          state.firstTime = false;
          return ["coach_salutation_hello", this.getBeforeFormsMessage(state), "coach_login_with_daily_form_first_time", secondMessage];
        }

      case "open_materials_modal":
        return [this.getBeforeFormsMessage(state), "coach_realtime_materials_modal"];
      case "survey_available":
        if(!state.firstTime){
          return [this.getBeforeFormsMessage(state), "coach_survey_available"];
        }else{
          state.firstTime = false;
          return ["coach_salutation_hello", this.getBeforeFormsMessage(state), "coach_survey_available"];
        }
      case "unread_messages":
        if(!state.firstTime){
          return [this.getBeforeFormsMessage(state), "coach_unread_messages"];
        }else{
          state.firstTime = false;
          return ["coach_salutation_hello", this.getBeforeFormsMessage(state), "coach_unread_messages"];
        }
      case "daily_form_already_answered":
        if(!state.firstTime){
          return [this.getBeforeFormsMessage(state), this.getAfterFormsMessage(state)]
        }else{
          state.firstTime = false;
          return ["coach_salutation_hello", this.getBeforeFormsMessage(state), this.getAfterFormsMessage(state)];
        }

      case "daily_form_answered":
        let typeMessage = (state.dailyFormValue == 2 ? "normal" : state.dailyFormValue < 2 ? "negative" : "positive");

        if( state.todayExercises > 0 && !state.allExercisesCompleted){
          firstMessage = "coach_daily_form_answered_" + typeMessage + "_with_exercises";
        }else{
          firstMessage = "coach_daily_form_answered_" + typeMessage;
        }

        return [this.getBeforeFormsMessage(state), firstMessage, this.getAfterFormsMessage(state)];

      case "daily_form_not_answered":
        secondMessage = this.getAfterFormsMessage(state);
        return [this.getBeforeFormsMessage(state), "coach_daily_form_not_answered", secondMessage];

      case "satisfaction_form_opened":
        if(!state.firstTime){
          return [this.getBeforeFormsMessage(state)];
        }else{
          state.firstTime = false;
          return ["coach_salutation_hello", this.getBeforeFormsMessage(state)];
        }

      case "satisfaction_form_answered":
        return [this.getBeforeFormsMessage(state), this.getAfterFormsMessage(state)];

      case "satisfaction_form_not_answered":
        return [this.getBeforeFormsMessage(state), this.getAfterFormsMessage(state)];

      case "realtime_cancel":
        return ["coach_realtime_cancel"]
      case "realtime_exercise_clicked":
        state.firstTime = false;
        return ["coach_realtime_exercise_clicked"]
      case "realtime_preparation":
        if(state.sensorState == "connecting") return ["coach_realtime_preparation_sensor_connection_connecting"];
        else if(state.sensorState == "error") return ["coach_realtime_preparation_sensor_connection_incorrect"];
        else if(state.sensorState == "connected" && state.nextSet && state.sensorReadyToStream) return ["coach_realtime_preparation_sensor_connection_press_next"];
        else if(state.sensorState == "connected" && state.sensorReadyToStream) {
          let result = ["coach_realtime_preparation_sensor_connection_correct"];
          if (state.sensorPosition) result.push("coach_light_sensor_" + state.sensorPosition);
          result.push("coach_realtime_preparation_sensor_connection_press_next");
          return result;
        } else return [];
      case "realtime_preparation_camera":
        if(state.sensorState == "connected"){
          return ["coach_realtime_preparation_camera_connected"];
        }
        return [];
      case "realtime_exercise_instructions":
        return [];
        // if(state.isManual) firstMessage = "coach_realtime_exercise_instructions_without_sensor";
        // else firstMessage = "coach_realtime_exercise_instructions_watch_video";

        // return [firstMessage, "coach_realtime_exercise_instructions_start"];
      case "realtime_exercise":

        if(state.sensorFeedback){
          let messages = ["coach_realtime_sensor_feedback_" + state.sensorFeedback];
          delete state.sensorFeedback;

          return messages;
        }

        if(!state.exerciseFeedback) return [];

        let isDifferent = state.exerciseFeedback != lastState.exerciseFeedback;
        let now = new Date();
        let timeDiff = state.lastFeedbackTime ? now.getTime() - state.lastFeedbackTime.getTime() : 0;

        // Si el mensaje es diferente o es igual pero han pasado más de 5 segundos
        if(isDifferent || !isDifferent && timeDiff > 5000){
          state.lastFeedbackTime = now;

          //TODO: mover lógica del mensaje de notas a otro estado?

          let showNoteMessage = false;

          if(state.isNote && !state.isMobile){
            if(state.isManual){
              showNoteMessage = state.exerciseFeedback == "start_exercise" && state.firstSet;
            }else{
              showNoteMessage = state.exerciseFeedback == "start_test";
            }
          }

          if(showNoteMessage){
            return ["coach_message_physiotherapist_in_exercise"];
          }else{
            return ["coach_realtime_signal_assessment_" + state.exerciseFeedback];
          }
        }else{
          return [];
        }
      case "realtime_finish":
        if (state.isManual) {
          if (state.exerciseTimeSlow) firstMessage = "coach_realtime_finish_slow_without_sensor";
          else if (state.exerciseTimeFast) firstMessage = "coach_realtime_finish_fast_without_sensor";
          else firstMessage = "coach_realtime_finish_ok_4";
        } else {
          if (state.exerciseScore > 80) firstMessage = "coach_realtime_finish_ok_5";
          else if (state.exerciseScore > 60) firstMessage = "coach_realtime_finish_ok_4";
          else if (state.exerciseScore > 40) firstMessage = "coach_realtime_finish_ok_3";
          else if(state.exerciseScore > 20) firstMessage = "coach_realtime_finish_ok_2";
          else firstMessage = "coach_realtime_finish_ok_1";
        }
        if (state.preLastSet) {
          return [firstMessage, "coach_realtime_wait_next_set_last_one"];
        } else if (state.lastSet) {
          return [firstMessage, "coach_realtime_rate_exercise"];
        } else {
          return [firstMessage, "coach_realtime_wait_next_set"];
        }
      case "realtime_rate_exercise_pain":
          return ["coach_realtime_rate_exercise_pain"]
      case "realtime_see_instructions":
        return ["coach_realtime_see_instructions"]
      case "realtime_first_zero":
        return ["coach_realtime_first_zero"]
      case "realtime_half_exercise": // Mensaje cuando llega a la mitad del tiempo en EXERCISE_MANUAL
        return ["coach_realtime_signal_assessment_half_exercise"]
      case "realtime_first_quarter_exercise": // Mensaje cuando llega al primer cuarto del tiempo en EXERCISE_MANUAL
        return ["coach_realtime_signal_assessment_first_quarter_exercise"]
      case "realtime_third_quarter_exercise": // Mensaje cuando llega al tercer cuarto del tiempo en EXERCISE_MANUAL
        return ["coach_realtime_signal_assessment_third_quarter_exercise"]
      case "calibration_preparation":
        if(state.sensorState == "connecting") return ["coach_realtime_preparation_sensor_connection_connecting"];
        else if(state.sensorState == "error") return ["coach_realtime_preparation_sensor_connection_incorrect"];
        else if(state.sensorState == "connected") return ["coach_realtime_preparation_sensor_connection_correct", "coach_calibration_preparation_press_calibrate"];
        else return [];
      case "calibration_calibrating":
        return ["coach_calibration_calibrating"];
      case "calibration_calibrating_dont_move":
        return ["coach_calibration_calibrating_dont_move"];
      case "calibration_finished":
        return ["coach_calibration_finished"];
      case "calibration_error":
        return ["coach_calibration_error"];
      case "calibration_worse_calibration":
        return ["coach_calibration_worse_calibration"];
    }

    //this.setState("lastStep", step);

    return [];
  }

  private getBeforeFormsMessage(state: any): string | null{
    if(state.allFormsAnswered){
      delete state.allFormsAnswered;
      return "coach_questionnaires";
    }else if(state.exerciseCancelled){
      delete state.exerciseCancelled;
      return "coach_exercise_cancelled";
    }else if(state.inPain){
      delete state.inPain;
      return "coach_after_pain_selection_6";
    }

    return null;
  }

  private getAfterFormsMessage(state: any): string | null{
    if (state.unreadMessages) {
      return "coach_unread_messages";
    } else if (state.surveyAvailable) {
      return "coach_survey_available";
    } else {
      // if(state.cycleEnd && !state.allExercisesCompleted){
      //   return "coach_exercises_for_today_continue_cycle_end";
      // }else
      if((state.inProgressExercises > 0 || state.doneExercises > 0) && state.pendingExercises > 0){
        if(state.pendingExercises == 1){
          return "coach_exercises_for_today_continue_last_one";
        }else{
          return "coach_exercises_for_today_continue";
        }
      }else{
        if(state.pendingExercises > 0){
          return "coach_exercises_for_today";
        } else {
          if (state.todayExercises > 0) {
            if (state.firstTimeShowExercisesCompletedToday) {
              if (state.achievements && state.achievements.length > 0) {
                if (state.achievements.length == 1) {
                  return "coach_" + state.achievements[0];
                } else if(state.achievements.length > 1) {
                  return "coach_more_than_one_achievements";
                }
              } else {
                return "coach_realtime_finished_today_exercises";
              }
            } else {
              return null;
            }
          } else {
            return !state.exercisesByDate || state.exercisesByDate == "" ? "coach_no_exercises" : "coach_no_exercises_for_today";
          }
        }
      }
    }

    return null;
  }

  private processState(state: any, lastState: any): void{

    let messages = this.getMessagesFromState(state, lastState).filter(message=>message != null);
    let messageType = this.state.messageType;

    //console.log("messages", messages);

    if(messages.length > 0){
      switch(messageType){
        case null:
          this.systemMessage = messages;
          this.feedbackMessage = null;
          this.controlMessage = null;
          this.warningMessage = null;
          //TODO: ???
          //this.messagesToPlay = [];
          break;
        case "system":
          this.systemMessage = messages;
          this.feedbackMessage = null;
          break;
        case "warning":
          this.warningMessage = messages;
          this.feedbackMessage = null;
          break;
        case "control":
          this.controlMessage = messages;
          this.feedbackMessage = null;
          break;
        case "feedback":
          this.feedbackMessage = messages;
          break;
      }

      this.messagesToPlay = this.decideMessagesByPriority();
      this.sayNext();
    }

    this.state.messageType = null;
  }

  private decideMessagesByPriority(): CoachMessage[]{
    let message = null;

    if(this.systemMessage){
      message = this.systemMessage;
      this.systemMessage = null;
    }else if(this.warningMessage){
      message = this.warningMessage;
      this.warningMessage = null;
    }else if(this.controlMessage){
      message = this.controlMessage;
      this.controlMessage = null;
    }else if(this.feedbackMessage){
      message = this.feedbackMessage;
      this.feedbackMessage = null;
    }

    return message.map((message: any)=>{
      return this.getMessageFormatted(message);
    });
  }

  private getAudioURLFromKey(baseUrl: string, key: string){
    return baseUrl + "/" + key + ".mp3" + "?v=" + this.cacheUrlParam;
  }

  private playAudio(baseUrl: string, key: string): void{
    if(this.disabled) return;

    if(this.muted){
      this.sayNextTimeout = setTimeout(()=>{
        this.stopAudio();
        this.sayNext();
      }, 1500);
    }else{
      this.stopAudio();

      let url = this.getAudioURLFromKey(baseUrl, key);
      this.audioElement.setAttribute("src", url);

      this.audioElement.play();

      this.audioElement.onended = ()=>{
        this.sayNextTimeout = setTimeout(()=>{
          this.sayNext();
        }, 500);
      }

    }
  }

  private stopAudio(): void{
    if(!this.audioElement) return;
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
  }

}