<div class="modal-header">
  <h4 *ngIf="!installing" class="modal-title">{{ 'modal_realtime_pending_install_title' | translate }}</h4>
  <h4 *ngIf="installing" class="modal-title">{{ 'modal_realtime_installing_title' | translate }}</h4>
  <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column justify-content-center align-items-center pb-0" style="width: 500px">
  <span *ngIf="!installing" class="text-left">{{ 'modal_realtime_pending_install_body' | translate }}</span>
  <span *ngIf="!installing" class="mt-3 text-left w-100" [innerHTML]="bodyTranslation"></span>
  <rehub-spinner *ngIf="installing" size="3" class="mt-3 mb-4 mx-auto"></rehub-spinner>
</div>

<div class="border-0 pt-0 d-flex flex-row pb-3 justify-content-center align-items-center">
  <button *ngIf="!installing" type="button" class="btn btn-primary text-nowrap mr-4" (click)="onDownloadPressed()">{{"modal_realtime_pending_install_download" | translate}}</button>
  <button *ngIf="!installing" type="button" class="btn btn-danger text-nowrap" (click)="onSkipDownloadPressed()">{{"modal_realtime_pending_install_skip_download" | translate}}</button>

  <button *ngIf="installing || (data && data.showCancel)" type="button" class="btn btn-danger text-nowrap ml-2" (click)="onCancelPressed()">{{"general_cancel" | translate}}</button>
</div>

<div class="modal-footer flex-row justify-content-start">
  <span style="font-size: 13px;">
    {{ 'modal_realtime_help_footer' | translate }}
    <a style="font-size: 13px;" class="cursor-pointer text-primary" [href]="supportUrl" target="_blank">{{ 'general_here' | translate }}</a>
  </span>
</div>