export class Confetti {

  maxParticleCount: number = 150; //set max confetti count
  particleSpeed: number = 2; //set the particle animation speed
  startConfetti: any; //call to start confetti animation
  stopConfetti: any; //call to stop adding confetti
  toggleConfetti: any; //call to start or stop the confetti animation depending on whether it's already running
  removeConfetti: any; //call to stop the confetti animation and remove all confetti immediately

  colors: string[] = ["DodgerBlue", "OliveDrab", "Gold", "Pink", "SlateBlue", "LightBlue", "Violet", "PaleGreen", "SteelBlue", "SandyBrown", "Chocolate", "Crimson"];
  particles: any[] = [];
  animationTimer: any;
  waveAngle: number = 0;
  streamingConfetti: boolean = false;

  resetParticle(particle: any, width: any, height: any) {
    particle.color = this.colors[(Math.random() * this.colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  }

  startConfettiInner() {
    var width = window.innerWidth;
    var height = window.innerHeight;
    window.requestAnimationFrame((callback: any) => {
      window.setTimeout(callback, 16.6666667);
    });
    var canvas: any = document.getElementById("confetti-canvas");
    if (canvas === null) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("id", "confetti-canvas");
      canvas.setAttribute("style", "display:block;z-index:999999;pointer-events:none; position:fixed; top:0px;");
      document.body.appendChild(canvas);
      canvas.width = width;
      canvas.height = height;
      window.addEventListener("resize", function () {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      }, true);
    }
    var context = canvas.getContext("2d");
    while (this.particles.length < this.maxParticleCount)
      this.particles.push(this.resetParticle({}, width, height));
    this.streamingConfetti = true;
    if (!this.animationTimer) {
      let _this = this;
      (function runAnimation() {
        context.clearRect(0, 0, window.innerWidth, window.innerHeight);
        if (_this.particles.length === 0)
          _this.animationTimer = null;
        else {
          _this.updateParticles();
          _this.drawParticles(context);
          _this.animationTimer = window.requestAnimationFrame(runAnimation);
        }
      })();
    }
  }

  stopConfettiInner() {
    this.streamingConfetti = false;
  }

  removeConfettiInner() {
    this.stopConfetti();
    this.particles = [];
  }

  toggleConfettiInner() {
    if (this.streamingConfetti)
      this.stopConfettiInner();
    else
      this.startConfettiInner();
  }

  drawParticles(context: any) {
    var particle;
    var x;
    for (var i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      context.beginPath();
      context.lineWidth = particle.diameter;
      context.strokeStyle = particle.color;
      x = particle.x + particle.tilt;
      context.moveTo(x + particle.diameter / 2, particle.y);
      context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
      context.stroke();
    }
  }

  updateParticles() {
    var width = window.innerWidth;
    var height = window.innerHeight;
    var particle;
    this.waveAngle += 0.01;
    for (var i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      if (!this.streamingConfetti && particle.y < -15)
        particle.y = height + 100;
      else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(this.waveAngle);
        particle.y += (Math.cos(this.waveAngle) + particle.diameter + this.particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (this.streamingConfetti && this.particles.length <= this.maxParticleCount)
          this.resetParticle(particle, width, height);
        else {
          this.particles.splice(i, 1);
          i--;
        }
      }
    }
  }

}