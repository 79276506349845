import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rehub-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: { class: 'mb-2 btn-group' },
})
export class DropdownComponent implements OnInit {
  @Input() items: any[];
  @Input() value: any;
  @Output() selectionChange = new EventEmitter();

  selected: any;

  constructor() {}

  ngOnInit(): void {
    let itemSelected = this.items.find((value: any) => value.id !== null && value.id === this.value);
    this.selected = itemSelected;
  }

  selectOption(event: any, selected: any) {
    this.selected = selected;
    event.target.parentNode.classList.remove('show');
    this.selectionChange.emit(selected);
  }
}
