import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CredentialsService } from '../auth';
import { Permission } from '@rehub-shared/utils/permissions';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let isSelfService = this.credentialsService.isSelfService();

    if(!this.credentialsService.isAuthenticated() || !this.credentialsService.isRefreshTokenValid() || !this.credentialsService.isPatient()){
      this.credentialsService.clearCredentials();
      this.router.navigate(["/login"]);

      return false;
    }

    if (this.credentialsService.isExpired()){
      this.router.navigate(["/expired"]);

      return false;
    }

    if (!isSelfService && this.credentialsService.isTherapyFinished()){
      this.router.navigate(["/therapyFinished"]);

      return false;
    }

    if(this.hasTermsAndConditions()){
      // Si está pendiente y no está en la ruta adecuada redirigir
      if(state.url != "/terms-and-conditions"){
        this.router.navigate(["/terms-and-conditions"]);

        return false;
      }

      return true;
    }

    if(this.hasUpdateProfile()){
      if(state.url != "/update-profile"){
        this.router.navigate(["/update-profile"]);

        return false;
      }

      return true;
    }

    if(this.credentialsService.passwordResetRequired()){
      // Si tiene que cambiar la contraseña y no está en la ruta adecuada redirigir
      if(state.url != "/set-password"){
        this.router.navigate(["/set-password"]);

        return false;
      }

      return true;
    }
    if(this.isPendingPharmaForm()){
      if(state.url != "/pharma-form"){
        this.router.navigate(["/pharma-form"]);

        return false;
      }

      return true;
    }

    if (this.credentialsService.shouldShowWelcomeTutorial() && !this.isProviderWellwo() && !this.credentialsService.isAssegur()) {
      if (!state.url.includes("/welcome")) {
        this.router.navigate(["/welcome"]);

        return false;
      }

      return true;
    }

    return true;
  }

  private isProviderWellwo() {
    return this.credentialsService.checkPermission(Permission.SS_config_wellwo)
  }
  private isProviderPharma() {
    return this.credentialsService.checkPermission(Permission.SS_config_pharma)
  }

  private hasTermsAndConditions() {
    return this.credentialsService.isPending() && !this.isProviderWellwo();
  }

  private hasUpdateProfile() {
      return this.credentialsService.isMissingUserInfo() && !this.isProviderWellwo() && !this.credentialsService.isAssegur();
  }
  private isPendingPharmaForm() {
    return this.isProviderPharma() && this.credentialsService.shouldShowPharmaForm();
}


}
