import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

import { CredentialsService } from './credentials.service';

import * as moment from 'moment';
import 'moment-timezone';
import { I18nService } from '../../i18n/i18n.service';
import { ThemeService } from '../service/theme.service';
import { SharedAuthenticationService } from "@rehub-shared/service/authentication.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends SharedAuthenticationService {

  constructor(
    credentialsService: CredentialsService,
    router: Router,
    i18nService: I18nService,
    themeService: ThemeService
  ) {
    super(isDevMode(), moment, environment, credentialsService, router, i18nService, themeService);
  }

  loadPatientManifest(patientlang: string) {
    this.loadManifest(patientlang);
  }

  loadManifest(patientlang: string) {

    var manifestURL = "/manifest/" + `${environment.manifest_version}` + "/manifest-" + `${patientlang}` + ".json";

    fetch(manifestURL)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
        const stringManifest = JSON.stringify(data);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const localURL = URL.createObjectURL(blob);

        // Dynamically create a link element to load the manifest
        var manifestLink = document.createElement("link");
        manifestLink.rel = "manifest";
        manifestLink.href = localURL;

        // Replace existing manifest with the new one
        var existingManifest = document.getElementById("manifest-placeholder");
        existingManifest.parentNode.replaceChild(manifestLink, existingManifest);
      })
      .catch(error => {
          console.error('There was a problem fetching the manifest:', error);
      });
  }
}
