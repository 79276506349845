import { ExerciseService } from '@rehub-shared/measurement-program/exercise.service';

export class WebsocketExerciseService extends ExerciseService{

  name: string = "websocket";

  private subject: any;
  private websocket: WebSocket;
  
  public init(){
    console.log("init websocket service");
    this.websocket = this.createWebSocket();
    this.subject = this.createSubject(this.websocket);
    return this.mapSubject(this.subject);
  }

  public destroy(){
    console.log("destroy websocket service");
    if (this.websocket) this.websocket.close();
    if (this.subject) {
      this.subject.complete();
      this.subject = null;
    }
  }
  
  public send(command: string, value: any = null){
    //console.log("send websocket service", command, value);
    let data = <any>{ command: command };
    if(value) data.value = value;
    if(this.subject) this.subject.next(data);
  }

  public receive(data: any){};
  
  private createWebSocket(): WebSocket{
    let url = "ws://127.0.0.1:4200";

    let websocketExerciseServiceURL = localStorage.getItem("websocketExerciseServiceURL");
    if(websocketExerciseServiceURL) url = "ws://" + websocketExerciseServiceURL;

    return new WebSocket(url);
  }

  private createSubject(websocket: WebSocket): any{

    let observable = this.rxjs.createObservable((obs: any) => {
      websocket.onmessage = obs.next.bind(obs);
      websocket.onerror = obs.next.bind(obs);
      websocket.onclose = obs.next.bind(obs);

      return websocket.close.bind(websocket);
    });

    let observer = {
      next: (data: Object) => {
        if (websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify(data));
        }
      }
    };

    return this.rxjs.createSubject(observer, observable);
  }

  private mapSubject(subject: any){
    return this.rxjs.mapSubject(subject, true, (response: MessageEvent) =>{
      if (response.type == "message" && response.data) {
        let data = response.data;
        return {
          type: response.type,
          message: JSON.parse(data)
        };
      } else if (response.type == "close") {
        return {
          type: response.type,
          message: null,
        };
      }
    });
  }
}
