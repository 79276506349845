import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable, Subject, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class WebsocketNotificationService {

  private subject: Subject<MessageEvent>;
  private ws: WebSocket;

  public connect(ownerId:string): Subject<MessageEvent> {
    const url = environment.notif_ws_server_url + "?ownerId=" + ownerId;
    if (!this.subject) this.subject = this.create(url);
    return this.subject;
  }

  public disconnect() {
    if (this.ws) this.ws.close();
    if (this.subject) {
      this.subject.complete();
      this.subject = null;
    }
  }

  private create(url:string): Subject<MessageEvent> {
    let ws = new WebSocket(url);
    this.ws = ws;

    let observable: Observable<MessageEvent> = new Observable((observer) => {
      ws.onmessage = observer.next.bind(observer);
      ws.onerror = observer.next.bind(observer);
      ws.onclose = observer.next.bind(observer);

      return ws.close.bind(ws);
    })

    let observer: Observer<MessageEvent> = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      error: () => {},
      complete: () => {}
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

}