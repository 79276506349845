import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SharedCredentialsService } from "@rehub-shared/service/credentials.service";

@Injectable({
  providedIn: 'root',
})
export class CredentialsService extends SharedCredentialsService {

  constructor() {
    super(moment);
  }
}