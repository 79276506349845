import { ControllerRC } from "../controller-rc.interface";

export type WSAction = "host_message" | "client_message";
/**
 * Crea y gestiona el WebSocket que se va utilizar entre ReHub y el mando
 * 
 * @public
 */
export class WSRemoteController implements ControllerRC {

  private ws: WebSocket | null;
  private action: WSAction;
  private idWS: string;
  private onReceive: Function;
  private urlWS: string;

  constructor(action: WSAction, idWS: string, urlWS: string) {
    this.action = action;
    this.idWS = idWS;
    this.urlWS = urlWS;
  }

  init(onReceive: Function): Promise<boolean> {
    this.onReceive = onReceive;
    return this.initWS(this.urlWS, this);
  }

  close(): void {
    if (this.ws) this.ws.close();
  }

  send(data: any): void {
    if (this.ws) {
      let wsData = this.generateJsonWS(data);
      this.ws.send(JSON.stringify(wsData));
    }
  }

  receive(data: any): void {
    if (this.onReceive) {
      let dataProcesed = this.processResponse(data);
      if (!dataProcesed.ping) this.onReceive(dataProcesed);
    }
  }

  /**
   * Inicializa el WebSocket (o lo recupera) e introduce en los diferentes estados las funciones
   * necesaria para poder controlarlo.
   * 
   * @param url 
   * @param controller Objeto controller donde se le pasara el mensaje que se reciba para procesarlo
   * @returns Una promesa para saber si se ha creado correctmente el WS o no
   */
  private initWS(url: string, controller: ControllerRC): Promise<boolean> {
    let wsController = this;
    return new Promise<boolean>((resolve, reject) => {
      if (!this.ws) {
        let ws = new WebSocket(url);
        ws.onopen = () => {
          wsController.ws = ws;
          wsController.ping();
          resolve(true);
        };
        ws.onerror = (err: any) => {
          reject(err);
        };
        ws.onmessage = (message: any) => {
          controller.receive(message.data);
        };
        ws.onclose = () => {
          wsController.ws = null;
        }
      } else {
        if (wsController.ws) wsController.ws.onmessage = (message: any) => {
          controller.receive(message.data);
        };
        resolve(true);
      }
    });
  }

  private generateJsonWS(data: any) {
    return {
      action: this.action,
      message: {
        rehubId: this.idWS,
        data: data
      }
    };
  }

  private processResponse(data: any) {
    return JSON.parse(data);
  }

  private ping(): void {
    if (this.ws) {
      let wsData = this.generateJsonWS({ping: true});
      this.ws.send(JSON.stringify(wsData));
    }
  }

}