import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CredentialsService } from '@app/@core/auth';
import { CalendarUtils } from '../calendar-utils';

import * as moment from 'moment';
import { I18nService } from '@app/i18n';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { RecordAPI } from '@rehub-shared/api';

@Component({
  selector: 'rehub-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss'],
  host: { class: 'rounded shadow bg-white d-block p-2 pb-3 cy-calendar-month' },
})
export class CalendarMonthComponent implements OnInit {

  @Output() sendSelectedDay = new EventEmitter();
  @Input() selectedDay:any;
  CalendarUtils = CalendarUtils;

  actualMonth: any;
  weeks: any;
  dayNames: any = [];

  indexSelected: any = 0;

  exercisesByDate: any;

  selectedMonth: any = 0;

  maxExercisesOfWeek: any = 0;

  constructor(
    private credentialsService: CredentialsService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {
    let today = moment();
    if (!this.selectedDay) {
      this.selectedDay = moment();
    }
    this.dayNames = CalendarUtils.getDaysNames();
    this.selectMonth(this.selectedMonth);
    this.getExercises(today);
  }

  selectMonth(selectedMonth: any, addExercises: boolean = false) {
    moment.locale(this.i18nService.getLanguageCode());
    let today = moment();
    this.actualMonth = SharedUtils.capitalize(today.add(selectedMonth, 'month').format('MMMM YYYY'));
    let weeks = CalendarUtils.getWeeksMonth(today);
    this.weeks = weeks;

    if(addExercises) {
      let max = 0;
      this.maxExercisesOfWeek = 0;
      this.weeks.forEach((week: any) => {
        week.forEach((day: any) => {
          let key = day.date.format("YYYY-MM-DD") + "T00:00:00Z";
          day.exercises = this.exercisesByDate[key];
          if(day.exercises > max) max = day.exercises;
        });
        this.maxExercisesOfWeek += max;
      });
    }
  }

  selectDay(day: any) {
    this.sendSelectedDay.emit(day);
    this.selectedDay = day;
  }

  nextMonth() {
    this.selectedMonth++;
    this.selectMonth(this.selectedMonth, true);
    this.getExercises(moment().add(this.selectedMonth, 'month'));
  }

  previousMonth() {
    this.selectedMonth--;
    this.selectMonth(this.selectedMonth, true);
    this.getExercises(moment().add(this.selectedMonth, 'month'));
  }

  private getExercises(date: any) {
    RecordAPI.getMyRoutineByDays({
      patientId: this.credentialsService.getCredentials().sub,
      date: date.startOf('month').format("YYYY-MM-DD"),
      days: date.daysInMonth()
    }).then((result: any) => {
      this.exercisesByDate = result;
      this.selectMonth(this.selectedMonth, true);
    });
  }
}
