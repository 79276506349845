export type Theme = {
  light: string,
  brand: string,
  primary: string,
  secondary: string,
  spinner?: string,
  name?: string
}
export class SharedThemeService{

  constructor(){
    (window as any).themeService = this;
  }

  private currentTheme: string = "default";

  // TODO: cambiar nombres de variables
  private themes: Map<string, Theme> = new Map([
    ["default", { name: "default", light: "#D9F3F6", brand: "#03B3C9", primary: "#008899", secondary: "#2B2E38" }],
    ["blue", { name: "blue", light: "#E6F0F7", brand: "#80BBE6", primary: "#83B6D5", secondary: "#2B2E38" }],
    ["pink", { name: "pink", light: "#F0BBD4", brand: "#ED68A8", primary: "#DF4171", secondary: "#C5174C" }],
    ["vh", { name: "vh", light: "#7BACFC", brand: "#5986FC", primary: "#264EFC", secondary: "#005682" }],
    ["green", { name: "green", light: "#D5E2C3", brand: "#A9C97D", primary: "#66884B", secondary: "#2B2E38" }],
    ["tp", { name: "tp", light: "#E4F4EA", brand: "#B6D0C6", primary: "#0A3425", secondary: "#005682" }],
    ["orange", { name: "orange", light: "#EEC593", brand: "#E98205", primary: "#E95805", secondary: "#2B2E38" }],
    ["red", { name: "red", light: "#F2CAC6", brand: "#F28E84", primary: "#E3493A", secondary: "#B60007" }],
    ["brown", { name: "brown", light: "#D9C4B1", brand: "#B48050", primary: "#9D5A30", secondary: "#5C3524" }],
    ["grey", { name: "grey", light: "#E4E4E4", brand: "#CECECE", primary: "#5A5A5A", secondary: "#373737" }],
    ["violet", { name: "violet", light: "#C8A1D0", brand: "#492365", primary: "#470A68", secondary: "#C8A1D0" }],
    ["light_green", { name: "light_green", light: "#e6fff0", brand: "#00e65c", primary: "#00b549", secondary: "#003625" }],
    ["light_violet", { name: "light_violet", light: '#b8acfc', brand: '#887BD1', primary: '#665aad', secondary: '#7D70C5' }],
    ["ww", { name: "ww", light: '#4EF4D4', brand: '#3BB79F', primary: '#2C6C75', secondary: '#4EF4D4' }],
    ["rj", { name: "rj", light: "#009de0", brand: "#009de0", primary: "#1c5a7a", secondary: "#425563" }],
    ["vv", { name: "vv", light: "#FF8F1C", brand: "#FF6900", primary: "#FF6900", secondary: "#FF8F1C" }],
    ["sf", { name: "sf", light: "#f0f0f8", brand: "#001698", primary: "#001698", secondary: "#f0f0f8" }],
    ["tb", { name: "tb", light: "#f0f9f1", brand: "#066cb5", primary: "#408627", secondary: "#f0f9f1" }],
    ["w1", { name: "w1", light: "#c5e4e6", brand: "#286f72", primary: "#193435", secondary: "#286f72" }],
    ["w2", { name: "w2", light: "#fffdbf", brand: "#ffe700", primary: "#DF9F00", secondary: "#ffe700" }],
    ["w3", { name: "w3", light: "#c4d1e6", brand: "#3476e6", primary: "#234F9A", secondary: "#3476e6" }],
    ["w4", { name: "w4", light: "#fffbc3", brand: "#ffe217", primary: "#F3AC16", secondary: "#ffe217" }],
    ["w5", { name: "w5", light: "#b0dad2", brand: "#00dab2", primary: "#004236", secondary: "#00dab2" }],
    ["haria", { name: "haria", light: "#968fbf", brand: "#665aad", primary: "#9381f9", secondary: "#665aad" }],
    ["reigJofre", { name: "reigJofre", light: "#afaed9", brand: "#06038D", primary: "#0703b3", secondary: "#06038d85" }],
    ["cc", { name: "cc", light: "#21cdc0", brand: "#21cdc0", primary: "#000000", secondary: "#283a6a" }],
    ["124", { name: "124", light: "#f45a8e", brand: "#A81B4B", primary: "#221815", secondary: "#A81B4B" }],
    ["w6", { name: "w6", light: "#e6f5f7", brand: "#84cdd6", primary: "#012f53", secondary: "#84cdd6" }],
    ["iac", { name: "iac", light: "#e9efe1", brand: "#94c120", primary: "#706e6f", secondary: "#94c120" }],
    ["fso", { name: "fso", light: "#c2d6eb", brand: "#006699", primary: "#00728d", secondary: "#006699" }],
    ["iaci", { name: "iaci", light: "#dadada", brand: "#009877", primary: "#003a41", secondary: "#009877" }],
    ["asgr", { name: "asgr", light: "#d9f3f6", brand: "#56b5d6", primary: "#0079a3", secondary: "#56b5d6" }],
    ["asgh", { name: "asgh", light: "#d9f3f6", brand: "#0079a3", primary: "#56b5d6", secondary: "#0079a3" }],
    ["asi", { name: "asi", light: "#3798bc", brand: "#3098cf", primary: "#004C97", secondary: "#3098cf" }],
  ]);

  getCurrentTheme(): Theme | undefined{
    return this.getTheme(this.currentTheme);
  }

  getCurrentThemeName(): string{
    return this.themes.has(this.currentTheme) ? this.currentTheme : "default";
  }

  getTheme(name: string): Theme | undefined{
    return this.themes.has(name) ? this.themes.get(name) : this.themes.get("default");
  }

  getThemes(): Map<string, Theme>{
    return this.themes;
  }

  getThemeList(): Theme[]{
    return [...this.themes.values()];
  }

  getThemesNames(): string[]{
    return [...this.themes.keys()];
  }

  setTheme(themeName : string, themeConfig : Theme | null){
    if (themeConfig) {
      this.setThemeConfig(themeConfig); // Establece el tema con los valores de los colores del formulario
    } else if(themeName) {
      this.setThemeName(themeName); // Establece el tema con los valores del tema seleccionado
    } else {
      this.setThemeName("default"); // Establece el tema con los valores del tema predefinido
    }
  }

  setThemeName(name: string): void{
    let theme2 = {...this.getTheme(name)};
    if(!theme2) return;

    theme2.spinner = theme2.primary;

    this.currentTheme = name;

    Object.keys(theme2).forEach((key: string)=>{
      document.documentElement.style.setProperty("--color-" + key, (theme2 as any)[key]);
    });
  }


  setThemeConfig(theme: Theme): void{

    Object.keys(theme).forEach((k) => !theme[k] && delete theme[k]);
    let defaultTheme = this.getTheme("default");
    theme = Object.assign(defaultTheme, theme);

    theme.spinner = theme.primary;

    this.currentTheme = "custom";

    Object.keys(theme).forEach((key: string)=>{
      document.documentElement.style.setProperty("--color-" + key, (theme as any)[key]);
    });
  }

  getVariable(name: string): string{
    return getComputedStyle(document.documentElement).getPropertyValue("--color-" + name);
  }

}