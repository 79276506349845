

<header class="text-center mb-3 d-flex flex-row align-items-center align-content-center pl-2 pr-2">
  <rehub-icon name="arrow" rotate="270" class="pointer" (click)="previousMonth()" size="16"></rehub-icon>
  <h2 class="flex-fill">{{actualMonth}}</h2>
  <rehub-icon name="arrow" rotate="90" class="pointer" (click)="nextMonth()" size="16"></rehub-icon>
</header>

<div>
  <table style="width:100%" class="text-center">
    <tr>
      <th *ngFor="let day of dayNames">
        <p class="text-gray-3 text-uppercase">
          {{day}}
        </p>
      </th>
    </tr>
    <ng-container *ngFor="let week of weeks">
      <tr>
        <ng-container *ngFor="let day of week">
          <td class="pointer" (click)="selectDay(day.date)" *ngIf="day.isOnMOnth">
            <p class="d-inline-flexday day dayMonth" [ngClass]="{'day-selected': CalendarUtils.isSameDate(day.date, selectedDay)}">
              {{day.date.format('DD')}}
            </p>
          </td>
          <td *ngIf="!day.isOnMOnth">
            <p class="d-inline-flexday day text-gray-3">
              {{day.date.format('DD')}}
            </p>
          </td>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let day of week">
          <td class="pointer" (click)="selectDay(day.date)" *ngIf="day.isOnMOnth">
            <rehub-icon name="shape_circle" [ngClass]="{'invisible': day.exercises == null || day.exercises <= 0}" size="12"
              class="text-brand fade-in mx-auto my-3 mx-2" [title]="day.exercises"></rehub-icon>
          </td>
          <td *ngIf="!day.isOnMOnth">
            <rehub-icon name="shape_circle" [ngClass]="{'invisible': day.exercises == null || day.exercises <= 0}" size="10"
              class="text-brand fade-in mx-auto" [title]="day.exercises"></rehub-icon>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </table>
</div>
