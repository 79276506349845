import { ExerciseService } from './exercise.service';

export class IOSExerciseService extends ExerciseService{

  name: string = "ios";

  subject: any;

  public init(): any{
    this.subject = this.rxjs.createEmptySubject();
    console.log("init ios service");
    (window as any).IOSExerciseService = this;

    this.commandActionReady();
    return this.createObservable(this.subject);
  }
  
  public destroy(){
    console.log("destroy ios service");
  }
  
  public send(command: string, value: any = null){
    if(!IOSExerciseService.hasInterface()) return;

    let i = this.getInterface();

    let data = <any>{ command: command };
    if(value) data.value = value;

    i.postMessage(JSON.stringify(data));
  }
  
  public receive(data: any){
    if(!this.subject) return;
    this.subject.next(data);
  }

  private getInterface(){
    return (window as any).webkit.messageHandlers.ios;
  }

  public static hasInterface(){
    return (window as any).webkit != null && (window as any).webkit.messageHandlers != null && (window as any).webkit.messageHandlers.ios != null;
  }

  private createObservable(subject: any){
    return this.rxjs.mapSubject(subject, false, (data: any)=>{
      return {
        type: "message",
        message: data
      };
    }); 
  }

}
