import { API } from "./api";

export class SensorAPI extends API {

  static getSensorKitsByOwner(ownerId: string, unassigned: boolean = false, sensorType: string | null = null){
    const url = `${this.getBaseUrl()}/sensors/owner/${ownerId}`;

    let params: any = {};

    if(unassigned != false) params.unassigned = unassigned;
    if(sensorType != null) params.sensorType = sensorType;

    return this.get(url, params, {timeout: 20000});
  }

  static getSensorCalibration(mac: string) {
    const url = `${this.getBaseUrl()}/calibration/${mac}`;
    return this.get(url, {});
  }

  static updateSensor(sensorId: any, patientId: any, action: any) {
    const url = `${this.getBaseUrl()}/sensor/assign/${sensorId}/${patientId}/${action}`;
    return this.post(url, {});
  }

  static getSensorsByOwner(ownerId: string, assignFree: boolean = true) {
    const url = `${this.getBaseUrl()}/sensors/owner/${ownerId}`;
    return this.get(url, {assignFree}, {timeout: 20000});
  }
}