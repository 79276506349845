import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormService } from '@core/service/form.service';
import { I18nService } from '@app/i18n/i18n.service';
import { ToastService } from '@core/service';
import { Utils } from '@app/utils';
import { Router } from '@angular/router';
import { CoachService } from '@app/@core/service/coach.service';
import { FormName } from '@rehub-shared/service/form.service';
import { TherapyAPI } from '@rehub-shared/api';

@Component({
  selector: 'rehub-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  Utils = Utils;

  @Input() forms: any;
  @Input() patientId: any;
  @Output() onAnswered = new EventEmitter();

  isComplete: boolean = false;
  items: any[] = [];
  survey: any;
  checkboxItems: any[] = [];
  formDates: any;
  percentageAnswered: string = "0";
  formId: any;
  scoreOrder: any;
  diagnosisId: string;
  spiderData: any;
  selectedForm: any;
  selectedDate: any;
  formsComplets: any = {};
  languageKey = this.i18nService.getLanguageCode();

  answers: any = {};
  answered: number = 0;
  answersICIQSF = [{ init: 1, final: 7 }, { init: 7, final: 11 }, { init: 11, final: 12 }, { init: 12, final: 20 }];
  totalAnswers: number = 0;

  saving = false;

  constructor(
    public formService: FormService,
    private i18nService: I18nService,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private router: Router,
    private coachService: CoachService
  ) { }

  ngOnInit() {
    //this.languageKey = (this.languageKey !== "pt") ? ((this.languageKey === "ca" || this.languageKey === "esMX") ? "es" : this.languageKey) : "en";

    if (!this.forms) {
      this.isComplete = true;
      return;
    }

    this.init();
  }

  init() {
    let keys = Object.keys(this.forms);

    keys.forEach((key: any) => {
      let title = this.getTitle(key);
      this.items.push({
        type: 'form', form: {
          name: key,
          description: title,
          abbreviation: title.length == key.length ? "" : title.replace(key + " ", "").trim()
        }
      });
    })

    this.items[0].form.selected = true;
    this.formDates = {};

    keys.forEach((id: string) => {
      this.formDates[id] = Object.keys(this.forms[id]);
      this.formDates[id].sort((a: any, b: any) => {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });
    });

    this.formId = keys[0];
    this.selectedForm = keys[0];
    this.survey = this.getForm(this.formId);
    console.log(this.survey)
    this.selectForm();

    //TODO: Revisar? No se está traduciendo?
    this.checkboxItems.push({ name: 'Sí', value: 'si' });
    this.checkboxItems.push({ name: 'No', value: 'no' });
  }

  changeFormMobile(nameForm: any) {
    let item: any = this.items.find((item: any) => item.form.selected);
    item.form.selected = false;
    item = this.items.find((item: any) => item.form.name == nameForm);
    item.form.selected = true;
    this.changeForm(nameForm);
  }

  changeFormDesktop(itemSelected: any) {
    let item: any = this.items.find((item: any) => item.form.selected);
    if ((!item && !itemSelected) || item.form.name == itemSelected.form.name) return;
    item.form.selected = false;
    let nameForm = itemSelected.form.name;
    item = this.items.find((item: any) => item.form.name == nameForm);
    item.form.selected = true;
    this.changeForm(nameForm);
  }

  reset() {
    this.answers = {};
    this.updateInfoForm();
  }

  setAnswer(question: any, index: any, section: any) {
    this.formService.setAnswer(this.answers, question, index, section);
    this.updateInfoForm();
  }

  save() {
    let valid = this.isValid();

    if (valid && !this.saving) {
      this.saving = true;

      let r = { [this.formId]: this.answers };

      this.toastService.show(this.i18nService.translate('patient_forms_saving'), 'normal', false);

      TherapyAPI.createFAForm(this.patientId, this.diagnosisId, r).then((result: any) => {
        // this.forms[this.formId] = {
        //   ...this.answers,
        //   score: result[0].score,
        //   scoreNorm: result[0].scoreNorm,
        //   pending: false
        // }
        this.scoreOrder = this.formService.getScoreOrder(this.formId);
        // if (this.diagnostics[this.formId].score && this.scoreOrder) this.makeSpiderData();

        this.saving = false;

        this.cd.markForCheck();
        this.cd.detectChanges();

        // this.snackbar.show("patient_forms_saved", null, true);
        this.toastService.show(this.i18nService.translate('patient_forms_saved'), 'success', false);
        // this.onAnswered.emit(this.form);
        delete this.forms[this.formId][this.selectedDate];
        if (Object.keys(this.forms[this.formId]).length == 0) {
          delete this.forms[this.formId];
        }
        if (Object.keys(this.forms).length == 0) {
          this.coachService.setState({ allFormsAnswered: true }, true);
          this.router.navigate(['/']);
        } else {
          // this.isComplete = true;
          this.formsComplets[this.formId] = true;
          this.items = [];
          this.init();
        }
        this.reset();
        //this.notificationService.checkNotifications();
      }).catch((error: any) => {
        if (error.code == "ECONNABORTED") {
          this.toastService.show(this.i18nService.translate('general_connection_error'), 'danger', false);
        } else if (error.status == 0) {
          // this.modalService.showNoInternet();
        } else {
          this.toastService.show(this.i18nService.translate('general_form_error'), 'danger', false);
        }
        console.error("SAVE FORM ERROR", error);
      });
    } else {
      this.toastService.show(this.i18nService.translate('patient_forms_all_required'), 'danger', false);
    }
  }

  isValid() {
    return this.formService.isValid(this.answers, this.formId, this.survey);
  }

  isFormCountQuestions() {
    return ![FormName.MRC, FormName.NDI, FormName.ODI, FormName.PGIC, FormName.TegnerScore, FormName.MNA, FormName.GDS, FormName.HADS, FormName.ICIQ_SF, FormName.SF12, FormName.PFIQ_7].includes(this.formId);
  }

  private changeForm(nameForm: string) {
    this.survey = this.getForm(nameForm);
    this.formId = nameForm;
    this.selectedForm = nameForm;
    this.selectForm();
  }

  private selectForm() {

    this.isComplete = this.formsComplets[this.formId] ? this.formsComplets[this.formId] : false;

    if (!this.isComplete) {
      this.selectedDate = this.formDates[this.formId][0];
      this.diagnosisId = this.forms[this.formId][this.selectedDate].diagnosisId;
      this.totalAnswers = 0;

      if (this.isFormCountQuestions()) {
        this.survey.sections.forEach((section: any) => this.totalAnswers += section.questions.length);
      } else if (this.formId == FormName.SF12) {
        this.survey.sections.forEach((section: any) => this.totalAnswers += section.maxResponses ? section.maxResponses : section.questions.length);
      } else {
        this.totalAnswers += this.survey.sections.length;
      }

      this.answers = this.forms[this.formId][this.selectedDate].pending ? {} : this.forms[this.formId][this.selectedDate];
      this.updateInfoForm();
      this.scrollChatToTop();
    }
  }
  private getForm(formId: string) {
    let survey = this.formService.getForm(formId);
    //i18n
    let title = this.i18nService.translate(formId + "_t");
    if (title != formId + "_t") survey.title = title;
    else survey.title = "";

    let description = this.i18nService.translate(formId + "_d");
    if (description != formId + "_d") survey.description = description;
    else survey.description = "";


    survey.sections.forEach((section: any, index: any) => {
      let title = this.i18nService.translate(formId + "_" + index + "_t");
      if (title != formId + "_" + index + "_t") section.title = title;
      else section.title = "";
      let description = this.i18nService.translate(formId + "_" + index + "_d");
      if (description != formId + "_" + index + "_d") section.description = description;
      else section.description = "";

      section.questions.forEach((question: any) => {
        let text = this.i18nService.translate(formId + "_" + index + "_" + question.id + "_t");
        if (text != formId + "_" + index + "_" + question.id + "_t") question.text = text;
        else question.text = "";
      });
    });

    return survey;
  }

  private updateInfoForm() {
    this.answered = this.getNumAnswered();
    this.onAnswered.emit(this.answered);
    this.percentageAnswered = ((this.answered / this.totalAnswers) * 100).toFixed(0);
  }

  private getNumAnswered() {
    if (this.formId != FormName.ICIQ_SF) return Object.keys(this.answers).length;

    if (this.formId == FormName.ICIQ_SF) {
      let answers = 0;
      let keys = Object.keys(this.answers);
      this.answersICIQSF.forEach((answer: any) => {
        if (keys.some((key: string) => Array.from({ length: answer.final - answer.init }, (x, i) => i + answer.init).includes(parseInt(key)))) answers++;
      });
      return answers;
    };

  }

  private getTitle(formId: string) {
    // let title = this.formService.getFormTitle(formId, this.languageKey);
    return this.i18nService.translate(formId + "_t");
  }

  private scrollChatToTop() {
    let messages = document.getElementById('formContainer');
    if (messages) {
      messages.scrollTop = 0;
    }
  }
}