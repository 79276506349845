import { Injectable } from '@angular/core';
import { CredentialsService } from '../auth';
import { EventBusService } from './eventbus.service';

import { SharedNotificationService } from "@rehub-shared/service/notification.service";

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends SharedNotificationService {

  constructor(
    credentalsService: CredentialsService,
    eventBus: EventBusService
  ) {
    super(credentalsService, eventBus);
  }
}
