<select class="custom-select custom-select-lg mb-3" [ngModel]='currentLanguage' (ngModelChange)='setLanguage($event)'>
  <ng-container *ngFor="let language of languages">
    <option value="{{ language.name }}">{{ 'language_' + language.name | translate }}</option>
  </ng-container>
</select>
<!-- <div [ngClass]="{ 'nav-item': inNavbar }" ngbDropdown>
  <a *ngIf="inNavbar; else button" id="language-dropdown" class="nav-link" ngbDropdownToggle>
    {{ currentLanguage | translate }}
  </a>
  <ng-template #button>
    <button id="language-dropdown" class="btn btn-sm btn-secondary" ngbDropdownToggle>
      {{ currentLanguage }}
    </button>
  </ng-template>
  <div ngbDropdownMenu aria-labelledby="language-dropdown" [ngClass]="menuClass">
    <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
      {{ language | translate }}
    </button>
  </div>
</div> -->
