export const DEFAULT_CAMERA_POINTS = {
  NOSE: 0,
  LEFT_EYE: 1,
  RIGHT_EYE: 2,
  LEFT_EAR: 3,
  RIGHT_EAR: 4,
  LEFT_SHOULDER: 5,
  RIGHT_SHOULDER: 6,
  LEFT_ELBOW: 7,
  RIGHT_ELBOW: 8,
  LEFT_WRIST: 9,
  RIGHT_WRIST: 10,
  LEFT_HIP: 11,
  RIGHT_HIP: 12,
  LEFT_KNEE: 13,
  RIGHT_KNEE: 14,
  LEFT_ANKLE: 15,
  RIGHT_ANKLE: 16,
  MID_SHOULDER: 17,
  MID_HIP: 18,
  LUMBAR: 19
}

export const CAMERA_POINTS_1 = {
  ...DEFAULT_CAMERA_POINTS,
  HEAD: 20
}

export const CAMERA_POINTS_VERIONS: { [key: string]: typeof DEFAULT_CAMERA_POINTS } = {
  "0": DEFAULT_CAMERA_POINTS,
  "1": CAMERA_POINTS_1
}

export const DEFAULT_CAMERA_POINTS_CONTRALATERAL = {
  NOSE: DEFAULT_CAMERA_POINTS.NOSE,
  LEFT_EYE: DEFAULT_CAMERA_POINTS.RIGHT_EYE,
  RIGHT_EYE: DEFAULT_CAMERA_POINTS.LEFT_EYE,
  LEFT_EAR: DEFAULT_CAMERA_POINTS.RIGHT_EAR,
  RIGHT_EAR: DEFAULT_CAMERA_POINTS.LEFT_EAR,
  LEFT_SHOULDER: DEFAULT_CAMERA_POINTS.RIGHT_SHOULDER,
  RIGHT_SHOULDER: DEFAULT_CAMERA_POINTS.LEFT_SHOULDER,
  LEFT_ELBOW: DEFAULT_CAMERA_POINTS.RIGHT_ELBOW,
  RIGHT_ELBOW: DEFAULT_CAMERA_POINTS.LEFT_ELBOW,
  LEFT_WRIST: DEFAULT_CAMERA_POINTS.RIGHT_WRIST,
  RIGHT_WRIST: DEFAULT_CAMERA_POINTS.LEFT_WRIST,
  LEFT_HIP: DEFAULT_CAMERA_POINTS.RIGHT_HIP,
  RIGHT_HIP: DEFAULT_CAMERA_POINTS.LEFT_HIP,
  LEFT_KNEE: DEFAULT_CAMERA_POINTS.RIGHT_KNEE,
  RIGHT_KNEE: DEFAULT_CAMERA_POINTS.LEFT_KNEE,
  LEFT_ANKLE: DEFAULT_CAMERA_POINTS.RIGHT_ANKLE,
  RIGHT_ANKLE: DEFAULT_CAMERA_POINTS.LEFT_ANKLE,
  MID_SHOULDER: DEFAULT_CAMERA_POINTS.MID_SHOULDER,
  MID_HIP: DEFAULT_CAMERA_POINTS.MID_HIP,
  LUMBAR: DEFAULT_CAMERA_POINTS.LUMBAR
}

export const CAMERA_POINTS_CONTRALATERAL_1 = {
  ...DEFAULT_CAMERA_POINTS_CONTRALATERAL,
  HEAD: CAMERA_POINTS_1.HEAD
}

export const CAMERA_POINTS_CONTRALATERAL_VERSIONS: { [key: string]: typeof DEFAULT_CAMERA_POINTS_CONTRALATERAL } = {
  "0": DEFAULT_CAMERA_POINTS_CONTRALATERAL,
  "1": CAMERA_POINTS_CONTRALATERAL_1
}

// CameraPointsByCamPlane

export const DEFAULT_CAMERA_POINTS_BY_CAM_PLANE = {
  up: [
    DEFAULT_CAMERA_POINTS.NOSE,
    DEFAULT_CAMERA_POINTS.LEFT_EYE,
    DEFAULT_CAMERA_POINTS.RIGHT_EYE,
    DEFAULT_CAMERA_POINTS.LEFT_EAR,
    DEFAULT_CAMERA_POINTS.RIGHT_EAR,
    DEFAULT_CAMERA_POINTS.LEFT_SHOULDER,
    DEFAULT_CAMERA_POINTS.RIGHT_SHOULDER,
    DEFAULT_CAMERA_POINTS.LEFT_ELBOW,
    DEFAULT_CAMERA_POINTS.RIGHT_ELBOW,
    DEFAULT_CAMERA_POINTS.LEFT_WRIST,
    DEFAULT_CAMERA_POINTS.RIGHT_WRIST,
    DEFAULT_CAMERA_POINTS.MID_SHOULDER,
    DEFAULT_CAMERA_POINTS.MID_HIP,
    DEFAULT_CAMERA_POINTS.LUMBAR
  ],
  down: [
    DEFAULT_CAMERA_POINTS.LEFT_HIP,
    DEFAULT_CAMERA_POINTS.RIGHT_HIP,
    DEFAULT_CAMERA_POINTS.LEFT_KNEE,
    DEFAULT_CAMERA_POINTS.RIGHT_KNEE,
    DEFAULT_CAMERA_POINTS.LEFT_ANKLE,
    DEFAULT_CAMERA_POINTS.RIGHT_ANKLE
  ],
}

export const CAMERA_POINTS_BY_CAM_PLANE_1 = {
  ...DEFAULT_CAMERA_POINTS_BY_CAM_PLANE,
  up: [
    ...DEFAULT_CAMERA_POINTS_BY_CAM_PLANE.up,
    CAMERA_POINTS_1.HEAD,
  ],
}

export const CAMERA_POINTS_BY_CAM_PLANE_VERSIONS: { [key: string]: typeof DEFAULT_CAMERA_POINTS_BY_CAM_PLANE } = {
  "0": DEFAULT_CAMERA_POINTS_BY_CAM_PLANE,
  "1": CAMERA_POINTS_BY_CAM_PLANE_1
}
