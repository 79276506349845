import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { I18nService } from '@app/i18n';
import { ButtonProgressBar } from '@rehub-shared/utils/button-progress-bar';

@Component({
  selector: 'rehub-confirm-end-exercise-modal',
  templateUrl: './confirm-end-exercise-modal.component.html',
})
export class ConfirmEndExerciseModalComponent implements OnInit {

  data: any;
  disabled: boolean;
  confirmEndExerciseProgressBar: ButtonProgressBar = new ButtonProgressBar();

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
  ) {}

  ngOnInit() {
    this.confirmEndExerciseProgressBar.start("#buttonAcceptEndExercise", 12000, this.confirm.bind(this));
  }

  close() {
    this.confirmEndExerciseProgressBar.stop();
    this.modalService.close(false, true);
  }

  confirm() {
    this.disabled = true;
    this.modalService.callback(true);
  }

  cancel() {
    this.confirmEndExerciseProgressBar.stop();
    this.close();
  }

  getBodyTranslation(){
    if(!this.data.body) return;
    return this.i18nService.translate(this.data.body);
  }


}
