export class AchievementUtils {

  public static getAchivementsTypes(achievements: any): string[] {
    return Object.keys(achievements.achievementThresholds);
  }

  public static getAchievements(achievements: any): any[] {
    return achievements ? achievements.achievements : [];
  }

  public static getTodayAchievements(achievements: any, moment: any): any[] {
    let result = AchievementUtils.getAchievements(achievements);
    let today = moment.utc().startOf('day');

    return result.filter((achievement: any) => moment.utc(achievement.doneDate).startOf('day').isSame(today));
  }

  public static getTotalAchievements(achievements: any, moment: any): any[] {
    let result = AchievementUtils.getAchievements(achievements);
    let today = moment.utc().startOf('day');

    return result.filter((achievement: any) => moment.utc(achievement.doneDate).startOf('day').isSameOrBefore(today));
  }

  public static getPendingAchievements(achievements: any): any[] {
    let types = AchievementUtils.getAchivementsTypes(achievements);

    let result = types.map(((type: string) => {
      let value = achievements.main ? achievements.main[type] || 0 : 0;
      let nextValue = achievements.achievementThresholds[type].find((n: number) => n > value);

      return {
        achievementName: type,
        achievementValue: nextValue,
        achievementValueRemaining: nextValue - value
      }
    }));

    return result;
  }

  public static getNextExerciseDate(exercisesByDate: any, moment: any){
    let keys = Object.keys(exercisesByDate);
    if(keys.length == 0) return null;

    let today = moment.utc().startOf('day');
    return keys.find((date: string)=>moment.utc(date).isAfter(today));
  }

  public static getCoachMessageForAchievement(achievementName: string) {
    if (achievementName == 'E_DONE') return "completed_exercises_achievement";
    if (achievementName == 'E_DONE_MAX_Q') return "five_stars_achievement";
    if (achievementName == 'E_DONE_NO_PAIN') return "pain_achievement";
    return "";
  }

}