import { SharedUtils } from "../utils";

declare var ReHubWasmModule: any;
declare var TextDecoder: any;

export class RehubWasm {

  module: any;
  actionReadyPointer: any;
  receive: any;

  constructor(){
    (window as any)["RehubWasm"] = this;
  }

  // https://rob-blackbourn.github.io/blog/webassembly/wasm/strings/javascript/c/libc/wasm-libc/clang/2020/06/20/wasm-string-passing.html
  private getStringFromMemory(memory: any, ptr: number, len: number){
    // UTF8ArrayToString???
    try {
      const array = new Uint8Array(memory.buffer, ptr, len);
      return new TextDecoder().decode(array);
    } finally {
      //this.instance.exports.free(ptr);
    }
  }

  send(str: string){
    if(str.includes("action_ready")){
      let action_ready = this.module.cwrap("action_ready", "undefined", ["number", "string"]);
      action_ready(this.actionReadyPointer);
    }else{
      let send = this.module.cwrap("send", "undefined", ["string"]);
      return send(str);
    }
  }

  setReceive(receive: any){
    this.receive = receive;
  }

  init(wasmPath: string){
    return SharedUtils.loadScript(wasmPath).then(()=>{
      return ReHubWasmModule().then((module: any)=>{
        this.module = module;
        this.actionReadyPointer = this.module.addFunction((data: any, length: number)=>{
          let str = this.getStringFromMemory(this.module.asm.memory, data, length);
          console.log("action_ready", str);
          if(this.receive) this.receive(str);
        }, "vii");

        return this.module.asm;
      });
    });
  }
}