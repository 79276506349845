export abstract class DebugGui {

  private static gui: any;

  public static set(gui: any){
    this.gui = gui;
    this.gui.domElement.style.right = undefined;
    this.gui.domElement.style.left = "15px";

    this.hide();

    (window as any).DebugGui = this;
  }

  public static show(){
    this.gui.show();
  }

  public static hide(){
    this.gui.hide();
  }

  public static addFolder(title: string){
    return this.gui.addFolder(title);
  }

  public static removeFolder(title: string) {
    this.gui.folders.forEach((folder: any) => {
      if (folder._title == title) folder.destroy();
    });
  }
}