import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service/modal.service';

import { environment } from '@env/environment';

import { I18nService } from '@app/i18n';

import { CredentialsService } from '@app/@core/auth';

import { SharedUtils } from '@rehub-shared/utils/utils';

@Component({
  selector: 'rehub-install-realtime-modal',
  templateUrl: './install-realtime-modal.component.html',
  styleUrls: ['./install-realtime-modal.component.scss']
})
export class InstallRealtimeModalComponent implements OnInit {

  data: any;

  installing: boolean = false;
  private statusChangeSubscription: any;

  bodyTranslation: string;
  supportUrl: string;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.bodyTranslation = this.i18nService.translate('modal_realtime_pending_install_body_2');
    this.supportUrl = this.getSupportUrl();

    this.statusChangeSubscription = this.data.measurementProgramService.onStatusChange().subscribe((status: string)=>{
      if(status == "INSTALLING"){
        // Ha empezado a instalar
        this.installing = true;
      }else if(status == "CONNECTED" || status == "PENDING_UPDATE"){
        // Ha terminado de instalar
        this.close(status == "PENDING_UPDATE" ? "update" : null);
      }
    });
  }

  close(result: string = null){
    this.statusChangeSubscription.unsubscribe();
    if(result) this.modalService.callback(result);
    else this.modalService.close();
  }

  onCancelPressed(){
    this.data.measurementProgramService.cancelInstall();
    this.close();
  }

  onDownloadPressed(){
    //Abrir pdf en una nueva pestaña
    let lang = this.i18nService.getLanguage();
    window.open("https://s3-eu-west-1.amazonaws.com/dycare.rehub.shared.files/default/" + lang + "/Install_Realtime_ReHub_Patient.pdf", "_blank");
    // Descargar instalador
    this.data.measurementProgramService.downloadRealtimeInstaller();
  }

  onSkipDownloadPressed(){
    this.close("skip");
  }

  getSupportUrl(){
    return SharedUtils.getSupportUrl({
      client: environment.client_login,
      supportUrl: environment.login_url + "/contact-form",
      patient: this.credentialsService.getInfo(),
      lang: this.i18nService.getLanguageCode(),
      reasonGroup: SharedUtils.REASON_GROUP_REALTIME,
      reason: this.data.measurementProgramService.isRealtimeInstalled() ? SharedUtils.REASON_REALTIME_NO_RESET : SharedUtils.REASON_REALTIME_NO_INSTALL
    });
  }

}
