export class SharedFeatureFlagsService{

  enableChatProfessional: boolean = true;
  enableChatPatient: boolean = true;
  showToggleFullScreenPatient: boolean = true;
  showLogoutPatient: boolean = true;
  showResetPatientPasswordProfessional: boolean = true;
  showUsernamePatient: boolean = true;
  showProfilePatient: boolean = true;
  enableChangePasswordPatient: boolean = true;
  showPatientEmailProfessional: boolean = true;
  showPatientEmailBounced: boolean = true;
  showPatientLoginProfessional: boolean = true;

  constructor(){
    (window as any).featureFlags = this;
  }
  
  public set(featureFlags: any){
    if(featureFlags == null) return;

    // Sobreescribe las feature flags que llegen 
    Object.keys(featureFlags).forEach((featureFlag: string)=>{
      (this as any)[featureFlag] = featureFlags[featureFlag];
    });
  }
  
}