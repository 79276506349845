import { PatientAPI, UserAPI } from "../api";
import { SharedCredentialsService } from "./credentials.service";
import { SharedEventBusService } from "./eventbus.service";

export type CalendarEventItem = "CALENDAR_EVENT" | "CALENDAR_EVENT_ATTENDEE";
export type CalendarEventAction = "GET" | "LIST" | "CREATE" | "UPDATE" | "UPDATE_STATUS";
export type CalendarEventAttendeeStatus = "OK" | "NOK" | "CANCELED";

export class SharedNotificationService {

  private notifications: any = [];

  constructor(
    private credentialsService: SharedCredentialsService,
    private eventBus: SharedEventBusService
  ){}

  public static hasFormResponseNotification(notifications: any){
    return notifications && notifications.find((n: any)=> n.notificationType == "FA_FORM_RESPONSE") != null;
  }

  public static hasNewMessageNotification(notifications: any){
    return notifications && notifications.find((n: any)=> n.notificationType == "NEW_MESSAGE") != null;
  }

  checkNotifications() {
    if (this.credentialsService.isPatient()) {
      let patientId = this.credentialsService.getCredentials().sub;
      PatientAPI.getNotificationsPatient(patientId).then((result: any) => {
        this.setNotifications(result);
      }).catch((error) => {
        //TODO!: gestion de errores
        // if(error.code == "ECONNABORTED") {
        //   this.snackbar.showWarning("general_connection_error_notifications");
        // } else {
        //   this.snackbar.showWarning("general_form_error");
        // }
        console.error("CHECK NOTIFICATIONS PATIENT ERROR", error);
      });
    } else if(this.credentialsService.isProfessional()) {
      UserAPI.getNotificationsProfessional().then((result: any) => {
        this.setNotifications(result);
      }).catch((error) => {
        //TODO!: gestion de errores
        // if(error.code == "ECONNABORTED") {
        //   this.snackbar.showWarning("general_connection_error_notifications");
        // } else {
        //   this.snackbar.showWarning("general_form_error");
        // }
        console.error("CHECK NOTIFICATIONS PROFESSIONAL ERROR", error);
      });
    }
  }

  getNotifications() {
    return this.notifications;
  }

  setNotifications(notifications: any) {
    this.notifications = notifications;

    let credentials = this.credentialsService.getInfo();

    if (credentials.bouncedEmail && this.notifications) {
      this.notifications.push({
        notificationType: "BOUNCED_EMAIL"
      });
    }
    this.eventBus.publish("NOTIFICATIONS_RECEIVED", this.notifications);
  }
}