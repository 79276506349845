<div class="px-3" [ngClass]="{'pt-2': isMobile}">
  <div class="d-flex mx-auto">
    <div class="flex-row justify-content-center align-items-center pt-2 px-2 w-100">
      <div class="d-flex justify-content-center align-items-center">
        <rehub-icon name="time_1" size="24" class="mr-2 text-primary" ></rehub-icon>
        <h2 class="my-auto text-title "  [ngClass]="{'therapy-info-values' : !isMobile , 'therapy-info-values-mobile': isMobile}">{{duration}}</h2>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="therapy-info text-therapy" style="margin: 0;">{{'therapy_duration' | translate}}</h5>
      </div>

    </div>

    <div class="flex-row  pt-2 w-100 px-2" >
      <div class="d-flex justify-content-center align-items-center">
        <rehub-icon name="components" size="24" class="mr-2 text-primary"></rehub-icon>
        <h2 class="my-auto text-title" [ngClass]="{'therapy-info-values' : !isMobile , 'therapy-info-values-mobile': isMobile}">{{exercise.setsCount}}</h2>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="therapy-info text-therapy" style="margin: 0;">{{'therapy_sets' | translate}}</h5>
      </div>
    </div>

    <div class=" flex-row justify-content-center pt-2 w-100 px-2" >
      <div class="d-flex justify-content-center align-items-center">
        <rehub-icon  name="duplicate_1" size="24" class="mr-2 text-primary"></rehub-icon>
        <h2 class="my-auto text-title " [ngClass]="{'therapy-info-values' : !isMobile , 'therapy-info-values-mobile': isMobile}">{{exercise.iterations}}</h2>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="therapy-info text-therapy" style="margin: 0;">{{"therapy_reps" | translate}}</h5>
      </div>

    </div>

    <div class="flex-row  justify-content-center align-items-center pt-2 w-100 px-2" style="gap: 10px;" *ngIf="hasIconsToShow()">
      <div class="d-flex justify-content-center align-items-center ">
        <rehub-icon class="text-primary" *ngIf="protocol.cameraEnabled" name="sensor_camera" size="24"></rehub-icon>
        <rehub-icon *ngIf="hasSensor && protocol.sensorEnabled" [attr.name]="SharedUtils.getSensorTypeIcon(protocol.sensorType)" size="24"></rehub-icon>
        <h2  *ngIf="protocol.cameraEnabled || (hasSensor && protocol.sensorEnabled)" class="my-auto text-title" [ngClass]="{'therapy-info-values' : !isMobile , 'therapy-info-values-mobile': isMobile}">{{"therapy_detection_yes" | translate}}</h2>
        <h2 *ngIf="!protocol.cameraEnabled && !(hasSensor && protocol.sensorEnabled)" class="my-auto text-title " [ngClass]="{'therapy-info-values' : !isMobile , 'therapy-info-values-mobile': isMobile}">{{"therapy_detection_no" | translate}}</h2>

      </div>
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="therapy-info text-therapy" style="margin: 0;">{{"therapy_detection" | translate}}</h5>
      </div>



    </div>
    <!-- <ng-container *ngIf="protocol.tools && protocol.tools.length > 0">
      <div class="flex-row  justify-content-center align-items-center pt-2 px-2 w-100">
        <div class="d-flex justify-content-center align-items-center pt-2">
          <rehub-icon *ngFor="let tool of protocol.tools" [attr.name]="SharedUtils.getToolIcon(tool)" size="24"></rehub-icon>
        </div>
        <div class="d-flex justify-content-center align-items-center" *ngIf="!isMobile">
          <h5 class="therapy-info text-therapy" style="margin: 0;">{{"therapy_tools" | translate}}</h5>
        </div>
      </div>
    </ng-container> -->
  </div>
</div>