import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CredentialsService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class IsMissingUserInfoGuard implements CanActivate {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) { }

  canActivate(): boolean {
    if (this.credentialsService.isMissingUserInfo()) return true;
    else this.router.navigate(['/']);

    return false;
  }
}
