<div class="d-flex flex-column align-items-center justify-content-center p-3 container-calling" id="calling-modal">
  <div class="text-right w-100">
      <rehub-icon type="button" class="close" aria-label="Close" (click)="endCall()" name="close" size="20"></rehub-icon>
  </div>
  <rehub-avatar [image]="getAvatar(data.professional)" [name]="data.professional.alias" [size]="150" [showBorder]="true"></rehub-avatar>
  <div class="h4 mt-3">{{ data.professional.name }}</div>
  <div class="h3 mt-2 mb-4">{{ 'videocall_calling_professional' | translate }}</div>
  <div class="text-center d-block">
    <button class="btn btn-success btn-call mr-3" (click)="initCall()">
      <rehub-icon name="phone"></rehub-icon>
    </button>
    <button class="btn btn-danger btn-call" (click)="endCall()">
      <rehub-icon name="end_call"></rehub-icon>
    </button>
  </div>
</div>