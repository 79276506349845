<div class="d-flex w-100 align-items-center justify-content-center h-100">
  <div class="d-flex flex-column my-auto w-100  rounded bg-white p-5 mx-3" >
    <div class="d-flex justify-content-center mb-4">
      <h1 class="title_pulsio">{{ 'title_pulseOximeter' | translate}}</h1>
      <rehub-icon  name="help_circle_filled" class="text-primary ml-2 mb-0 cursor-pointer" size="16"  (click)="onIndicatorPressed('pulseoximeter_info_text')"></rehub-icon>
    </div>
    <div class="d-flex align-items-center my-auto flex-wrap" [formGroup]="paramsForm">
      <div class="mx-auto pb-4" *ngIf="pulseOximeterMeasures[0]">
        <div class="d-flex">
          <p class="">{{'pulseoximeter_BPM_name'| translate}}</p>
          <rehub-icon  name="help_circle_filled" class="text-primary ml-2 mb-0 cursor-pointer" size="16" (click)="onIndicatorPressed('pulseoximeter_PPM_vtext')"></rehub-icon>
        </div>
        <div class="form-input" [ngClass]="{'error': paramsForm.controls.ppm.invalid}" >
          <input class="inputNumber" type="number" formControlName="ppm" >
          <div class="unit" >PPM</div>
        </div>
      </div>
      <div class="mx-auto pb-4" *ngIf="pulseOximeterMeasures[1]">
        <div class="d-flex">
          <p>{{'pulseoximeter_dyspnoea_name'| translate}}</p>
          <rehub-icon  name="help_circle_filled" class="text-primary ml-2 mb-0 cursor-pointer" size="16" (click)="onIndicatorPressed('pulseoximeter_dyspnoea_text')"></rehub-icon>
        </div>
        <div class="form-input" [ngClass]="{'error': paramsForm.controls.d.invalid}" >
          <input class="unit" type="number" formControlName="d">
        </div>
      </div>
      <div class="mx-auto pb-4" *ngIf="pulseOximeterMeasures[2]">
        <div class="d-flex">
          <p>{{'pulseoximeter_spo2_name'| translate}}</p>
          <rehub-icon  name="help_circle_filled" class="text-primary ml-2 mb-0 cursor-pointer" size="16" (click)="onIndicatorPressed('pulseoximeter_spo2_text')"></rehub-icon>
        </div>
        <div class="form-input"[ngClass]="{'error': paramsForm.controls.spo2.invalid}" >
          <input type="number" formControlName="spo2" >
          <div class="unit" >%</div>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-primary rounded w-75 mx-auto mt-5" (click)="sendPulseOximeter()" [disabled]="disabledButton()">
      {{'general_save' | translate}}
    </button>
  </div>
</div>