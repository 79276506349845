<table cellpadding="5">
  <tr *ngFor="let item of items">
    <td class="align-top">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="{{item.value}}" id="{{id + '_' + item.value}}" class="checkbox"
          [checked]="answered == item.value" (change)="changeBox(item.value);">
          <label class="form-check-label checkmark" for="{{id + '_' + item.value}}" [ngClass]="{error: error}"></label>
      </div>
    </td>
    <td class="align-top">
      <div class="label">
        <label for="{{id + '_' + item.value}}" #textInfo class="cursor-pointer">
          <ng-content></ng-content>
        </label>
        <label for="{{id + '_' + item.value}}" *ngIf="!textInfo.innerHTML.trim()" class="cursor-pointer">{{item.text}}</label>
      </div>
    </td>
  </tr>
</table>