export const MAX_ITERATIONS: number = 50;
export const MAX_ITERATION_TIME: number = 300;
export const MAX_REPS: number = 10;
export class SharedValidators {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config: any = {
      required: "input_error_required",
      invalidEmailAddress: "input_error_invalidEmailAddress",
      invalidUserName: "input_error_invalidUserName",
      invalidPassword: "input_error_invalidPassword",
      min: "input_error_min",
      max: "input_error_max"
    };

    return { text: config[validatorName], params: validatorValue[validatorName] };
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[\w-]{2,4}$/)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static passwordValidator(control: any) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  static phoneValidator(control: any) {
    if (control.value.toString().length > 6) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  static notZeroValidator(control: any) {
    if (control.value != 0) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  static noAccentsValidator(control: any) {
    if (control.value.match(/^[a-zA-Z\\_\\"0-9]+\.?[a-zA-Z\\_\\"0-9]+\.?[a-zA-Z\\_\\"0-9]*$/)) {
      return null;
    } else {
      return { invalidUserName: true };
    }
  }

  static integerValidator(control: any) {
    let value = control.value ? control.value.toString() : "";

    if (value.match(/^-?[0-9]*$/g)) {
      return null;
    } else {
      return { invalidInteger: true };
    }
  }

  static floatValidator(control: any) {
    let value = control.value ? control.value.toString().replace(",", ".") : "";

    if (value.match(/^-?[0-9]*.?[0-9]{0,2}$/g)) {
      return null;
    } else {
      return { invalidFloat: true };
    }
  }
}
