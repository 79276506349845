export class EditorUtils{
  static setTempProtocol(protocol: any, i18n: any = {}) {
    let tempProtocol = {
      protocol: protocol,
      i18n: i18n
    }
    localStorage.setItem("tempProtocol", JSON.stringify(tempProtocol));
  }

  static getTempProtocol() {
    return JSON.parse(localStorage.getItem("tempProtocol") || "");
  }

  static removeTempProtocol() {
    localStorage.removeItem("tempProtocol");
  }
  
  static setTempProtocolGroup(protocolGroup: any, i18n: any = {}) {
    let tempProtocolGroup = {
      protocolGroup: protocolGroup,
      i18n: i18n
    }
    localStorage.setItem("tempProtocolGroup", JSON.stringify(tempProtocolGroup));
  }

  static getTempProtocolGroup() {
    return JSON.parse(localStorage.getItem("tempProtocolGroup") || "");
  }

  static removeTempProtocolGroup() {
    localStorage.removeItem("tempProtocolGroup");
  }
}