import { Injectable } from '@angular/core';
import { SpeechRecognitionService } from '@rehub-shared/service/speechrecognition.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognition extends SpeechRecognitionService {
  private transcriptionSubject = new Subject<string>();

  constructor() {
    super();

    // Handle speech recognition results
    this.recognition.onresult = (event: any) => {
      const transcript = Array.from(event.results)
        .map((result: any) => result[0].transcript)
        .join('');
      this.message = transcript;
      this.transcriptionSubject.next(this.message); // Emit the transcription result
    };
  }

  getTranscriptionObservable() {
    return this.transcriptionSubject.asObservable();
  }
}