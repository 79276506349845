import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedFormCheckboxComponent } from '@rehub-shared/component/form/answerTypes/checkbox/form-checkbox.component';

@Component({
  selector: 'rehub-form-checkbox',
  templateUrl: '../../../../../../../shared/src/component/form/answerTypes/checkbox/form-checkbox.component.html',
  styleUrls: ['../../../../../../../shared/src/component/form/answerTypes/checkbox/form-checkbox.component.scss']
})
export class FormCheckboxComponent extends SharedFormCheckboxComponent implements OnInit {

  @Input() items: any[];
  @Input() id: string;
  @Input() answered: any;
  @Output() changeCheckbox = new EventEmitter();

  ngOnInit() {
  }

  emitChangeCheckbox(value: any) {
    this.changeCheckbox.emit(value);
  }

  getButtonTheme(): string {
    return "patient";
  }

}