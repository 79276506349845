import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService, CredentialsService } from '@app/@core/auth';
import { ModalService } from '@app/@core/service';
import { PatientAPI } from '@rehub-shared/api';

@Component({
  selector: 'rehub-paradise-game-modal',
  templateUrl: './paradise-game-modal.component.html'
})
export class ParadiseGameModalComponent implements OnInit {

  @Input() data: any;
  url: any;
  iframe: any;

  constructor(
    private dom: DomSanitizer,
    private modalService: ModalService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService
  ){}

  ngOnInit() {
    this.setUrl(this.data.url);
  }

  onLoad(event: any, iframe: any){
    if(this.iframe) return;

    this.iframe = iframe;

    this.sendConfig();

    window.addEventListener("message", this.onMessage.bind(this));
  }

  onMessage(message: any){
    if(message.source != this.iframe.contentWindow) return;
    let data = message.data;

    this.saveConfig(data);

    if(data.redirect){
      this.setUrl("https://web-resources.dycare-services.com/development/TFM/" + data.redirect);
      this.sendConfig();
    }
  }

  sendConfig(){
    setTimeout(()=>{
      let data = {...this.data.params, ...this.data.config};
      this.iframe.contentWindow.postMessage(data, '*');
    }, 1000);
  }

  setUrl(url: string){
    this.url = this.dom.bypassSecurityTrustResourceUrl(url);
  }

  saveConfig(config: any){
    let configToSave = {...config};

    delete configToSave.redirect;
    delete configToSave.close;

    delete configToSave.patientName;
    delete configToSave.patientSurname;
    delete configToSave.exDoneToday;
    delete configToSave.totalExercises;
    delete configToSave.exDoneUntilToday;

    this.data.config = configToSave;

    let params = [{param: "game", value: configToSave}];

    PatientAPI.updateParam(this.authenticationService, this.credentialsService.getCredentials().sub, params);

    if(config.close) this.onClosePressed();
  }

  onClosePressed(){
    this.modalService.close(this.data.config);
  }

}