import { SharedUtils } from '../../utils/utils';
import { ChromecastCallbacks } from './chromecast-callback.interface';
import { ChromecastScript } from './chromecast-common';

declare var cast: any;
declare var chrome: any;

/**
 * Gestiona la carga del chromecast sender, inicio del chromecast y 
 * de la obtención del estado de la session
 * 
 */
export class ChromecastManager {

  constructor(
    private appid: string,
    private chromecastCallback: ChromecastCallbacks
  ) {
    this.loadScriptCast();
  }

  private loadScriptCast(): void {
    if (SharedUtils.isScriptLoaded(ChromecastScript.senderRC)) {
      this.initCast();
    } else {
      SharedUtils.loadScript(ChromecastScript.senderRC).then(() => {
        this.initCast();
      }, (error) => {
        console.error(error);
      });
    }
  }

  private initCast(): void {
    (window as any)['__onGCastApiAvailable'] = (isAvailable: boolean) => {
      if (isAvailable) {
        let inter = setInterval(() => {
          if (!cast || !cast.framework) return;
          else {
            clearInterval(inter);
            cast.framework.CastContext.getInstance().setOptions({
              receiverApplicationId: this.appid,
              autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
            });
            this.addSessionStateChangedListener();
          }
        }, 1000);
      }
    };
  }
  
  /**
   * Crea el listener para saber si el estado de la sesión ha cambiado
   */
  private addSessionStateChangedListener(): void {
    var context = cast.framework.CastContext.getInstance();
    context.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, (event: any) => {
      console.log('sessionState: ' + event.sessionState);
      switch (event.sessionState) {
        case cast.framework.SessionState.SESSION_STARTING:
          this.chromecastCallback.sessionStarting();
          break;
        case cast.framework.SessionState.SESSION_STARTED:
          this.chromecastCallback.sessionStarted();
          break;  
        case cast.framework.SessionState.SESSION_RESUMED:
          this.chromecastCallback.sessionResumed();
          break;
        case cast.framework.SessionState.NO_SESSION:
          this.chromecastCallback.sessionNoSession();
          break;
        case cast.framework.SessionState.SESSION_START_FAILED:
          this.chromecastCallback.sessionStartFailed();
          break;
        case cast.framework.SessionState.SESSION_ENDED:
          console.log('CastContext: CastSession disconnected');
          this.chromecastCallback.sessionSessionEnded();
          break;
      }
    });
  }

}