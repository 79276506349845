import { Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'rehub-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  host: { class: '' },
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {
  
  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(){
  }
}
