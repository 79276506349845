import { Injectable } from '@angular/core';
import { DOMService } from './dom.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  childComponent: any;

  constructor(
    private domService: DOMService
  ){}

  setChildComponent(childComponent: any){
    this.childComponent = childComponent;
  }

  show(parentComponent: any, childComponent: any, data: any = null, onClose: any = null){
    document.body.style.overflow = "hidden";
    this.setChildComponent(childComponent);
    this.domService.add("modal", parentComponent, data, onClose);
  }

  close(result: any = null, emitCallback: boolean = true){
    document.body.style.overflow = "auto";
    this.domService.remove("modal", result, emitCallback);
  }

  callback(result: any){
    this.domService.callback("modal", result);
  }
}
