import * as moment from 'moment';

export class CalendarUtils{

  public static getDuringDayNames(){
    return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  }

  public static getDaysNames() {
    let days = moment.weekdaysShort(true);
    return days;
  }

  public static getWeekDays(date: any, languageCode: string = 'es') {
    moment.locale(languageCode);
    
    let weekStart = date.startOf('week');

    let days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days'));
    }

    return days;
  }

  public static getWeeksMonth(date: any) {
    let startWeek = date.startOf('month').week();
    let endWeek = date.endOf('month').week();
    
    let firstDaysLastWeek = false;
    let lastDaysFirstWeek = false;

    if (startWeek > endWeek) {
      if (date.month() == 0) {
        if (date.startOf('year').week() != 1) {
          firstDaysLastWeek = true;
        }
        startWeek = 1;
      } else {
        if (date.endOf('year').week() == 1) {
          lastDaysFirstWeek = true;
        }
        endWeek = date.weeksInYear();
      }
    }

    let weeks = [];
    let actualWeek = date.startOf('month');
    if (firstDaysLastWeek) {
      weeks.push(this.getWeekDaysOfMonth(actualWeek, date.format('M')));
      actualWeek = actualWeek.add(7, 'days');
    }
    for(var week = startWeek; week <= endWeek; week++) {
      weeks.push(this.getWeekDaysOfMonth(actualWeek, date.format('M')));
      actualWeek = actualWeek.add(7, 'days');
    }
    if (lastDaysFirstWeek) {
      weeks.push(this.getWeekDaysOfMonth(actualWeek, date.format('M')));
    }
    return weeks;
  }

  public static getWeekDaysOfMonth(date: any, month: any){
    let weekStart = date.startOf('week');

    let days = [];
    for (let i = 0; i <= 6; i++) {
      let date = moment(weekStart).add(i, 'days');
      days.push({date: date, isOnMOnth: date.format('M') == month});
    }

    return days;
  }

  public static isToday(date: any){
    return moment().isSame(date, 'day');
  }

  public static isSameDate(dateA: any, dateB: any) {
    return dateA.isSame(dateB, 'day');
  }

  public static formatDate(date: any, format: string){
    return moment.utc(date).format(format);
  }
}