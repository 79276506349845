import { LogLevel, BaseLog } from './log';
import { ILogPublisher } from './log-publisher';

type TLogMessage = {
  logGroup: string;
  logStream: string;
  logAction: string;
  logIndex1: string;
  logIndex2: string;
  logIndex3: string;

  date: string;
  message: string;
}

const LOG_GROUP = "rehub_frontend";

export const LOGS = {
  TEST: <TLogMessage>{logGroup: LOG_GROUP, logIndex1: LOG_GROUP + "_TEST"}
}

export class PersistentLog extends BaseLog{

  private static instance: PersistentLog;

  static getInstance(){
    if(!PersistentLog.instance) PersistentLog.instance = new PersistentLog();
    return PersistentLog.instance;
  }

  d(message: TLogMessage, params: any = null){
    this.getPublishers().forEach((publisher: ILogPublisher)=>{
      publisher.log({...message, ...params, date: new Date().toISOString()}, LogLevel.Debug);
    });
  }
}