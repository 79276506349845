import { SensorAPI } from "../api";
import { API } from "../api/api";

export class SharedSensorService{

  sensors: any = {};

  getSensors() {
    return this.sensors;
  }

  isSensorAvailable(type: any) {
    return true; //this.getAssignedSensor(type) != null;
  }

  getSensor(mac: string, type: string) {
    let sensor: any = null;
    if (mac) sensor = this.sensors[type].find((s: any) => s.sensorId == mac);
    if (sensor == null && this.sensors[type] && this.sensors[type].length > 0) sensor = this.sensors[type][0];
    return sensor;
  }

  updateSensors(sub: string) {
    SensorAPI.getSensorsByOwner(sub).then((data: any) => {
      this.getSensorTypes().forEach((type: any) => {
        this.sensors[type] = data[type] ? data[type] : [];
      });
    }).catch((error: any) => {
      this.sensors = {};
    });
  }

  getSensorTypes(){
    return ["MAIN", "HANDLE", "PRESSURE"];
  }

  getSensorKits(){
    return ["KIT_BASIC", "KIT_ADVANCED", "KIT_ADVANCED_PRESSURE"]
  }

  getSensorKitToSensorType(){
    return {
      KIT_BASIC: ["MAIN"],
      KIT_ADVANCED: ["MAIN", "HANDLE"],
      KIT_ADVANCED_PRESSURE: ["MAIN", "HANDLE", "PRESSURE"]
    }
  }

  getSensorTypeToSensorKit(){
    return {
      MAIN: ["KIT_BASIC", "KIT_ADVANCED", "KIT_ADVANCED_PRESSURE"],
      HANDLE: ["KIT_ADVANCED", "KIT_ADVANCED_PRESSURE"],
      PRESSURE: ["KIT_ADVANCED_PRESSURE"]
    }
  }

  getMySensorKitId(tempParams: any) {
    let sensorKitId: any = null;
    if (tempParams) sensorKitId = tempParams["sensorKit"];
    return sensorKitId;
  }

  sortSensorKitsByOrder(sensorKits: any, order: any) {
    let sensorKitsCopy: any = [...sensorKits];
    let result: any = [];

    if (order && order.length > 0) {
     // Añadir los sensores en el orden indicado por order
     order.forEach((sensorId: any) => {
       let index = sensorKitsCopy.findIndex((sensorKit: any)=> sensorKit.sensorId == sensorId);
       if (index != -1) result.push(sensorKitsCopy.splice(index, 1)[0]);
     });
    }

    //Ordenar el resto de sensores por nombre
    sensorKitsCopy = sensorKitsCopy.sort((a: any, b: any) => a.sensorName.localeCompare(b.sensorName));

    //Añadir el resto de sensores
    result = [...result, ...sensorKitsCopy];

    return result;
  }

  sortSensorKitsByAssigned(sensorKits: any) {
    // Primero filtra por los asignados que tengan algún problema
    // Segundo por los asignados a paciente
    // Finalmente filtra por los sensores libres

    // También ordena alfabéticamente por codigo
    let sensorKitsCopy: any = [...sensorKits];
    sensorKitsCopy = sensorKitsCopy.sort((a: any, b: any) => {
      let aAssigned = a.patientId != "UNASSIGNED";
      let bAssigned = b.patientId != "UNASSIGNED";

      if (aAssigned && !bAssigned) return -1;
      else if (!aAssigned && bAssigned) return 1;
      else if (aAssigned && bAssigned) {
        let aHasWarning = a.warning != null;
        let bHasWarning = b.warning != null;

        if (aHasWarning && !bHasWarning) return -1;
        if (!aHasWarning && bHasWarning) return 1;
      }

      return a.sensorName.localeCompare(b.sensorName);
    });

    return sensorKitsCopy;
  }

  getNosensorWarningMessage(assignedSensor: any, noSensorEnabled: boolean) {
    let warningMessage;
    if (noSensorEnabled && !assignedSensor) warningMessage = "patient_sensors_no_sensor_enabled_without_sensors";
    else if (!noSensorEnabled && !assignedSensor) warningMessage = "patient_sensors_no_sensor_disabled_without_sensors";
    return warningMessage;
  }

  getUnassignWarningMessage(assignedSensor: any, noSensorEnabled: boolean) {
    let warningMessage;
    if (noSensorEnabled && !assignedSensor) warningMessage = "patient_sensors_no_sensor_enabled_without_sensors";
    else if (!noSensorEnabled && !assignedSensor.MAC_MAIN) warningMessage = "patient_sensors_no_sensor_disabled_without_sensors";
    return warningMessage;
  }

  getNoMainSensorsError(sensors: any) {
    return !sensors["MAIN"] || (sensors["MAIN"] && sensors["MAIN"].length == 0);
  }

  getNoHandleSensorsError(sensors: any) {
    return !sensors["HANDLE"] || sensors["HANDLE"] && sensors["HANDLE"].length == 0;
  }

  getNoPressureSensorsError(sensors: any) {
    return !sensors["PRESSURE"] || sensors["PRESSURE"] && sensors["PRESSURE"].length == 0;
  }

  getAllReferenceSensors(sensorType: string, sub: string, tempParams: any) {
    return SensorAPI.getSensorsByOwner(sub, false).then((result: any) => {
      let userSensors = result;
      let resultSensors: any = {};

      if (userSensors && userSensors[sensorType]) {
        userSensors[sensorType].forEach((s: any) => {
          if (tempParams && tempParams["sensor_" + sensorType] && s.sensorId == tempParams["sensor_" + sensorType]) {
            s.mySensor = true;
            s.order = 2;
          } else if (s.patient && s.patient.name != "-" && (!tempParams || tempParams && tempParams["sensor_" + sensorType]
            && s.patientId != tempParams["sensor_" + sensorType])) {
            s.patientSensor = true;
            s.order = 1;
          } else {
            s.order = 0;
          }
        });
        resultSensors[sensorType] = [...userSensors[sensorType]];
      }

      return resultSensors;
    });
  }

  getReferenceSensors(patientId: string, sensorType: string, sub: string, tempParams: any) {
    let batch: any = {
      userSensors: SensorAPI.getSensorsByOwner(sub, false)
    }

    if (patientId) batch.patientSensors = SensorAPI.getSensorsByOwner(patientId, false);

    return API.allFromObject(batch).then((result: any) => {
      let userSensors = result.userSensors;
      let patientSensors = result.patientSensors;

      let userAssignedSensor = null;

      // buscar mi sensor de tempParams
      if (userSensors && userSensors[sensorType] && tempParams && tempParams["sensor_" + sensorType]) {
        userAssignedSensor = userSensors[sensorType].find((s: any)=>s.sensorId == tempParams["sensor_" + sensorType]);
        userAssignedSensor.mySensor = true;
      }

      //buscar de sensores del paciente el que tenga como dueño el profesional actual
      let patientAssignedSensor = null;
      if (patientSensors && patientSensors[sensorType]) {
        patientAssignedSensor = patientSensors[sensorType].find((s: any)=>s.userId == sub);
        if (patientAssignedSensor) patientAssignedSensor.patientSensor = true;
      }

      let sensors = [];

      //comprobar si son el mismo sensor para solo añadirlo una vez
      if (userAssignedSensor && patientAssignedSensor && userAssignedSensor.sensorId == patientAssignedSensor.sensorId) {
        userAssignedSensor.patientSensor = true;
        sensors.push(userAssignedSensor);
      } else {
        if (userAssignedSensor) sensors.push(userAssignedSensor);
        if (patientAssignedSensor) sensors.push(patientAssignedSensor);
      }

      let freeSensors = [];
      if (userSensors[sensorType]) freeSensors = userSensors[sensorType].filter((s: any) =>
        s.patientId == "UNASSIGNED" && !s.mySensor && !s.patientSensor);

      let resultSensors: any = {};
      resultSensors[sensorType] = [...sensors, ...freeSensors];

      return resultSensors;
    });
  }

  findMacInSensorList(mac: string, sensors: any, type: string) {
    return sensors[type].find((s: any) => s.mac == mac);
  }

  findSensorIdInSensorList(sensorId: string, sensors: any, type: string) {
    return sensors[type].find((s: any) => s.sensorId == sensorId);
  }
}