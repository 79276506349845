import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rehub-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() id:string;
  @Input() items: any[];
  @Input() error: boolean;
  @Input() answered: any;
  @Output() valueChanged = new EventEmitter();

  ngOnInit() {}

  changeBox(value: any) {
    if (this.answered == value) this.answered = null;
    else this.answered = value;
    this.valueChanged.emit(this.answered);
  }
}