<div class="h-100 shadow-lg border-right relative">
	<div class="row align-items-center border-bottom border-top bg-white mr-0 ml-0" *ngIf="isSearch">
		<div class="col pl-0 pr-0">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text bg-white border-right-0 pl-2 pr-2 pt-3 pb-3">
						<rehub-icon name="search"></rehub-icon>
					</span>
				</div>
				<input type="text" class="form-control border-left-0 pt-3 pb-3 h-100" placeholder="Buscar"/>
			</div>
		</div>
	</div>
	<div class="items">
    <ng-container *ngFor="let item of items">
      <div (click)="selectItem(item)">
        <ng-template *ngTemplateOutlet="templateRef; context : {item: item}"></ng-template>
      </div>
    </ng-container>
	</div>
</div>