<div>
  <table style="width:100%" class="text-center">
    <tr>
      <td *ngFor="let index of [0,1,2,3,4,5,6]; last as isLast" [ngClass]="{'border-right': !isLast}" class="py-2">
        <p class="text-gray-3 text-uppercase">
          {{dayNames[index]}}
        </p>
        <!-- este componente no se usa actualmente -->
      </td>
    </tr>
  </table>
</div>