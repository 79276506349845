import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CredentialsService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class IsTherapyFinishedGuard implements CanActivate {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) { }

  canActivate(): boolean {
    if (this.credentialsService.isAuthenticated() && this.credentialsService.isTherapyFinished()) {
      this.credentialsService.clearCredentials();
      return true;
    }
    this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }
}