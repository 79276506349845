import { Injectable, isDevMode } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService, CredentialsService } from '../auth';

import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { SharedUtils } from '@rehub-shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class IsNotAuthenticatedGuard implements CanActivate {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let code = route.queryParams.code;
    if (code) return true;

    if (SharedUtils.isChromecast()) {
      this.router.navigate(['/connection'], {queryParams: {type: 'chrome'}, replaceUrl: true});
      return true;
    }

    if (!this.credentialsService.isAuthenticated()) {

      this.authenticationService.redirectToLogin();

      return true;
    }

    if(this.credentialsService.isPending()) this.router.navigate(['/terms-and-conditions']);
    else this.router.navigate(['/']);

    return false;
  }
}
