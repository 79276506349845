import { Injectable } from '@angular/core';
import { SharedCoachService } from '@rehub-shared/coach/coach.service';
import { DOMService } from './dom.service';

import { CoachImpl } from './shared-implementations/coach.impl';

@Injectable({
  providedIn: 'root',
})
export class CoachService extends SharedCoachService {

  constructor(
    private domService: DOMService
  ) {
    super(new CoachImpl(domService));
  }
}