export type Point = {
  x: number,
  y: number
}

export class SharedMathUtils {

  // TODO añadir tests
  public static toRadians(degrees: number){
    return degrees * (Math.PI / 180);
  }

  // TODO añadir tests
  public static toDegrees(radians: number){
    return radians * (180 / Math.PI);
  }

  // TODO añadir tests
  public static lerp(start: number, end: number, amount: number): number {
    return (1 - amount) * start + amount * end;
  }

  // TODO añadir tests
  public static lerpPoints(start: Point, end: Point, amount: number): Point {
    return {
      x: this.lerp(start.x, end.x, amount),
      y: this.lerp(start.y, end.y, amount)
    }
  }

  // TODO añadir tests
  public static getDistance(a: Point, b: Point): number {
    return Math.hypot(b.x - a.x, b.y - a.y);
  }

  // TODO añadir tests
  public static getShortestDistance(p0: Point, p1: Point, p2: Point): number{
    let lengthA = this.getDistance(p0, p1);
    let lengthB = this.getDistance(p0, p2);
    return Math.min(lengthA, lengthB);
  }

  // TODO añadir tests
  public static getAngleBetweenTwoPoints(p0: Point, p1: Point): number{
    let degrees = this.toDegrees(Math.atan2(p1.y - p0.y, p1.x - p0.x));
    if(degrees < 0) degrees += 360;
    return degrees;
  }

  // TODO añadir tests
  public static mapRange(num: number, inMin: number, inMax: number, outMin: number, outMax: number){
    return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  // TODO añadir tests
  public static isNumber(number: number) {
    return number != null && !isNaN(number) && isFinite(number);
  }

   /**
   *
   * @param a Primer punto (inicial)
   * @param b Segundo punto (central)
   * @param c Tercer punto (final)
   * @param resolution Número de puntos de resolución
   * @returns Array de puntos que forman la curva
   */
  // TODO! añadir tests
  public static getBezier3(a: any, b: any, c: any, resolution: any): Point[]{
    let points: Point[] = [];

    for(let i = 0; i <= resolution; i++){
      let t = SharedMathUtils.mapRange(i, 0, resolution, 0, 1);
      let x = (Math.pow(1 - t, 2) * a.x) + (2 * (1 - t) * t * b.x) + (Math.pow(t, 2) * c.x);
      let y = (Math.pow(1 - t, 2) * a.y) + (2 * (1 - t) * t * b.y) + (Math.pow(t, 2) * c.y);

      points.push({x, y});
    }

    return points;
  }

  // TODO! añadir tests
  // TODO! No pueden llegar decimales, throw error
  public static decToHex(decimal: number){
    return decimal.toString(16).padStart(2, "0");
  }
}