<ng-container *ngIf="!showProgress">
  <div class="progress position-relative progress-bar-container w-100" style="height: 15px;">
    <div class="d-flex justify-content-center position-absolute w-100" style="gap: 5px;">
      <rehub-icon *ngIf="iconName && showProgress" [attr.name]="iconName" class="mt-1" size="20"></rehub-icon>
    </div>
      <div class="progress-bar bg-brand" style="border-radius: 0.75rem !important;" role="progressbar"  aria-valuemax="100"
        aria-valuemin="0" [ngStyle]="{'width': getPercentage() +'%'}"></div>
  </div>
</ng-container>
<ng-container *ngIf="showProgress">
  <div class="progress position-relative progress-bar-container w-100" style="height: 30px;">
    <div class="d-flex justify-content-center position-absolute w-100" style="gap: 5px;">
      <rehub-icon *ngIf="iconName && showProgress" [attr.name]="iconName" class="mt-1" size="20"></rehub-icon>
      <h3>
          {{current}}/{{max}}
      </h3>
    </div>
      <div class="progress-bar bg-brand" style="border-radius: 0.75rem !important;" role="progressbar"  aria-valuemax="100"
        aria-valuemin="0" [ngStyle]="{'width': getPercentage() +'%'}"></div>
  </div>
</ng-container>
