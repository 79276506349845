<ng-container *ngIf="data">
  <div class="confirm-container">
    <div [ngClass]="{'modal-header-professional': data.useProfessionalTheme, 'modal-header': !data.useProfessionalTheme}">
      <h5 class="modal-title" *ngIf="data.title">{{ data.title | translate }}</h5>
      <a *ngIf="!data.hideClose" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <rehub-icon name="close" size="15"></rehub-icon>
      </a>
    </div>

    <div class="modal-body text-left" *ngIf="data.body" [innerHtml]="getBodyTranslation()" style="max-width: 450px;"></div>

    <div class="border-0 d-flex flex-row pb-3 pl-3 pr-3 pt-1 justify-content-center align-items-center footer w-100"
      [ngClass]="{'pt-3': !data.body, 'pt-1': data.body }">
      <button type="button" class="btn btn-lg" (click)="confirm()" [disabled]="disabled" [attr.data-rc]="'modal-confirm-yes'"
        [ngClass]="{'btn-primary-professional': data.useProfessionalTheme, 'btn-primary': !data.useProfessionalTheme, 'mr-3': !data.hideCancel}">
        {{ (data.accept_text ? data.accept_text : "general_accept") | translate}}
      </button>
      <button *ngIf="!data.hideCancel" type="button" class="btn btn-danger btn-lg" (click)="cancel()" [disabled]="disabled"
        [attr.data-rc]="'modal-confirm-cancel'">
        {{ (data.cancel_text ? data.cancel_text : "general_cancel") | translate}}
      </button>
    </div>
  </div>
</ng-container>
