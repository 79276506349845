import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TruncateTextPipe } from './truncate-text.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

let pipes = [
  TruncateTextPipe,
  SafeHtmlPipe
];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class PipeModule {}
