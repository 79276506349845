import { Component, Input, OnInit } from '@angular/core';
import { CalendarUtils } from '../calendar/calendar-utils';

@Component({
  selector: 'rehub-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
  host: { class: "d-block cy-achievement" }
})
export class AchievementComponent implements OnInit {

  CalendarUtils = CalendarUtils;
  @Input() achievement: any;
  @Input() pending: boolean = false;

  constructor() {}

  ngOnInit() {

  }

  getAchievementRemaining(){
    return this.achievement.achievementValue - this.achievement.achievementValueRemaining;
  }

}