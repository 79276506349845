import { ILogPublisher } from './log-publisher';

export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug
}

export class BaseLog{
  private publishers: ILogPublisher[] = [];

  addPublisher(publisher: ILogPublisher){
    //TODO: check if it is already added
    this.publishers.push(publisher);
  }

  getPublishers(): ILogPublisher[]{
    return this.publishers;
  }

}

export class Log extends BaseLog{
  
  private static instance: Log;

  static getInstance(){
    if(!Log.instance) Log.instance = new Log();
    return Log.instance;
  }

  d(...args: any[]){
    this.log(args, LogLevel.Debug);
  }

  e(...args: any[]){
    this.log(args, LogLevel.Error);
  }

  private log(args: any[], level: LogLevel){
    this.getPublishers().forEach((publisher: ILogPublisher)=>{
      publisher.log(args, level);
    });
  }
}