import { IRxjs } from "@rehub-shared/utils/rxjs.interface";
import { Observable, Subject } from 'rxjs';
import { share, map } from 'rxjs/operators';

export class RxjsImpl implements IRxjs{

  createEmptySubject() {
    return new Subject<any>(); 
  }
  
  createSubject(observer: any, observable: any) {
    return Subject.create(observer, observable);
  }

  createObservable(observerCallback: any) {
    return Observable.create(observerCallback);
  }

  mapSubject(subject: any, makeHot: boolean, messageCallback: any) {
    let mapped = subject.pipe(map(messageCallback));
    if(makeHot) mapped = mapped.pipe(share());
    return mapped;
  }

}