import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, CredentialsService } from '@app/@core/auth';
import { EventBusService } from '@app/@core/service';
import { I18nService } from '@app/i18n';
import * as moment from 'moment';
import { PatientAPI } from "@rehub-shared/api"
import { AppConfig } from './../../app.config';

@Component({
  selector: 'rehub-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {

  @Input() inNavbar = false;
  @Input() menuClass = '';
  currentLanguage:any;

  constructor(
    private i18nService: I18nService,
    private credentialsService: CredentialsService,
    private eventBus: EventBusService,
    private authenticationService: AuthenticationService,
    private appConfig: AppConfig
  ) {}

  ngOnInit() {
    this.currentLanguage = this.i18nService.getLanguage();
  }

  setLanguage(language: any) {
    if(!language) return;

    let params = [{param: "lang", value: language}];

    this.i18nService.setLanguage(language).then(()=>{

      PatientAPI.updateParam(this.authenticationService, this.credentialsService.getCredentials().sub, params);

      //this.i18nService.setLanguage(language);

      moment.locale(this.i18nService.getLanguageCode());

      this.eventBus.publish("LANGUAGE_CHANGED");

    }).catch(()=>{

      console.log("Error loading language config")

    });

  }

  get languages(): any[] {
    return this.i18nService.getSupportedLanguages();
  }
}
