

<header class="text-center mb-1 d-flex flex-column">
  <!-- <div class="text-left pl-2">
    <h4>{{'calendar_week_title' | translate}}</h4>
  </div> -->

  <div class="d-flex flex-row align-items-center align-content-center">
    <rehub-icon name="arrow" rotate="270" class="pointer" (click)="previousWeek()" size="16"
      [ngClass]="{'invisible': selectedWeek <= weekRange[0]}"></rehub-icon>
    <h4 class="flex-fill text-title">{{first}} - {{last}}</h4>
    <rehub-icon name="arrow" rotate="90" class="pointer" (click)="nextWeek()" size="16"
    [ngClass]="{'invisible': selectedWeek >= weekRange[1]}"></rehub-icon>
  </div>

</header>

<div>
  <table style="width:100%" class="text-center">
    <tr>
      <th *ngFor="let day of dayNames">
        <p class="text-gray-3 text-uppercase title-calendar" >
          {{day}}
        </p>
      </th>
    </tr>
    <tr>
      <td *ngFor="let day of days" class="pointer" (click)="selectDay(day)">
        <!-- <p class="d-inline-flexday day dayWeek" [ngClass]="{'day-selected': CalendarUtils.isToday(day)}"> -->
        <p class="d-inline-flexday title-calendar day dayWeek" [ngClass]="{'day-selected': day.isToday, 'day-exercise': !day.isToday && day.exercisesNum > 0}" [title]="day.exercisesNum">
          {{day.format('DD')}}
        </p>
      </td>
    </tr>
    <!-- <tr>
      <td *ngFor="let day of days;" class="pointer" (click)="selectDay(day)">
        <rehub-icon name="shape_circle" [ngClass]="{'invisible': !day.exercisesNum}" size="12"
              class="text-brand fade-in mx-auto my-3 mx-2" [title]="day.exercisesNum"></rehub-icon>
      </td>
    </tr> -->
  </table>
</div>

<div class="col mt-1 d-flex align-items-center justify-content-center" *ngIf="therapyProgress && therapyProgress.remainingDays">
  <h4 class="m-0 mr-2 title-calendar text-title">{{ 'today_progress_therapy_days' | translate }}:</h4>
  <h4 class="m-0 title-calendar text-title" *ngIf="therapyProgress.remainingDays > 1">{{therapyProgress.remainingDays}}</h4>
  <h4 class="m-0 title-calendar text-title" *ngIf="therapyProgress.remainingDays == 1">{{ 'today_progress_therapy_last_day' | translate }}</h4>

</div>
