const RETRY_TIME = 100;
const MAX_RETRY = 10;

export class ButtonProgressBar{
  timeout: any;
  listener: any;
  element: HTMLElement;
  stopped: boolean;

  private retries: number = 0;

  private static skipTimers : boolean = false;

  public static setSkipTimers(value: boolean) {
    this.skipTimers = value;
  }

  start(selector: string, time: number, callback: Function) {
    if(ButtonProgressBar.skipTimers || this.stopped) return;

    this.retries = 0;

    this.getElement(selector).then((element: HTMLElement) => {
      this.element = element;
      this.element.classList.add("btn-progress");

      setTimeout(() => {
        this.element.classList.add("btn-progress-animate");
        this.element.style.setProperty("--button-progress-transition", `${time}ms`);
        this.timeout = setTimeout(callback, time);
      }, 100);

      this.listener = () => {this.stop()};
      this.element.addEventListener("click", this.listener);
    }).catch(() => {
      console.error(`Elemento ${selector} no encontrado`);
    });
  }

  stop() {
    if(!this.element) return;

    clearTimeout(this.timeout);
    this.element.removeEventListener("click", this.listener);
    this.stopped = true;
  }

  private getElement(selector: string): Promise<HTMLElement>{
    return new Promise((resolve, reject) => {
      let element = document.querySelector(selector) as HTMLElement;

      if(element) resolve(element);

      if(this.retries < MAX_RETRY) {
        this.retries++;
        setTimeout(() => {
          resolve(this.getElement(selector));
        }, RETRY_TIME);
      } else {
        reject();
      }
    });
  }
}