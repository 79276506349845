export class AudioManager {

  audios: any = new Map();

  private audiosLoaded: number = 0;
  private audiosToLoad: number = 0;
  private audioElement: any;

  /**
   * Crea un elemento html de audio y carga todos los audios pasados por parámetro
   * 
   * @param audioAssetsBaseUrl Ruta base desde la que se cargan los audios
   * @param cacheUrlParam Parámetro extra que se añade a la url para que se cacheen correctamente los audios
   * @param audios 
   */
  constructor(
    private audioAssetsBaseUrl: string,
    private cacheUrlParam: string,
    private audioKeys: string[]
  ) {
    this.audioElement = document.createElement("audio");

    this.audioKeys.forEach((nameAudio: string) => {
      this.addAudioToLoader(nameAudio);
    });

    this.loadAudios();
  }

  /**
   * Reproduce un audio
   * @param key Audio a reproducir
   * @param loop Si el audio se tiene que reproducir en bucle
  */
  play(key: string, loop: boolean = false): void {
    let url = this.audios.get(key);
    this.audioElement.setAttribute('src', url);
    this.stop();

    let promise = this.audioElement.play();

    promise.catch((e: any)=> {}); //TODO: eliminar?
  }

  /**
   * Para el audio que se está reproduciendo actualmente
  */
  stop(): void {
    if (!this.audioElement) return;
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
  }

  /**
   * Añade un audio a la lista para cargarlo
   * @param key Clave del audio a cargar
  */
  private addAudioToLoader(key: string): void {
    this.audios.set(key, key);
  }

  /**
   * Carga todos los audios
  */
  private loadAudios(): void {
    this.audiosToLoad = this.getAudioCount();
    this.audiosLoaded = 0;

    this.audios.forEach((value: any, key: string) => {    
      this.loadAudioFromString(value, key);
    });
  }

  /**
   * Carga un audio
   * @param value Numbre del fichero
   * @param key Clave del audio
  */
  private loadAudioFromString(value: string, key: string): void {
    const url = this.audioAssetsBaseUrl + "/assets/sounds/" + value + ".wav" + "?v=" + this.cacheUrlParam;

    this.audios.set(key, url);
    this.audiosLoaded++;
  }

  /**
   * @returns El número de audios que hay cargados
   */
  private getAudioCount(): number {
    return this.audios.size;
  }

}