import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { I18nModule } from '@app/i18n';

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [],
})
export class AuthModule {}
