
import { AudioManager } from "../utils/audio-manager";
import { COMPONENTS_CONTEXTS } from "./voice-control-templates"
import { SharedEventBusService } from "./eventbus.service";
import { SharedLocalSettingsService } from "./../utils/local-settings.service";

export interface IVoiceControlEvent {
  onKeyWordDetected(): void;
  onCommandProcessing(): void;
  onCommandProcessed(command:string): void;
  onCommandNotProcessed(coach_message: string, component_name: string): void;
}

export type VoiceComponentContext = {
  componentName: string,
  commands: {[key:string]:string},
  questions: {[key:string]:string}
}

export type VoiceComponentCallback = {
  componentName: string,
  callback: IVoiceControlEvent
}

export class SharedVoiceControlService {
  private wssConnection: WebSocket;
  private wssUrl: string = "wss://ae3e07d4z7.execute-api.eu-west-1.amazonaws.com/dev";
  private userId: string;

  private componentsContexts: Array<VoiceComponentContext> = [];
  private componentsCallbacks: Array<VoiceComponentCallback> = [];

  private KEYWORD: string = "Diana";
  private LANGUAGE: string = 'es-ES'; //'it-IT'; //'es-ES' //'en-GB';
  private keyWordFound: boolean = false;

  private speechRecognitionList: any;
  private recognition: any;
  private audioManager: AudioManager;

  private serviceStarted: boolean = false;

  private initSound: string = "realtime_min";
  private endSound: string = "realtime_max";

  microIconHeaderEventBus: any;
  microAppStatusEventBus: any;
  voiceServiceEventBus: any;
  microphoneEnabled: boolean = false;

  constructor(
    private eventBus: SharedEventBusService,
    private localSettingsService: SharedLocalSettingsService,
  ) {
    this.componentsContexts = new Array<VoiceComponentContext>();
    this.componentsCallbacks = new Array<VoiceComponentCallback>();
    this.initMicrophoneStatus();
  }

  initMicrophoneStatus(){
    this.microIconHeaderEventBus = this.eventBus.subscribe("MICRO_ENABLE", (data: any)=>{
      let isDisabled = data.message === "disable" ? true : false;
      if(!isDisabled){this.initRecognition();}
      this.setMicroStatus(isDisabled);
    });
  };


  setMicroStatus(status: boolean){
    let settings: any = this.localSettingsService.get();
    this.microphoneEnabled = !status
    settings.microDisabled = status;
    this.localSettingsService.set(settings);
  }


  generateContext() {
    let allCommands: {[key:string]:string} = {};
    let allQuestions: {[key:string]:string} = {};

    for(let _voiceComponentContext of this.componentsContexts){
      if (!this.componentsCallbacks){
        continue;
      }

      let commands = _voiceComponentContext.commands;
      let questions = _voiceComponentContext.questions;

      for(let _k in commands){
        allCommands[_k] = commands[_k];
      }
      for(let _k in questions){
        allQuestions[_k] = questions[_k];
      }

    }
    return {
      "commands": allCommands,
      "questions": allQuestions
    };
  }

  addComponentContext(componentName:string, commands: {[key:string]:string}, questions: {[key:string]:string}) {
    this.componentsContexts.push({
      commands: commands,
      questions: questions,
      componentName: componentName,
    });
  }

  addComponentCallback(componentName:string, callback: IVoiceControlEvent) {
    this.componentsCallbacks.push({
      callback: callback,
      componentName: componentName
    });
  }

  addComponent(componentName:string, commands: {[key:string]:string}, questions: {[key:string]:string}, callback: IVoiceControlEvent) {
    this.addComponentContext(componentName, commands, questions);
    this.addComponentCallback(componentName, callback);
  }

  removeContext(windowName:string) {
    this.componentsContexts = this.componentsContexts.filter( context => context.componentName !== windowName);
  }
  removeCallback(windowName:string) {
    this.componentsCallbacks = this.componentsCallbacks.filter( callback => callback.componentName !== windowName);
  }

  removeComponent(windowName:string) {
    this.removeContext(windowName);
    this.removeCallback(windowName);
  }

  init(userId: string, assets_url:string, cache_param:string) {
    if(this.serviceStarted) return;
    // User id to identify the user in the server
    this.userId = userId;

      this.audioManager = new AudioManager(
        assets_url,
        cache_param,
        [this.initSound, this.endSound]
      );
      this.initWebsocket();
      this.startRecognition();
      this.serviceStarted = true;
  }

  initWebsocket(){
      if(!this.wssConnection || this.wssConnection.CLOSED) {
        // Websocket connection to the server and its callbacks
        this.wssConnection = new WebSocket(this.wssUrl + "?user=" + this.userId);
        this.wssConnection.onopen = this.onOpen.bind(this);
        this.wssConnection.onclose = this.onClose.bind(this);
        this.wssConnection.onmessage = this.onMessage.bind(this);
      }
  }

  initRecognition(){
    let w = window as any;
    this.recognition = new (w.SpeechRecognition || w.webkitSpeechRecognition)();
    this.speechRecognitionList = new w.webkitSpeechGrammarList();
  }

  startRecognition() {
    if(!this.speechRecognitionList){
      let isDisabled = true;
      this.setMicroStatus(isDisabled);
    }else{
      var grammar = '#JSGF V1.0; public <fase> = Diana *;'
      this.speechRecognitionList.addFromString(grammar, 1);

      if(this.LANGUAGE){
        console.log("VoiceControl::startRecognition() language " + this.LANGUAGE);
        this.recognition.lang = this.LANGUAGE;
      }

      this.recognition.interimResults = true; // return partial results enabled
      this.recognition.maxAlternatives = 1;
      this.recognition.continuous = true; // keep listening
      this.recognition.grammars = this.speechRecognitionList;
      this.recognition.start();

      this.recognition.onaudioend = (event: any) => {
        // Restart recognition when audio ends to keep listening
        this.initRecognition();
        this.startRecognition();
      }

      this.recognition.onresult = (event: any) => {
        //console.log("VoiceControl::onresult", event);
        //console.log("VoiceControl::onresult", this.microphoneEnabled);
        if(this.microphoneEnabled){
          //loop over the partial results to find early matches with the keyword
          for (var i = event.resultIndex; i < event.results.length; i++) {
            var transcript = event.results[i][0].transcript;
            var isFinal = event.results[i].isFinal;
            var timeStamp = event.timeStamp;

            // check if the keyword is found on transcript and it has not been
            // detected yet
            let keyword_in_message = transcript.indexOf(this.KEYWORD) > -1;
            let keyword_not_already_detected = !this.keyWordFound;

            if (keyword_in_message && keyword_not_already_detected) {
              this.keyWordFound = true;

              // play sound
              this.audioManager.play(this.initSound);

              // send event to all components that the keyword has been detected
              this.componentsCallbacks.forEach(
                (_voiceComponentCallback, _windowName) => {
                  _voiceComponentCallback.callback.onKeyWordDetected();
                });
            }

            // check if the final result is found and keyword has been detected
            if (isFinal) {
              if (this.keyWordFound) {
                // play sound
              this.audioManager.play(this.endSound);

                // generate context from all components mergin commands and questions
                let contextGenerated = this.generateContext();

                // send event to all components that the command is being processed
                this.componentsCallbacks.forEach(
                  (_voiceComponentCallback, _windowName) => {
                    _voiceComponentCallback.callback.onCommandProcessing();
                });

                // send voice command to the server to be processed
                let trimmedTranscript = transcript.split(this.KEYWORD).pop();
                if(this.microphoneEnabled){
                  this.wssConnection.send(
                    JSON.stringify({
                      "voice_command": trimmedTranscript,
                      "message_context": contextGenerated
                    }));
                }

              }
              this.keyWordFound = false;
            }
          }
        }

      }
    }
  }


  onMessage(event: any) {
    let message = JSON.parse(event.data);
    let command = message.command;
     // recieve the processed command from the server and send it to the
    // component that requested it
    let commandExists = false;
    let firstComponent: any;
    let FirstComponentName: any;

    this.componentsCallbacks.forEach((_component) => {

      if(!firstComponent){
        firstComponent = _component;
        FirstComponentName = _component.componentName
      }

      if(this.componentsContexts.some( context => context.componentName === _component.componentName)){
        for(let _context of this.componentsContexts){
          if(command in _context.commands){
            _component.callback!.onCommandProcessed(command);
            commandExists = true;
            break;
          }
        }
      }

    });

    if(!commandExists){
      firstComponent.callback!.onCommandNotProcessed("coach_voice_unknown_message", FirstComponentName);
    }

  }

  onOpen(event: any) {
    //console.log("VoiceControl::onOpen");
    //console.log("event:", event);
  }

  onClose(event: any) {
    //console.log("VoiceControl::onClose");
    //console.log("event:", event);
    if(this.wssConnection.CLOSED){
      this.initWebsocket();
    }
  }
}