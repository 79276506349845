export enum Permission {
  sendSms = "sendSms",
  videoCall_C = "videoCall_C",
  Patient_share = "Patient_share",
  Patient_Pwd_U = "Patient_Pwd_U",
  Report_C = "Report_C",
  Report_E = "Report_E",
  Patient_C = "Patient_C",
  PatientEvolution_C = "PatientEvolution_C",
  Widget_E = "Widget_E",
  Mod_musculoskeletal = "Mod_musculoskeletal",
  Mod_respiratory = "Mod_respiratory",
  Mod_logopedics = "Mod_logopedics",
  Mod_pelvicFloor = "Mod_pelvicFloor",
  Protocol_C = "Protocol_C",
  ExGroup_C = "ExGroup_C",
  ExGroup_share = "ExGroup_share",
  Protocol_Export = "Protocol_Export",
  Protocol_Cam_Params = "Protocol_Cam_Params",
  Camera_U = "Camera_U",
  Trial_Patient_C_num = "Trial_Patient_C_num",
  Trial_Protocol_C_num = "Trial_Protocol_C_num",
  Trial_Protocol_group = "Trial_Protocol_group",
  Trial_Protocols = "Trial_Protocols",
  Trial_forms = "Trial_forms",
  Trial_joints = "Trial_joints",
  Theme_E = "Theme_E",
  videoCall_Calendar = "videoCall_Calendar",
  File_Upload = "File_Upload",
  Logo_E = "Logo_E",
  Patient_game = "Patient_game",
  JointOther_C = "JointOther_C",
  PulseOximeter_U = "PulseOximeter_U",
  VoiceRecognition_Patient_U = "VoiceRecognition_Patient_U",
  SS_config_wellwo = "SS_config_wellwo",
  Chat_Patient_D = "Chat_Patient_D",
  Theme_E_Config = "Theme_E_Config",
  SS_config_pharma = "SS_config_pharma",
  Coach_Patient_D = "Coach_Patient_D",
  Contact_Patient_D = "Contact_Patient_D",
  Optional4_Patient_D = "Optional4_Patient_D",
  Patient_header_phone_D = "Patient_header_phone_D",
}

export class PermissionsUtils {

  public static checkPermission(permissions: any, namePermission: Permission, isPatient: boolean) {
    let defaultValue: boolean = isPatient ? false : true;
    return permissions ? permissions[namePermission] == true : defaultValue;
  }

  public static getTrialPermission(permissions: any, namePermission: Permission) {
    return permissions ? permissions[namePermission] : namePermission.includes("_num") ? 0 : [];
  }

}