import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { CredentialsService } from '@app/@core/auth';
import { CalendarUtils } from '../calendar-utils';

import * as moment from 'moment';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'rehub-calendar-week',
  templateUrl: './calendar-week.component.html',
  styleUrls: ['./calendar-week.component.scss'],
  host: { class: 'shadow bg-white d-block p-2 pb-3 cy-calendar-week' },
})
export class CalendarWeekComponent implements OnInit {

  CalendarUtils = CalendarUtils;

  @Input() therapyProgress: any;
  @Input() exercisesByDate: any;

  @Output() sendSelectedDay = new EventEmitter();

  dayNames: any = [];
  days: any;

  indexSelected: any = 0;

  first: any;
  last: any;

  selectedWeek: any = 0;
  selectedDay: any;
  weekRange = [0, 1];

  constructor(
    private i18nService: I18nService
  ) {}

  ngOnInit() {
    let today = moment();
    this.selectedDay = today;
    this.dayNames = CalendarUtils.getDaysNames();
    //this.selectWeek(this.selectedWeek);

    this.selectWeek(this.selectedWeek, true);

  }

  selectWeek(selectedWeek: any, addExercises: boolean = false){
    let today = moment();
    let days = CalendarUtils.getWeekDays(today.add(selectedWeek * 7, 'days'), this.i18nService.getLanguageCode());

    this.first = days[0].format("DD MMM YYYY");
    this.last = days[6].format("DD MMM YYYY");
    this.days = days;

    if(addExercises){
      this.days.forEach((day: any) => {
        let key = day.format("YYYY-MM-DD") + "T00:00:00Z";
        // day.exercises = this.exercisesByDate[key];
        day.exercisesNum = this.exercisesByDate[key];
        day.isToday = day.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD");
      });
    }
  }

  selectDay(day: any) {
    this.sendSelectedDay.emit(day);
    this.selectedDay = day;
  }

  nextWeek(){
    if(this.selectedWeek >= this.weekRange[1]) return;
    this.selectedWeek++;
    this.selectWeek(this.selectedWeek, true);
  }

  previousWeek(){
    if(this.selectedWeek <= this.weekRange[0]) return;
    this.selectedWeek--;
    this.selectWeek(this.selectedWeek, true);
  }
}
