import { Component, Input, OnInit } from '@angular/core';
import { ModalService} from '@app/@core/service/modal.service';
import { I18nService } from '@app/i18n';

import { environment } from '@env/environment';

@Component({
  selector: 'rehub-update-realtime-modal',
  templateUrl: './update-realtime-modal.component.html',
  styleUrls: ['./update-realtime-modal.component.scss']
})
export class UpdateRealtimeModalComponent implements OnInit {

  data: any;
  private statusChangeSubscription: any;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {
    this.statusChangeSubscription = this.data.measurementProgramService.onStatusChange().subscribe((status: string)=>{
      console.log("InstallRealtimeModal status:", status);
      if(status == "CONNECTED"){
        // Ha terminado de instalar
        this.close();
      }
    });
  }

  close(){
    this.statusChangeSubscription.unsubscribe();
    this.modalService.close();
  }

  onCancelPressed(){
    this.data.measurementProgramService.cancelUpdate();
    this.close();
  }

  onHelpPressed(){
    //Se abre web de contacto
    let client = environment.client_login;
    let lang = this.i18nService.getLanguageCode();
    let url = environment.login_url + "/contact-form?client=" + client + "&lang=" + lang;
    window.open(url, "_blank");
  }
}
