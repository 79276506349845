<ng-container *ngIf="data">
  <div class="confirm-container">
    <div [ngClass]="{'modal-header-professional': data.useProfessionalTheme, 'modal-header': !data.useProfessionalTheme}">
      <a *ngIf="!data.hideClose" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <rehub-icon name="close" size="15"></rehub-icon>
      </a>
    </div>

    <div class="modal-body">
      <rehub-icon name="warning" class="mb-3 mx-auto" size="50" style="color: #eb7450;"></rehub-icon>
    </div>
    <div class="modal-body my-auto text-center" *ngIf="!data.body" style="max-width: 450px;">

      <p class="modal-text" >{{ 'pulseoximeter_warning_values' | translate }}</p>
      <p class="modal-text" style="color: #eb7450;">{{ 'pulseoximeter_warning_first_text' | translate }}</p>
      <p class="modal-text" >{{ 'pulseoximeter_warning_second_text' | translate }}</p>

    </div>
    <div class="border-0 d-flex flex-row pb-3 pl-3 pr-3 pt-1 justify-content-center align-items-center footer w-100"
    [ngClass]="{'pt-3': !data.body, 'pt-1': data.body }">
      <button type="button" class="btn btn-lg" (click)="confirm()" [disabled]="disabled" [attr.data-rc]="'modal-confirm-yes'"
        [ngClass]="{'btn-primary-professional': data.useProfessionalTheme, 'btn-primary': !data.useProfessionalTheme, 'mr-3': !data.hideCancel}">
        {{ (data.accept_text ? data.accept_text : "general_accept") | translate}}
      </button>
    </div>
  </div>
</ng-container>
