import { API } from "./api";

export type CalendarEventItem = "CALENDAR_EVENT" | "CALENDAR_EVENT_ATTENDEE";
export type CalendarEventAction = "GET" | "LIST" | "CREATE" | "UPDATE" | "UPDATE_STATUS";
export type CalendarEventAttendeeStatus = "OK" | "NOK" | "CANCELED";

export class NotificationAPI extends API {

  static getAchievements(patientId: string, action: string = "LIST"): Promise<any>{
    const url = `${this.getBaseUrl()}/notification/achievement/${action}`;
    return this.post(url, {patientId: patientId});
  }

  static calendarEventItemAction(item: CalendarEventItem, action: CalendarEventAction, data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/notification/calendar/event/${item}/${action}`;
    return this.post(url, data);
  }

  static getAlerts(action: "LIST" | "GET_PATIENT" | "REVISE", data: any = {}): Promise<any>{
    const url = `${this.getBaseUrl()}/notification/alert/${action}`;
    return this.post(url, data);
  }
}