import { Injectable } from "@angular/core";
import { SharedFormService } from '@rehub-shared/service/form.service';

import answerTypes from "@rehub-shared/survey/answerTypes.json";
import formCategories from "@rehub-shared/survey/categories.json";
import scoreOrder from "@rehub-shared/survey/scoreOrder.json";

import EQ5D5LForm from "@rehub-shared/survey/EQ-5D-5L.json";
import TSK13Form from "@rehub-shared/survey/TSK-13.json";
import WOMACForm from "@rehub-shared/survey/WOMAC.json";
import QuickDASHForm from "@rehub-shared/survey/QuickDASH.json";
import RDQForm from "@rehub-shared/survey/RDQ.json";
import MRCForm from "@rehub-shared/survey/MRC.json";
import NDIForm from "@rehub-shared/survey/NDI.json";
import WOSIForm from "@rehub-shared/survey/WOSI.json";
import SPADIForm from "@rehub-shared/survey/SPADI.json";
import SSTForm from "@rehub-shared/survey/SST.json";
import PSSForm from "@rehub-shared/survey/PSS.json";
import OSSForm from "@rehub-shared/survey/OSS.json";
import OKSForm from "@rehub-shared/survey/OKS.json";
import OHSForm from "@rehub-shared/survey/OHS.json";
import HAGOSForm from "@rehub-shared/survey/HAGOS.json";
import HOOSForm from "@rehub-shared/survey/HOOS.json";
import KOOSForm from "@rehub-shared/survey/KOOS.json";
import FADIForm from "@rehub-shared/survey/FADI.json";
import PRWEForm from "@rehub-shared/survey/PRWE.json";
import QBPDSForm from "@rehub-shared/survey/QBPDS.json";
import CATForm from "@rehub-shared/survey/CAT.json";
import PGICForm from "@rehub-shared/survey/PGIC.json";
import ODIForm from "@rehub-shared/survey/ODI.json";
import TegnerScoreForm from "@rehub-shared/survey/TegnerScore.json";
import MNAForm from "@rehub-shared/survey/MNA.json";
import GDSForm from "@rehub-shared/survey/GDS.json";
import GRBASForm from "@rehub-shared/survey/GRBAS.json";
import VHI_10Form from "@rehub-shared/survey/VHI-10.json";
import VHI_30Form from "@rehub-shared/survey/VHI-30.json";
import HADSForm from "@rehub-shared/survey/HADS.json";
import BORGCR10Form from "@rehub-shared/survey/BORG-CR10.json";
import SWALQOLForm from "@rehub-shared/survey/SWAL-QOL.json";
import QoLDysForm from "@rehub-shared/survey/QoL-Dys.json";
import EAT10Form from "@rehub-shared/survey/EAT-10.json";
import BSAQForm from "@rehub-shared/survey/B-SAQ.json";
import ICIQSFForm from "@rehub-shared/survey/ICIQ-SF.json";
import WexnerForm from "@rehub-shared/survey/Wexner.json";
import DASHForm from "@rehub-shared/survey/DASH.json";
import EVAForm from "@rehub-shared/survey/EVA.json";
import MinnesotaForm from "@rehub-shared/survey/Minnesota.json";
import SF12Form from "@rehub-shared/survey/SF12.json";
import PCSForm from "@rehub-shared/survey/PCS.json";
import TSKForm from "@rehub-shared/survey/TSK-11.json";
import KiddyKINDLForm from "@rehub-shared/survey/Kiddy-KINDL.json";
import KidKINDLForm from "@rehub-shared/survey/Kid-KINDL.json";
import KiddoKINDLForm from "@rehub-shared/survey/Kiddo-KINDL.json";
import KiddyKINDLParentsForm from "@rehub-shared/survey/Kiddy-KINDL-parents.json";
import KidKiddoKINDLParentsForm from "@rehub-shared/survey/Kid-Kiddo-KINDL-parents.json";
import MPIPainForm from "@rehub-shared/survey/MPI-Pain.json";
import MPIActivitiesForm from "@rehub-shared/survey/MPI-Activities.json";
import MPISignificantOtherForm from "@rehub-shared/survey/MPI_SO.json";
import DASS21Form from "@rehub-shared/survey/DASS-21.json";
import BournemouthBackForm from "@rehub-shared/survey/Bournemouth-back.json";
import PFIQ7Form from "@rehub-shared/survey/PFIQ-7.json";
import WDQForm from "@rehub-shared/survey/WDQ.json";

@Injectable({
  providedIn: 'root',
})
export class FormService extends SharedFormService{

  answerTypes: any = answerTypes;
  formCategories: any = formCategories;
  scoreOrder: any = scoreOrder;

  forms: any = {
    "EQ-5D-5L": EQ5D5LForm,
    "TSK-13": TSK13Form,
    "WOMAC": WOMACForm,
    "QuickDASH": QuickDASHForm,
    "RDQ": RDQForm,
    "MRC": MRCForm,
    "NDI": NDIForm,
    "WOSI": WOSIForm,
    "SPADI": SPADIForm,
    "SST": SSTForm,
    "PSS": PSSForm,
    "OSS": OSSForm,
    "OKS": OKSForm,
    "OHS": OHSForm,
    "HAGOS": HAGOSForm,
    "HOOS": HOOSForm,
    "KOOS": KOOSForm,
    "FADI": FADIForm,
    "PRWE": PRWEForm,
    "QBPDS": QBPDSForm,
    "CAT": CATForm,
    "PGIC": PGICForm,
    "ODI": ODIForm,
    "TegnerScore": TegnerScoreForm,
    "MNA": MNAForm,
    "GDS": GDSForm,
    "SWAL-QOL": SWALQOLForm,
    "QoL-Dys": QoLDysForm,
    "EAT-10": EAT10Form,
    "HADS": HADSForm,
    "B-SAQ": BSAQForm,
    "ICIQ-SF": ICIQSFForm,
    "Wexner": WexnerForm,
    "DASH": DASHForm,
    "EVA": EVAForm,
    "Minnesota": MinnesotaForm,
    "SF12": SF12Form,
    "PCS": PCSForm,
    "TSK-11": TSKForm,
    "GRBAS": GRBASForm,
    "VHI-10": VHI_10Form,
    "VHI-30": VHI_30Form,
    "Kiddy-KINDL": KiddyKINDLForm,
    "Kid-KINDL": KidKINDLForm,
    "Kiddo-KINDL": KiddoKINDLForm,
    "Kiddy-KINDL-parents": KiddyKINDLParentsForm,
    "Kid-Kiddo-KINDL-parents": KidKiddoKINDLParentsForm,
    "MPI-Pain": MPIPainForm,
    "MPI-Activities": MPIActivitiesForm,
    "MPI_SO": MPISignificantOtherForm,
    "DASS-21": DASS21Form,
    "Bournemouth-back" : BournemouthBackForm,
    "PFIQ-7" : PFIQ7Form,
    "BORG-CR10": BORGCR10Form,
    "WDQ": WDQForm,
  };
}