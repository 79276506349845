import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { Permission } from '@rehub-shared/utils/permissions';
import { CredentialsService } from '@app/@core/auth';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  isLoading: boolean = false;
  isWellwo: boolean = false;
  isHeaderDisable: boolean = false;

  constructor(
    private router: Router,
    private credentialsService: CredentialsService

  ) {}

  ngOnInit() {
    this.isWellwo = this.isProviderWellwo();
    this.isHeaderDisable = this.credentialsService.checkPermission(Permission.Patient_header_phone_D)
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel: { // cancel is required when candeactive is enabled
          this.isLoading = false;
          break;
        }
      }
    });
  }
  private isProviderWellwo() {
    return this.credentialsService.checkPermission(Permission.SS_config_wellwo)
  }
}
